import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";

export const Fotoperfil = () => {
  const [imageUrl, setImageUrl] = useState("");
  const { width } = useWindowDimensions();
  const user = useSelector((state) => state?.user?.data.data);
  const isMobile = width < 630;
  const isDoctor = user?.profile?.type === 1;

  // Función para obtener la imagen del almacenamiento local al cargar la página
  useEffect(() => {
    const storedImageUrl = localStorage.getItem("storedImageUrl");
    if (storedImageUrl) {
      setImageUrl(storedImageUrl);
    }
  }, []);

  // Función para manejar el cambio de la imagen desde el explorador de archivos
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result;
      setImageUrl(imageUrl);
      localStorage.setItem("storedImageUrl", imageUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Imagen"
          style={
            isMobile
              ? {
                  marginLeft: "25%",
                  justifyContent: "center",
                  maxWidth: "50%",
                  borderRadius: "200px",
                }
              : {
                  maxWidth: "100%",
                  borderRadius: "200px",
                }
          }
        />
      )}
      <br />
      <br />
      {isMobile ? (
        <label
          style={{
            color: isDoctor ? "#4D99E5" : "#00AC90",
            background: "#FFF",
            border: isDoctor ? "2px solid #4D99E5" : "2px solid #00AC90",
            cursor: "pointer",
            padding: "10px",
            borderRadius: "10px",
            marginLeft: "35%",
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          Subir Foto de perfil
        </label>
      ) : (
        <label
          style={{
            color: isDoctor ? "#4D99E5" : "#00AC90",
            background: "#FFF",
            border: isDoctor ? "2px solid #4D99E5" : "2px solid #00AC90",
            cursor: "pointer",
            padding: "10px",
            borderRadius: "10px",
            marginLeft: "15px"
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          Subir Foto de perfil
        </label>
      )}
    </div>
  );
};
