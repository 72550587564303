import { useState } from 'react'
import axios from 'axios';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import styled from "styled-components";
import ReCAPTCHA from 'react-google-recaptcha';
import { HelmetSEO } from '../../helpers/HelmetSEO'
import { Loader } from '../general/spinner';
import { UilTimes } from "@iconscout/react-unicons";

const Title = styled.h1`
  font-weight: 900;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #3b3b3b;
  margin-top: 44px;
  margin-bottom: 24px;
  @media only screen and (min-width: 768px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }
`;

const LittleP = styled.p`
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #474a4b;
  margin-bottom: 0;
`;

const StyledForm = styled(Form)`
  input,
  textarea {
    font-family: "IBM Plex Sans";
    border: none;
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    // margin-left: 5%;
  }
  > .form-floating {
    margin-top: 23px;
  }
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
  }
  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans";
  }
  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad92;
  }
  > div input.form-check-input:checked {
    background-color: #00ad92;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }
  > div input.form-check-input:checked {
    background-color: #00ad90;
  }
  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #00ac90;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    width: 100%;
    margin-bottom: 8px;
    span {
      font-weight: 800;
    }
  }
  h3 {
    margin-bottom: 16px;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00ac90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const InputStyled = styled(Form.Check)`
  > input {
    cursor: pointer;
    border: 1px solid #30df99 !important;
    :checked {
      border: 1px solid #30df99 !important;
      background-color: #30df99 !important;
    }
  }
`;

const FormButton = styled(Button)`
  width: 260px;
  height: 48px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    margin-top: 31px;
    width: 300px;
  }
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;

const WhiteButton = styled(Button)`
  color: #00ac90;
  border: none;
  margin-top: -10px;
  margin-bottom: 0px;
  border: 2px solid #00ad92;
  width: 260px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    width: 300px;
  }
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #00ad90;
  }
`;

export const SuggestionsForm = () => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isComplainSuccess, setIsComplainSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState("");
  const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

  const printChange = (data) => {
    setButtonEnable(true);
    setToken(data);
    console.log(data);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const addComplain = await axios.post("https://labopat-5792e.appspot.com/calidad/save-sugestions", data)
      if(addComplain.data.ok){
        console.log("Queja agregada");
        setIsComplainSuccess(true);
      }
    } catch (error) {
      console.log("Error al agregar la queja:", error);
      setIsComplainSuccess(false);
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  }

  return (
    <Container style={{ paddingBottom: "1px" }}>
    {loading ? <Loader show={loading} /> : null}
      <HelmetSEO
        title='Quejas y Sugerencias - Labopat'
        description='Si tienes alguna queja o sugerencia nos gustaría la compartieras. Completa el formulario de Labopat y mejora nuestra atención.'
      />
      <Title>Quejas y Sugerencias</Title>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FloatingLabel controlId="name" label="Nombre del paciente*">
              <Form.Control
                type="text"
                {...register("name", {
                  required: true,
                })}
                placeholder="Nombre*"
              />
            </FloatingLabel>
            <FloatingLabel controlId="email" label="Email*" className="mb-3">
              <Form.Control
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                    message: "El correo no cumple con el formato",
                  },
                })}
                type="email"
                placeholder="name@example.com"
              />
            </FloatingLabel>
            <FloatingLabel controlId="phone" label="Teléfono">
              <Form.Control
                type="number"
                {...register("phone", {})}
                placeholder="Teléfono"
              />
            </FloatingLabel>
              <br /> 
            <FloatingLabel controlId="message" label="Escribe tu mensaje aquí*">
              <Form.Control
                as="textarea"
                {...register("message", {
                  required: true,
                })}
                placeholder="Escribe tu mensaje aquí*"
                style={{ height: "250px" }}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-center">
              <LittleP style={{ marginTop: "15px", marginBottom: "20px" }}>
                <b>Aviso legal: </b>
                Los datos recabados en este formulario son con la finalidad de
                dar atención a la solicitud requerida, por lo tanto, le
                informamos que puede consultar nuestro{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "#717171",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FAvisodeprivacidad.pdf?alt=media&token=32a816cc-89a7-4a07-b4ea-1a148cfcf7a4"
                >
                  aviso de privacidad.
                </a>
              </LittleP>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <InputStyled
                type="checkbox"
                label="He leído y acepto los términos y condiciones y aviso de privacidad de Labopat"
                onChange={() => setShowCaptcha(!showCaptcha)}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <ReCAPTCHA
                sitekey="6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT"
                render="explicit"
                onChange={printChange}
                onExpired={() => {
                  setToken(undefined);
                  setButtonEnable(false);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <FormButton
                variant="secondary"
                type="submit"
                disabled={buttonEnable && showCaptcha === true ? false : true}
              >
                Enviar
              </FormButton>
            </div>
          </StyledForm>
        </Col>
      </Row>

      {showModal && 
        <StyledModal show={showModal} onHide={() => setShowModal(false)}>
        {
          isComplainSuccess ?
          <>
            <div onClick={() => setShowModal(false)}>
              <UilTimes color="#fff" style={{ cursor: "pointer" }} />
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Hemos recibido tu mensaje</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ marginBottom: "35px" }}>
                Intentaremos resolver las quejas o los reclamos tan pronto como sea
                posible. Si necesita algo más, llámenos al +52 221 231 1313.
              </p>
              <WhiteButton
                variant="light"
                onClick={() => {
                  setShowModal(false);
                  window.location.href = "/";
                }}
              >
                Regresar a página de inicio
              </WhiteButton>
            </Modal.Body>
          </>
          :
          <>
            <div onClick={() => setShowModal(false)}>
              <UilTimes color="#fff" style={{ cursor: "pointer" }} />
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Error al enviar tu mensaje</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ marginBottom: "35px" }}>
                Hubo un error al intentar guardar tu mensaje, por favor vuelve a intentarlo mas tarde.
              </p>
              <WhiteButton
                variant="light"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cerrar
              </WhiteButton>
            </Modal.Body>
          </>
        }
        </StyledModal>
      }
    </Container>
  )
}

