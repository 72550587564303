import { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleAddItemToCart } from "../../../actions/shoppingCart";
import handleAlert from "../../../helpers/handleAlert";
import { studyInCart } from "../../../helpers/cart";
import axios from "axios";
import {
  BoxTitle,
  PanelContent,
  ContainerPricing,
  BoxPagoSucursal,
  PriceLinea,
  BoxBgBottom,
  CustomBox,
  BtnPagarAhora,
  PriceDetails,
  BoxPadding,
  Paragraph,
} from "./styles/StylesBannerPaneles";



const imageDesktop =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Fbanner_valoracion_integral.png?alt=media&token=570403b0-c8f9-4b4f-9f52-c9df165052ab";

const imageMobile =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Ftwo-people-vi-mobile.jpg?alt=media&token=cc596dd4-fc49-469d-8aef-e496b74dff54";

const bgImageDesk =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2FFondoValoracionIntegral.png?alt=media&token=7f626467-15d7-4af9-86c7-38789de2b067";

const bgImage =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2FFondoValorIntegralMobile.png?alt=media&token=cc8fc6f9-7aaf-4c67-b3ab-2e4abe4b8bb6";

  const BgNewService = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FVI-NEW-SERVICE.png?alt=media&token=7743a5e3-5ced-47f2-96bd-bf289a6226b2'


  const bgImageBottom = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fbg-VI.png?alt=media&token=dd093ebe-a7b7-449c-ad89-6b3bd0476603"

 const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(to right ,#5097E3, #0CAC8C, #30DF99);
  height: 640px;

  @media (min-width: 500px) {
    max-width: 36rem;
  height: 740px;

  }

  @media (min-width: 768px) {
    max-width: 36rem;
  height: 740px;

  }

  @media (min-width: 992px) {
    height: 460px;
    max-width: 64rem;
    background: #fff;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: 100%;
  }
`;


  export const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageDesktop || 'default-desktop-image.jpg'});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`;

const NewService = styled.div`
position: absolute;
top: 70%;
left: 0;
width: 234px;
height: 54px;
background-image: url(${BgNewService});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
display: flex;
align-items: center;
justify-content: flex-start;
@media (max-width: 768px) {
   top: 60%;

  }
`
const NewServiceTxt = styled.span`
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-left: 10px;
`
 const BoxPagoLinea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: auto;
  gap: 5px;
  button {
    border: 2px solid #1aa687;
    background-color: #fff;
    color: #1aa687;
    padding: 1px 10px;
    border-radius: 10px;
    outline: none;
    text-align: center;
    font-weight: 700;
    align-self: flex-start;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border: 2px solid transparent;
     background: #1aa687;
    color: #fff;


    }
    @media (min-width: 992px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
      padding: 3px 40px;
    }

    @media (min-width: 1400px) {
    }
  }
`;


const ValoracionIntegral = () => {
  const [local, setLocal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const { items } = useSelector((state) => state.shoppingCart);
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });
  const studyId = "valoracion-integral-para-cirugia-bariatrica-(vicib)";
  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/obtener-estudio/${studyId}/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
      if (response.data.categoria == 6) {
        setLocal(true);
      }
      if (response.data.categoria == 9) {
        setLocal(true);
      }
    } catch (error) {
      console.log(error);
      history.push("/not-found");
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkoutp");
    }
  };
  
  
  const TomaDomicilioEvt = () => {
    const estudioName = "Valoración Integral para Cirugía Bariátrica.";
    const whatsappNumber = "2222086833";
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Hola, quiero más información sobre el estudio ${estudioName}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <Container backgroundColor="#DBEBFA">
        <Row style={{ height: "100%" }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={5}
            xxl={5}
            style={{ margin: "0", padding: "0", position: 'relative' }}
          >
            <img
              className="mt-4 mt-lg-0 d-block d-lg-none"
              style={{ objectFit: "cover", width: "100%", height: '100%' }}
              src={imageMobile}
              alt="enfermedades-respiratorias-pediatrico"
            />

            <StyledImage
              className="d-none d-lg-block"
              imageDesktop={imageDesktop}
            />
            <NewService>
              <NewServiceTxt>¡NUEVO SERVICIO!</NewServiceTxt>
            </NewService>
          </Col>

          <Col
            className="bg-white"
            xs={12}
            sm={12}
            md={12}
            lg={8}
            xl={7}
            xxl={7}
            style={{ margin: "0", padding: "0" }}
          >
            <CustomBox>
              <PanelContent>
                <BoxTitle
                  className=""
                  bgImage={bgImage}
                  bgImageDesk={bgImageDesk}
                  textColor="#fff"
                >
                  <h1>
                    Valoración Integral para <br /> Cirugía Bariátrica.
                  </h1>
                </BoxTitle>

                <BoxPadding>
                  <Paragraph>
                    Servicio de valoración del estado de salud para pacientes
                    interesados <br /> en realizarse cirugía bariátrica a través
                    de una{" "}
                    <span style={{ color: "#F9AD62" }}>
                      evaluación médica, <br /> nutricional, psicológica y
                      estudios de laboratorio.
                    </span>
                  </Paragraph>

                  <ContainerPricing>
                    <BoxPagoLinea>
                      <PriceLinea>$7,500.00 <small>MXN</small></PriceLinea>
                      <button onClick={TomaDomicilioEvt}>Más información</button>
                      <PriceDetails></PriceDetails>
                   
                    </BoxPagoLinea>
                    <BoxPagoSucursal>
                        <PriceLinea>$6,750.00 <small>MXN</small></PriceLinea>
                      <BtnPagarAhora onClick={() => payStudy()}>
                      Paga ahora -10%
                      </BtnPagarAhora>
                    <PriceDetails>Precio en línea</PriceDetails>
        
                    </BoxPagoSucursal>

                  </ContainerPricing>
                </BoxPadding>
                    <BoxBgBottom bgImageBottom={bgImageBottom}>
                      <span>
                      ¡Hasta 3 meses sin intereses!
                      </span>
                    </BoxBgBottom>
              </PanelContent>
            </CustomBox>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ValoracionIntegral;
