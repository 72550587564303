import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Row } from "react-bootstrap";
import { TestCardnew } from "../studies/testCardnew";
import { useSelector } from "react-redux";

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #0a7a71;
    border-radius: 4px;
  }
  > .slick-dots li button:before {
    display: none;
  }
  > .slick-dots li.slick-active {
    width: 29px;
    background: #fff;
  }
  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

export const StudiesSlidernew = (props) => {
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getGroupedSections = (studies, isMobile) => {
    if (isMobile) {
      return studies.map((study) => [study]); // Mostrar de a 1 en móviles
    } else {
      return [studies.slice(0, 6), studies.slice(6)]; // Mostrar 6 y luego los 2 restantes
    }
  };

  const [groupedSections, setGroupedSections] = useState(
    getGroupedSections(props?.studies || [], isMobile)
  );

  useEffect(() => {
    setGroupedSections(getGroupedSections(props?.studies || [], isMobile));
  }, [props?.studies, isMobile]);

  return (
    <>
      {isMobile ? (
        <StyledSlider
          dots={false}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={4000}
          cssEase={"linear"}
          speed={600}
        >
          {props?.studies?.map((test, index) => (
            <TestCardnew
              idClienteLabopat={idClienteLabopat}
              data={test}
              key={index}
              highlight={true}
            />
          ))}
        </StyledSlider>
      ) : (
        <StyledSlider
          dots={false}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={4000}
          cssEase={"linear"}
          speed={600}
          
        >
          {groupedSections.map((group, groupIndex) => (
            <div key={groupIndex}>
              <Row>
                {group.map((test, index) => (
                  <TestCardnew
                    idClienteLabopat={idClienteLabopat}
                    data={test}
                    key={index}
                    highlight={true}
                  />
                ))}
              </Row>
            </div>
          ))}
        </StyledSlider>
      )}
    </>
  );
};
