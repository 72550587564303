import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import { UilInvoice } from "@iconscout/react-unicons";
import { useSelector, useDispatch } from "react-redux";
import { resetCart } from "../../actions/shoppingCart";
import { useEffect } from "react";
import { resetPatientInfo } from "../../actions/patientInfo";
import axios from "axios";
import { Loader } from "../general/spinner";

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 20px;
  @media (max-width: 767px) {
    padding: 20px 30px;
  }
`;
const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  background: linear-gradient(
    90deg,
    #0cac8c -13.75%,
    #30df99 39.04%,
    #34d6a2 68.36%,
    #5097e3 131%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
const Title = styled.h1`
  text-align: center;
  color: #474a4b;
  font-size: 35px;
  font-weight: 700;
`;

const ContainerInfoUser = styled.div`
  text-align: left;
  line-height: 8px;
  padding: 10px 30px;
  @media (max-width: 767px) {
    line-height: 25px;
  }
  p {
    color: #474a4b;
    font-size: 20px;
    font-weight: 400;
  }
`;

const Card = styled.div`
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 14px 26px 0px #00000026;
`;

const CardTitle = styled.h2`
  font-size: 24px;
  color: #474a4b;
  font-weight: 700;
  margin: 0px;
`;

const CardBody = styled.div``;

const BoxTitle = styled.div`
  background: #e4ddf870;
  padding: 10px 10px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
`;

const PricingBlock = styled.div`
  border-bottom: 1px solid#E0ECEC;
  &:last-child {
    border-bottom: 3px dashed #ccc;
  }
  padding: 10px 0px;
  h3 {
    color: #474a4b;
    font-size: 20px;
    font-weight: 500;
  }
  span {
    color: #474a4bb8;
    font-size: 18px;
    font-weight: 500;
  }
`;

const BoxBtnQuote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  color: #fff;
  padding: 5px 80px;
  cursor: pointer;
  border-radius: 10px 50px 10px 50px;
  background: #474a4b;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.4s ease;
  &:hover {
    background: #333737;
  }
  @media (max-width: 991px) {
    padding: 0px 20px;
  }
`;

const ConsultationAndRecommendations = styled.div`
  text-align: left;
  p {
    color: #474a4b;
    font-size: 14px;
    font-weight: 400;
    line-height: 5px;
    @media (max-width: 767px) {
      line-height: 19px;
    }
  }
`;

const ContainerImgQr = styled.div`
  text-align: center;
  padding: 15px 0px;
`;

const ContainerTxtQr = styled.div`
  padding: 15px 30px;
  h4 {
    color: #0cac8c;
    font-size: 27px;
    font-weight: 600;
  }
  p {
    color: #474a4bbd;
    font-size: 13px;
    font-weight: 500;
  }
`;

const ContainerBtnDownoaldPdf = styled.div`
  width: 260px;
  height: 40px;
  background: #4d99e5;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #3581b8;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }
`;

const ContainerTotalPayBranch = styled.div`
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-direction: column;
  align-items: center;
  span {
    color: #474a4b9e;
    font-size: 16px;
    font-weight: 600;
  }
`;

const BoxBtnPagarAhora = styled.div`
  a {
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    background: #f9ad62;
    padding: 5px 20px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 14px 26px 0px #00000026;
    &:hover {
      background-color: #f89c41;
    }
    @media (max-width: 991px) {
      padding: 5px 10px;
    }
  }
`;

const ContainerDate = styled.div`
  text-align: center;
  padding: 0px 0px;
  p {
    color: #0cac8c;
    font-weight: 700;
    font-size: 32px;
    line-height: 10px;
    @media (max-width: 991px) {
      margin-bottom: 5px;
      line-height: 30px;
    }
  }
`;

const ContainerInfoSucursal = styled.div`
  text-align: left;
  p {
    color: #474a4b;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    @media (max-width: 991px) {
      line-height: 18px;
    }
  }
`;

const Prices = styled(Row)`
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row;
  p {
    width: auto;
    font-family: "IBM Plex Sans";
    padding-left: 0px;
    padding-right: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  #descuento {
    font-weight: 500;
    font-size: 18px;
    font-style: italic;
    line-height: 27px;
    letter-spacing: -0.03em;
    color: #474a4bb8;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 8px;
      padding-left: 0px;
    }
  }
`;

export const CotizacionConfirmation = (props) => {
  // Cambiar esta variable a true para ver el diseno de publico general
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);
  const logged = useSelector((state) => state.auth.uid);
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const shoppingCart = useSelector((state) => state.shoppingCart);
  const [cartItems, setCartItems] = useState([]);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var orderID = urlParams.get("order");
  const [newNet, setNewNet] = useState(false);
  const user = useSelector((state) => state.user?.data?.data);
  const [loading, setLoading] = useState(false);
  const [subTotalWeb, setSubTotalWeb] = useState(0);
  const [subTotalSuc, setSubTotalSuc] = useState(0);
  const total = props.subTotal;
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [confirm, setConfirm] = useState({
    folio: "",
    id: 0,
    ok: true,
    type: 0,
  });

  useEffect(() => {
    altaClientes();
  }, []);

  const checkPrices = () => {
    const pricesArray = cartItems.map((item) => item.price);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const oldPricesArray = cartItems.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    return [final, oldFinal];
  };

  const altaClientes = async (
    persona,
    check,
    fromRed,
    newNet,
    tipo,
    idPago,
    idPago2,
    cardInfo
  ) => {
    setLoading(true);
    const cartId = localStorage.getItem("cartId");
    const endpoint = "https://labopat-5792e.appspot.com/get-cart/?id=" + cartId;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    let request = await axios.get(endpoint, options);
    console.log(request);
    setCartItems(request.data.cart.items);
    var cartItems = request.data.cart.items;
    const pricesArray = request.data.cart.items.map((item) => item.price);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const oldPricesArray = request.data.cart.items.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setSubTotalWeb(final);
    setSubTotalSuc(oldFinal);
    setLoading(false);

    if (cartItems.length > 0) {
      await setLoading(true);

      console.log("************* ALTA CLIENTES ****************");

      console.log("********************************************");

      var formData = new FormData();
      //si tiene id cliente laopat
      formData.append(
        "idClienteLabopat",
        idClienteLabopat ? idClienteLabopat : 0
      );
      //personal data
      formData.append("uid", logged);
      const eldx = window.document?.getElementById("elDx")?.value;
      
      formData.append("newNetwork", newNet ? 1 : 0);
      formData.append("idNetwork", newNet ? 0 : fromRed?.id);
      //formData.append("otherPerson", persona.otherPerson);
      //studies

      cartItems.forEach((element) => {
        console.log(element);
        var ids = [];
        console.log(element.estudios);
        element.estudios.forEach((elem) => {
          formData.append("products[]", element.id);
          formData.append("categories[]", element.categoria);
          formData.append("productTitle[]", element.title);

          formData.append("studiesPrice[]", element.price);
          formData.append(
            "studiesPricePromedio[]",
            element.price / element.estudios.length
          );
          if (!ids.includes(element.id)) {
            ids.push(element.id);
          }

          console.log(elem.id);
          formData.append("studies[]", elem.id);
        });
        //
      });
      //place
      formData.append("place", 1);
      //date and hour

      formData.append("date", "2022-11-07"); // TODO: Change for today??
      formData.append("time", "08:10");

      var pagado = 1.1111;
      let tmp = checkPrices();
      pagado = tmp[0];

      console.log("a guardar : ", oldFinal);

      formData.append("subTotal", oldFinal);

      // Save idPacienteLabopat
      //const idPacienteLabopat = persona.idPacienteLabopat;

      //orden de paciente
      /*formData.append(
              "refOrdenMedico",
              window.document.getElementById("refMedico").value
            );*/
      formData.append("paymentType", 2);
      //formData.append("idPacienteLabopat", persona.idPacienteLabopat);

      // Save fields for order
      // Additional studies field
      const additionalStudies = window.document?.getElementById(
        "theAdditionalStudies"
      )?.value;
      formData.append("additionalStudies", additionalStudies ?? "");
      // Observations field
      const observations =
        window.document?.getElementById("theObservations")?.value;
      formData.append("observations", observations ?? "");

      // Other person with previous sol
      /*if (persona.id) {
            formData.append("idSol", persona.id);
          }*/

      formData.append("orderType", "cotizacion");
      formData.append("fase", 2);

      formData.append("order", orderID ?? "");

      // Uncomment next 3 lines to see final formData info
      // for (var pair of formData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }

      // Handle URL base on  type of order
      let response = await axios.post(
        "https://lpqsystem.com/nuevaCotizacion",
        formData
      );

      console.log(response.data);
      if (response.data.ok) {
        await setConfirm(response);

        await setLoading(false);
        dispatch(resetCart());
        localStorage.removeItem("cartId");
        localStorage.setItem("cartId", "");
        dispatch(resetPatientInfo());
        localStorage.removeItem("patientInfo");
        console.log(localStorage);
        console.log("thanks");
        console.log(props);
      } else {
        window.alert("*" + response.data.error);
      }
    } else {
      window.alert("No hay items en el carrito");
    }
  };

  return (
    <>
      {loading ? <Loader show={loading} /> : null}
      <Container>
        <IconContainer>
          <FaCheck color="#fff" size={40} />
        </IconContainer>
        <Title className="pt-3 pb-3">Hola, ¡Aquí tiene su cotización!</Title>

        <Row>
          <Col sm={12} md={6} lg={8} xl={8} xxl={8}>
            <Card>
              <span
                className="pb-5"
                style={{
                  colorbackground: "#474A4B",
                  fontSize: "20px",
                  fontWeight: "500",
                }}
              >
                Resumen de la cotización
              </span>
              <BoxTitle className="mt-3">
                <CardTitle>No. {confirm.data?.folio}</CardTitle>
              </BoxTitle>
              <CardBody>
                {cartItems.map((item) => (
                  <PricingBlock className="mt-3">
                    <h3>{item.title}</h3>
                    <div className="d-flex gap-3">
                      <span>{currency(item.oldPrice)} MXN</span>
                    </div>
                  </PricingBlock>
                ))}
              </CardBody>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginLeft: "0%",
                  marginTop: "",
                  alignItems: "center",
                }}
              >
                <ContainerTotalPayBranch>
                  <p>
                    <UilInvoice />
                    <span>Total pago en Sucursal:</span>
                  </p>
                  <span>{currency(subTotalSuc)} MXN</span>
                </ContainerTotalPayBranch>
                <br />
                <ContainerTotalPayBranch>
                  <p>
                    <UilInvoice />
                    <span>Descuento pago en línea:</span>
                  </p>
                </ContainerTotalPayBranch>
                <BoxBtnPagarAhora>
                  <a
                    href={"/checkoutp?notForMe=1&order=" + confirm.data?.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pagar ahora {currency(subTotalWeb)}
                  </a>
                </BoxBtnPagarAhora>
                <br />
                <Prices>
                  {" "}
                  <p id="descuento">
                    {subTotalSuc - subTotalWeb > 0 && (
                      <>
                        Ahorras:
                        {currency(subTotalWeb - subTotalSuc)} MXN
                      </>
                    )}
                  </p>
                </Prices>
              </div>
              {/*<ContainerDate className="mt-3">
                  <p>
                    {days[props.date.getDay()]}{" "}
                    {String(props.date.getDate()).padStart(2, "0")} de{" "}
                    {months[props.date.getMonth()]} {props.date.getFullYear()}
                  </p>
                  <p>{props.time}</p>
                </ContainerDate>
    
                <ContainerInfoSucursal className="mt-4">
                  <p className="fw-bolder">Sucursal: {props.place.name}</p>
                  <p>{props.place.address}</p>
                </ContainerInfoSucursal>*/}

              <BoxBtnQuote>COTIZACIÓN</BoxBtnQuote>

              <ConsultationAndRecommendations className="mt-4">
                <p>*Consulta las condiciones de toma.</p>
              </ConsultationAndRecommendations>
            </Card>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={4}
            xl={4}
            xxl={4}
            className="d-flex flex-column justify-content-center"
          >
            <ContainerImgQr>
              {confirm.data?.id && (
                <QRCode
                  alt="qrt-muestra"
                  width={255}
                  height={255}
                  style={{ margin: "0 auto" }}
                  value={confirm.data?.id.toString()}
                />
              )}
            </ContainerImgQr>
            <ContainerTxtQr>
              <h4>Presenta el código al tomador de muestra</h4>
              <p>
                El tomador de muestra validará tu informacion al escanear este
                código, iniciando el proceso de seguimiento de tu muestra.
              </p>
            </ContainerTxtQr>
            <ContainerBtnDownoaldPdf>
              <IoArrowDownCircleOutline color="#fff" size={28} />
              <a
                href={
                  "https://lpqsystem.com/getCotizacion?id=" + confirm.data?.id
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar cotización
              </a>
            </ContainerBtnDownoaldPdf>
          </Col>
        </Row>
      </Container>
    </>
  );
};