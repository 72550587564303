import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { UilInvoice } from "@iconscout/react-unicons";
import { resetCart } from "../../actions/shoppingCart";
import { useEffect } from "react";
import { resetPatientInfo } from "../../actions/patientInfo";
import { TrackGoogleAnalitycsEventWhitValue } from "../../analytics/google-analytics";
        

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 20px;
  @media (max-width: 767px) {
    padding: 20px 30px;
  }
`;
const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  background: linear-gradient(
    90deg,
    #0cac8c -13.75%,
    #30df99 39.04%,
    #34d6a2 68.36%,
    #5097e3 131%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
const Title = styled.h1`
  text-align: center;
  color: #474a4b;
  font-size: 35px;
  font-weight: 700;
`;

const ContainerInfoUser = styled.div`
  text-align: left;
  line-height: 8px;
  padding: 10px 30px;
  @media (max-width: 767px) {
    line-height: 25px;
  }
  p {
    color: #474a4b;
    font-size: 20px;
    font-weight: 400;
  }
`;

const Card = styled.div`
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 14px 26px 0px #00000026;
`;

const CardTitle = styled.h2`
  font-size: 24px;
  color: #474a4b;
  font-weight: 700;
  margin: 0px;
`;

const CardBody = styled.div``;

const BoxTitle = styled.div`
  background: #e4ddf870;
  padding: 10px 10px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
`;

const PricingBlock = styled.div`
  border-top: 1px solid#E0ECEC;
  &:last-child {
    border-bottom: 3px dashed #ccc;
  }
  padding: 10px 0px;
  h3 {
    color: #474a4b;
    font-size: 20px;
    font-weight: 500;
  }
  span {
    color: #474a4bb8;
    font-size: 18px;
    font-weight: 500;
  }
`;

const TotalPay = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  span {
    color: #474a4b;
    font-size: 18px;
    font-weight: 500;
  }
`;

const ContainerDate = styled.div`
  text-align: center;
  padding: 30px 0px;
  p {
    color: #474a4b;
    font-weight: 700;
    font-size: 32px;
    line-height: 10px;
    @media (max-width: 991px) {
      margin-bottom: 5px;
      line-height: 24px;
    }
  }
`;

const ContainerInfoSucursal = styled.div`
  text-align: left;
  p {
    color: #474a4b;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    @media (max-width: 991px) {
      line-height: 18px;
    }
  }
`;

const BoxBtnPagado = styled.div`
  margin: 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 80px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px 50px 10px 50px;
  background: #0cac8c;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color 0.4s ease;
  &:hover {
    background: #098d6f;
  }
  @media (max-width: 991px) {
    padding: 5px 30px;
  }
`;

const ConsultationAndRecommendations = styled.div`
  text-align: left;
  p {
    color: #474a4b;
    font-size: 14px;
    font-weight: 400;
    line-height: 5px;
    @media (max-width: 991px) {
      line-height: 19px;
    }
  }
`;

const ContainerImgQr = styled.div`
  text-align: center;
  padding: 15px 0px;
`;

const ContainerTxtQr = styled.div`
  padding: 15px 30px;
  h4 {
    color: #0cac8c;
    font-size: 27px;
    font-weight: 600;
  }
  p {
    color: #474a4bbd;
    font-size: 13px;
    font-weight: 500;
  }
`;

const ContainerBtnDownoaldPdf = styled.div`
  width: 260px;
  height: 40px;
  background: #4d99e5;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #3581b8;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }
`;

export const PaymentConfirmation = (props) => {
  // Cambiar esta variable a true para ver el diseno de publico general
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("Alejandra");
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    dispatch(resetCart());
    localStorage.removeItem("cartId");
    localStorage.setItem("cartId", "");
    dispatch(resetPatientInfo());
    localStorage.removeItem("patientInfo");
    console.log(localStorage);
    console.log("thanks");
    console.log(props);
  }, []);

  useEffect(() => {
    if (props.total !== undefined && props.total !== null) {
      TrackGoogleAnalitycsEventWhitValue(
        " SuccessPayment",          
        " SuccessPayment",          
        "success_payment_confirmation", 
        props.total                      
      );
    }
  }, [props.total, props.confirm?.timestamp]); 

  return (
    <Container>
      <IconContainer>
        <FaCheck color="#fff" size={40} />
      </IconContainer>
      <Title className="pt-3 pb-3">
        {props.confirm && props.confirm.data?.sol?.nombre},¡Tu pago fue exitoso!
      </Title>
      {isAuthenticated && (
        <ContainerInfoUser>
          <p className="fw-bolder">
            Tu comprobante de pago y solicitud de estudios se enviaron a:
          </p>
          <p className="mt-5">
            {props.confirm &&
              props.confirm.data?.sol?.nombre +
                " " +
                props.confirm.data?.sol?.app +
                " " +
                props.confirm.data?.sol?.apm}
          </p>
          <p>{props.confirm && props.confirm.data?.sol?.emailContacto}</p>
          <p>
            WhatsApp: {props.confirm && props.confirm.data?.sol?.telContacto}
          </p>
        </ContainerInfoUser>
      )}

      <Row>
        <Col sm={12} md={6} lg={8} xl={8} xxl={8}>
          <Card>
            <span
              className="pb-5"
              style={{
                colorbackground: "#474A4B",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Resumen de solicitud
            </span>
            <BoxTitle className="mt-3">
              <CardTitle>
                No. de solicitud: {props.confirm && props.confirm.data?.folio}
              </CardTitle>
            </BoxTitle>
            <CardBody>
              {props.items.map((item) => (
                <PricingBlock className="mt-3">
                  <h3>{item.title}</h3>
                  <span>{currency(item.price)} MXN</span>
                </PricingBlock>
              ))}
            </CardBody>
            <TotalPay className="mt-3">
              <p>
                <UilInvoice />
                <span>Total pagado:</span>
              </p>
              <span>{currency(props.total)} MXN</span>
              
            </TotalPay>
            {props.discount !== 0 && (
                <div style={{ textAlign: "center" }}>
                  <hr />
                  <p>
                    Usaste el cupón "
                    <span style={{ fontWeight: "800" }}>{props.coupon}</span>"
                  </p>
                  <p>
                    Obtuviste un descuento de{" "}
                    <span style={{ fontWeight: "800" }}>
                      {currency(props.discount)} MXN
                    </span>
                  </p>
                  <hr />
                </div>
              )}
            <span
              className="mt-3 d-block"
              style={{
                colorbackground: "#474A4B",
                fontSize: "20px",
                fontWeight: "400",
              }}
            >
              Toma de muestra
            </span>
            <ContainerDate className="mt-3">
              <p>
                {days[props.date.getDay()]}{" "}
                {String(props.date.getDate()).padStart(2, "0")} de{" "}
                {months[props.date.getMonth()]} {props.date.getFullYear()}
              </p>
              <p>{props.time}</p>
            </ContainerDate>

            <ContainerInfoSucursal className="mt-4">
              <p className="fw-bolder">Sucursal: {props.place.name}</p>
              <p>{props.place.address}</p>
            </ContainerInfoSucursal>

            <BoxBtnPagado className="mt-4">pagado</BoxBtnPagado>

            <ConsultationAndRecommendations className="mt-4">
              <p>*Consulta las condiciones de toma.</p>
              <p>*Te recomendamos llegar 5 minutos antes de su cita.</p>
            </ConsultationAndRecommendations>
          </Card>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={4}
          xxl={4}
          className="d-flex flex-column justify-content-center"
        >
          <ContainerImgQr>
            {props.confirm.data?.id && (
              <QRCode
                alt="qrt-muestra"
                width={255}
                height={255}
                style={{ margin: "0 auto" }}
                value={props.confirm.data?.id.toString()}
              />
            )}
          </ContainerImgQr>
          <ContainerTxtQr>
            <h4>Presenta el código al tomador de muestra</h4>
            <p>
              El tomador de muestra validará tu informacion al escanear este
              código, iniciando el proceso de seguimiento de tu muestra.
            </p>
          </ContainerTxtQr>
          <ContainerBtnDownoaldPdf>
            <IoArrowDownCircleOutline color="#fff" size={28} />
            <a
              href={"https://lpqsystem.com/cita?id=" + props.confirm.data?.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar comprobante
            </a>
          </ContainerBtnDownoaldPdf>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentConfirmation;
