import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import axios from "axios";
import { UilTimes } from "@iconscout/react-unicons";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../general/spinner";
import { HelmetSEO } from "../../helpers/HelmetSEO";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Rate } from "antd";

const users = [
  {
    id: 0,
    name: "Prefiero no decirlo",
  },
  {
    id: 1,
    name: "Enf. Silvia Córdova",
  },
  {
    id: 2,
    name: "QFB. Fernanda Alcántara",
  },
  {
    id: 3,
    name: "Dr. Carlos Ávila",
  },
  /*{
    id: 4,
    name: "Enf. Lizeth Rodríguez",
  },*/
  {
    id: 5,
    name: "Enf. Judith Soriano",
  },
  {
    id: 6,
    name: "QFB. Vianey Ramos",
  },
  {
    id: 7,
    name: "QFB. Sandra Meneses",
  },
  /*{
    id: 8,
    name: "Laura García",
  },*/
  {
    id: 9,
    name: "Sara Ramírez",
  },
  {
    id: 11,
    name: "Dr. Alejandro Toro",
  },
  /*{
    id: 12,
    name: "TER. Francisco Hernández",
  },*/
  {
    id: 13,
    name: "Kathya Vera",
  },
  {
    id: 14,
    name: "Cynthia Sanchez",
  },
  {
    id: 15,
    name: "QFB. Sarahí Juárez",
  },
  {
    id: 16,
    name: "Analí Orozco",
  },
  {
    id: 17,
    name: "Arantxa Huerta",
  },
  {
    id: 18,
    name: "Mónica Santiago",
  },
  {
    id: 19,
    name: "Lorena Garay",
  },
  {
    id: 20,
    name: "QFB. Norberto Ibarra",
  },
];
const Title = styled.h1`
  font-weight: 900;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #3b3b3b;
  margin-top: 44px;
  margin-bottom: 24px;
  @media only screen and (min-width: 768px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }
`;
const RegularP = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 40px;
  @media only screen and (min-width: 1200px) {
    width: 46%;
    max-width: 546px;
    margin: 0 auto 40px;
  }
`;
const OtherP = styled.p`
  @media only screen and (min-width: 1200px) {
    width: 90%;
    margin-left: 5%;
  }
`;
const LittleP = styled.p`
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #474a4b;
  margin-bottom: 0;
`;
const FormButton = styled(Button)`
  width: 260px;
  height: 48px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    margin-top: 31px;
    width: 300px;
  }
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;
const StyledForm = styled(Form)`
  input,
  textarea {
    font-family: "IBM Plex Sans";
    border: none;
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    // margin-left: 5%;
  }
  > .form-floating {
    margin-top: 23px;
  }
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
  }
  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans";
  }
  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad92;
  }
  > div input.form-check-input:checked {
    background-color: #00ad92;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }
  > div input.form-check-input:checked {
    background-color: #00ad90;
  }
  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #00ac90;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    width: 100%;
    margin-bottom: 8px;
    span {
      font-weight: 800;
    }
  }
  h3 {
    margin-bottom: 16px;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00ac90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;
const WhiteButton = styled(Button)`
  color: #00ac90;
  border: none;
  margin-top: -10px;
  margin-bottom: 0px;
  border: 2px solid #00ad92;
  width: 260px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    width: 300px;
  }
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #00ad90;
  }
`;
const InputStyled = styled(Form.Check)`
  > input {
    cursor: pointer;
    border: 1px solid #30df99 !important;
    :checked {
      border: 1px solid #30df99 !important;
      background-color: #30df99 !important;
    }
  }
`;
export const QualityForm = () => {
  const [buttonEnable, setButtonEnable] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [formData, setFormData] = useState();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var user = urlParams.get("user");
  const [sucursal, setSucursal] = useState("");
  const [token, setToken] = useState("");
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const questions = [
    // {
    //   id: "1",
    //   question:
    //     "¿Qué tan satisfecho(a) está con la experiencia general de su visita al laboratorio?",
    //   options: [
    //     "Muy satisfecho(a)",
    //     "Satisfecho(a)",
    //     "Indiferente",
    //     "Insatisfecho(a)",
    //     "Muy insatisfecho(a)",
    //   ],
    // },
    {
      id: "1",
      text: "PERSONAL",
      question: "¿El personal le dio la bienvenida y se presentó a su llegada?",
      options: [
        "De acuerdo",
        "En desacuerdo",
      ],
    },
    {
      id: "2",
      question: "¿El personal estaba debidamente uniformado e identificado?",
      options: [
        "Correcto",
        "Incorrecto",
      ],
    },
    // {
    //   id: "4",
    //   question:
    //     "¿Cómo calificaría la amabilidad y cortesía del personal durante su visita?",
    //   options: [
    //     "Muy satisfecho(a)",
    //     "Satisfecho(a)",
    //     "Indiferente",
    //     "Insatisfecho(a)",
    //     "Muy insatisfecho(a)",
    //   ],
    // },
    // {
    //   id: "5",
    //   question:
    //     "¿El personal le hizo sentir cómodo y seguro durante la toma de muestra/estudio?",
    //   options: [
    //     "Totalmente de acuerdo",
    //     "De acuerdo",
    //     "Neutral",
    //     "En desacuerdo",
    //     "Totalmente en desacuerdo"
    //   ],
    // },
    // {
    //   id: "6",
    //   question:
    //     "¿Considera que el personal estaba capacitado y dispuesto para responder sus preguntas o inquietudes?",
    //   options: [
    //     "Totalmente de acuerdo",
    //     "De acuerdo",
    //     "Neutral",
    //     "En desacuerdo",
    //     "Totalmente en desacuerdo"
    //   ],
    // },
    {
      id: "3",
      text: "TOMA DE MUESTRA",
      question:
        "¿Le pidieron que firme el consentimiento informado?",
      options: [
        "Correcto",
        "Incorrecto",
      ],
    },
    // {
    //   id: "8",
    //   question:
    //     "¿Le explicaron el procedimiento de toma de muestra antes de que se realizara?",
    //   options: [
    //     "Si",
    //     "No",
    //   ],
    // },
    {
      id: "4",
      question:
        "¿Le mostraron el material nuevo y debidamente empaquetado para la toma de muestra?",
      options: [
        "De acuerdo",
        "En desacuerdo",
      ],
    },
    {
      id: "5",
      question: "¿Etiquetaron todas las muestras en mi presencia?",
      options: [
        "Correcto",
        "Incorrecto",
      ],
    },
    // {
    //   id: "6",
    //   question: "¿Cómo calificaría la atención brindada y/o toma de muestra del personal durante su visita?",
    //   options: [
    //     "Totalmente en desacuerdo",
    //     "En desacuerdo",
    //     "Neutral",
    //     "De acuerdo",
    //     "Totalmente de acuerdo",
    //    ],
    // },
    // {
    //   id: "11",
    //   question: "¿Le informaron los tiempos de entrega de los resultados?",
    //   options: [
    //     "Si",
    //     "No",
    //   ],
    // },
    // {
    //   id: "12",
    //   question: "¿El tiempo de espera para ser atendido fue razonable?",
    //   options: [
    //     "Si",
    //     "No",
    //   ],
    // },
    // {
    //   id: "13",
    //   text: "GENERALES",
    //   question: "¿Recomendaría este laboratorio a otras personas?",
    //   options: [
    //     "Si",
    //     "No",
    //   ],
    // },
  ];

  // Estado para almacenar las preguntas aleatorias
  const [randomQuestions, setRandomQuestions] = useState([]);
  useEffect(() => {
    // Función para seleccionar aleatoriamente 4 preguntas de la lista
    const selectRandomQuestions = () => {
      const shuffledQuestions = questions.sort(() => 0.5 - Math.random());
      const selectedQuestions = shuffledQuestions.slice(0, 13);
      setRandomQuestions(selectedQuestions);
    };
    selectRandomQuestions();
  }, []);

  // Arreglo para tooltip de estrellas
  const starsTooltips = [
    "Totalmente en desacuerdo",
    "En desacuerdo",
    "Neutral",
    "De acuerdo",
    "Totalmente de acuerdo",
   ]
  // Estado para almacenar calificacion de estrellas
  const [rating, setRating] = useState(0);

  const onSubmit = (data) => {
    setShow(true);
    setFormData({...data, stars: rating});
  };

  // Estado para almacenar las respuestas de las preguntas
  const [answers, setAnswers] = useState([]);

  // Función para manejar la selección de respuesta
  const handleAnswerSelect = (index, option) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = option;
    setAnswers(updatedAnswers);
  };

 /*useEffect(() => {
    if (
      user == 1 ||
      user == 3 ||
      user == 5 ||
      user == 8 ||
      user == 9 ||
      user == 10 ||
      user == 14 ||
      user == 15 ||
      user == 16
    ) {
      setSucursal("Palmas plaza");
    } else if (
      user == 2 ||
      user == 7 ||
      user == 11 ||
      user == 12 ||
      user == 13 ||
      user == 15
    ) {
      setSucursal("Torres medicas");
    } else if (user == 4 || user == 15) {
      setSucursal("La paz");
    } else if (user == 6 || user == 15) {
      setSucursal("Bosques de zavaleta");
    } else if (user == 17) {
      setSucursal("Hospital Aqua");
    }
  }, []); */
  
  // Modal confirmation
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmitConfirm = (data) => {
    setButtonEnable(false);
    // const id = toast.loading("Enviando...");
    setShow(false);
    setLoading(true);
    axios
      .post("https://labopat-5792e.appspot.com/calidad/save-form", {
        name: data.name,
        email: data.email,
        telefono: data.phone,
        quien: data.quien,
        pregunta1: data.answer0,
        pregunta2: data.answer1,
        pregunta3: data.answer2,
        pregunta4: data.answer3,
        pregunta5: data.answer4,
        stars: data.stars,
        sucursal: data.sucursal,
        mensaje: data.message,
      })
      .then((res) => {
        console.log(res);
        if (res.data.ok) {
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("message").value = "";
          setLoading(false);
          setShowSuccess(true);
          setTimeout(() => {
            setButtonEnable(true);
          }, 1000);
        } else {
          console.log("Error al guardar el formulario");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `https://web-service-email.uc.r.appspot.com/ayuda?nombre=${data.name}&apellido=${data.lastName}&email=${data.email}&telefono=${data.phone}&quien=${data.quien}&msg=${data.message}&sucursal=${data.sucursal}&pregunta1=${data.answer0}&pregunta2=${data.answer1}&pregunta3=${data.answer2}&pregunta4=${data.answer3}&pregunta5=${data.answer4}&pregunta6=${data.answer5}&pregunta7=${data.answer6}&pregunta8=${data.answer7}&pregunta9=${data.answer8}&pregunta10=${data.answer9}&pregunta11=${data.answer10}&pregunta12=${data.answer11}&pregunta13=${data.answer12}`
      )
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          toast.error("Ha ocurrido un error :(", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setButtonEnable(true);
          }, 2000);
        }, 200);
      });
  };
  // Modal success
  const [showSuccess, setShowSuccess] = useState(false);
  var printChange = (data) => {
    setButtonEnable(true);
    setToken(data);
    console.log(data);
  };
  const { width } = useWindowDimensions();
  let isMobile = width <= 768;
  return (
    <Container style={{ paddingBottom: "1px" }}>
      {loading ? <Loader show={loading} /> : null}
     <HelmetSEO
        title='Formulario de Calidad - Labopat'
        description='Ayúdanos a mejorar con tu opinión. Completa el formulario de calidad de Labopat y mejora nuestra atención.'
     />
      <Title>Queremos escucharte</Title>
      <Row>
        <RegularP>
          Gracias por elegirnos. Su satisfacción es nuestra prioridad, y cada
          día trabajamos para ofrecerle un servicio que le brinde comodidad y
          confianza. Por ello, le agradeceremos mucho si pudiera tomarse un
          momento para completar esta breve encuesta. Su opinión es
          indispensable para ayudarnos a mejorar para usted.
        </RegularP>
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <OtherP style={{ fontWeight: "500", fontSize: "22px" }}>
            Cuéntanos de ti
          </OtherP>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel controlId="name" label="Nombre del paciente*">
              <Form.Control
                type="text"
                {...register("name", {
                  required: true,
                })}
                placeholder="Nombre*"
              />
            </FloatingLabel>
            {/* <FloatingLabel controlId="lastName" label="Apellido">
              <Form.Control
                type="text"
                {...register("lastName", {
                  required: true,
                })}
                placeholder="Apellido"
              />
            </FloatingLabel> */}
            <FloatingLabel controlId="email" label="Email*" className="mb-3">
              <Form.Control
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                    message: "El correo no cumple con el formato",
                  },
                })}
                type="email"
                placeholder="name@example.com"
              />
            </FloatingLabel>
            <FloatingLabel controlId="phone" label="Teléfono">
              <Form.Control
                type="number"
                {...register("phone", {})}
                placeholder="Teléfono"
              />
            </FloatingLabel>
              <br /> 
            <Form.Label>
              Sucursal que visitó *
            </Form.Label>
            <Form.Select
              {...register("sucursal", {
                required: true,
              })}
             // value={sucursal}
            >
              <option value="">Selecciona</option>
              <option value="Local Palmas Plaza">Local Palmas Plaza</option>
              <option value="Torres médicas - Periférico">Torres médicas - Periférico</option>
              <option value="La Paz - Rosendo Márquez">La Paz - Rosendo Márquez</option>
              <option value="Bosques de Zavaleta - Zavaleta">Bosques de Zavaleta - Zavaleta</option>
              <option value="Toma a domicilio">Toma a domicilio</option>
            </Form.Select>
            <br />
            <Form.Label>Personal que lo atendió *</Form.Label>
            <Form.Select
              {...register("quien", {
                required: true,
              })}
              value={user}
            >
              <option value="">Selecciona</option>
              {users?.map((usuario) => (
                <option key={usuario?.id} value={usuario?.id}>
                  {usuario?.name}
                </option>
              ))}
            </Form.Select>
            
            {questions.map((question, index) => (
              <StyledForm key={index}>
                <br />
                <div style={{ fontSize: "15px", fontFamily: "IBM Plex Sans" }}>
                  {question.question}
                </div>
                {!isMobile ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 10px 10px 0px",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Form
                          style={{
                            fontSize: "15px",
                            fontFamily: "IBM Plex Sans",
                            fontWeight: "400",
                          }}
                          {...register(`answer${index}`, {
                            required: false,
                          })}
                        >
                          <input
                            style={{ height: "11px" }}
                            type="checkbox"
                            id={`option${optionIndex}`}
                            name={`answer${index}`}
                            value={option}
                            checked={answers[index] === option}
                            onChange={() => handleAnswerSelect(index, option)}
                          />
                          {option}
                        </Form>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Form
                          style={{
                            fontSize: "15px",
                            fontFamily: "IBM Plex Sans",
                            fontWeight: "400",
                          }}
                          {...register(`answer${index}`, {
                            required: false,
                          })}
                        >
                          <input
                            style={{ height: "11px" }}
                            type="checkbox"
                            id={`option${optionIndex}`}
                            name={`answer${index}`}
                            value={option}
                            checked={answers[index] === option}
                            onChange={() => handleAnswerSelect(index, option)}
                          />
                          {option}
                        </Form>
                      </div>
                    ))}
                  </div>
                )}
              </StyledForm>
            ))}
            <br />
            <Form.Label  style={{fontSize: "15px", fontFamily: "IBM Plex Sans"}}>
              ¿Cómo calificaría la atención brindada y/o toma de muestra del personal durante su visita?
            </Form.Label>
            <Form.Control style={{border:"none", display: "flex", justifyContent:"center"}} as="div" 
            {...register(`stars`)} >
            <Rate tooltips={starsTooltips} onChange={(e) => setRating(e)} value={rating} />
            </Form.Control>
            <FloatingLabel>
              <p>
                ¿Hay algo más que le gustaría añadir sobre su experiencia en el
                laboratorio?
              </p>
            </FloatingLabel>

            <FloatingLabel controlId="message" label="Escribe tu mensaje aquí*">
              <Form.Control
                as="textarea"
                {...register("message", {
                  required: true,
                })}
                placeholder="Escribe tu mensaje aquí*"
                style={{ height: "250px" }}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-center">
              <LittleP style={{ marginTop: "15px", marginBottom: "20px" }}>
                <b>Aviso legal: </b>
                Los datos recabados en este formulario son con la finalidad de
                dar atención a la solicitud requerida, por lo tanto, le
                informamos que puede consultar nuestro{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "#717171",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FAvisodeprivacidad.pdf?alt=media&token=32a816cc-89a7-4a07-b4ea-1a148cfcf7a4"
                >
                  aviso de privacidad.
                </a>
              </LittleP>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <InputStyled
                type="checkbox"
                label="He leído y acepto los términos y condiciones y aviso de privacidad de Labopat"
                onChange={() => setShowCaptcha(!showCaptcha)}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <ReCAPTCHA
                sitekey="6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT"
                render="explicit"
                onChange={printChange}
                onExpired={() => {
                  setToken(undefined);
                  setButtonEnable(false);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <FormButton
                variant="secondary"
                type="submit"
                disabled={buttonEnable && showCaptcha === true ? false : true}
              >
                Enviar
              </FormButton>
            </div>
          </StyledForm>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
      <StyledModal show={show} onHide={() => setShow(false)}>
        <div onClick={() => setShow(false)}>
          <UilTimes color="#fff" style={{ cursor: "pointer" }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Revisa tu mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Al buzon de Quejas y Sugerencias</h3>
          <p>
            {formData?.name}
          </p>
          <p>{formData?.email}</p>
          <p>{formData?.phone}</p>
          <p>{formData?.quien}</p>
          <p>{formData?.answer0}</p>
          <p>{formData?.answer1}</p>
          <p>{formData?.answer2}</p>
          <p>{formData?.answer3}</p>
          <p>{formData?.answer4}</p>
          <p>{formData?.stars}</p>
          <p>{formData?.sucursal}</p>
          <p>{formData?.message}</p>
          <FormButton onClick={() => onSubmitConfirm(formData)}>
            Confirmar y enviar
          </FormButton>
          <p style={{ textAlign: "center", marginBottom: "20px" }}>o</p>
          <WhiteButton variant="light" onClick={() => setShow(false)}>
            Regresar a editar
          </WhiteButton>
        </Modal.Body>
      </StyledModal>
      <StyledModal show={showSuccess} onHide={() => setShowSuccess(false)}>
        <div onClick={() => setShowSuccess(false)}>
          <UilTimes color="#fff" style={{ cursor: "pointer" }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Hemos recibido tu mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginBottom: "35px" }}>
            Intentaremos resolver las quejas o los reclamos tan pronto como sea
            posible. Si necesita algo más, llámenos al +52 221 231 1313.
          </p>
          <WhiteButton
            variant="light"
            onClick={() => {
              setShowSuccess(false);
              window.location.href = "/";
            }}
          >
            Regresar a página de inicio
          </WhiteButton>
        </Modal.Body>
      </StyledModal>
    </Container>
  );
};
