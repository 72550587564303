import React, { useEffect } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UilSearchAlt, UilUsersAlt, UilFilePlusAlt, UilFileMedicalAlt, UilUserPlus, UilClipboardAlt, } from '@iconscout/react-unicons';
import { Cart } from '../shopping-cart/cart';
import { useState } from 'react';
import { Searcher } from '../navbar/searcher';
import { PrivRoutes } from '../../routes/routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

const logoB = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flogo1.png?alt=media&token=dffc6866-cf6d-4c14-a494-60ceaf3f3219';

const Sections = [
  {
    title: "Buscar estudios",
    icon: <UilSearchAlt/>,
    desc: "Busca los estudios o paquetes creados",
    path: "search",
    olnyDoc: false,
  },
  {
    title: "Pacientes",
    icon: <UilUsersAlt/>,
    desc: "Encuentra el expediente médico de tus pacientes",
    path: "/pacientes",
    olnyDoc: true,
  },
  {
    title: "Paquetes de estudios",
    icon: <UilFilePlusAlt/>,
    desc: "Personaliza paquetes de estudios para tus pacientes",
    path: "/mis-paquetes",
    olnyDoc: true,
  },
  {
    title: "Sucursales",
    icon: <UilUserPlus/>,
    desc: "Localiza tu sucursal más cercana",
    path: "/sucursales",
    olnyDoct: false,
  },
]

const Title = styled.h1`
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #697077;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 18px;
`;

const Card = styled.div`
  border: 1px solid #E0ECEC;
  border-radius: 12px;
  padding: 24px;
  margin: 12px auto;
  cursor: pointer;
  transition: all ease .4s;
  h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: ${(props)=> props.mainColor};
  }
  p {
    margin-bottom: 0px;
  }
  :hover {
    background: #F6F6F6;
    box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
    transition: .4s;
  }
`;

const Nav = styled.div`
  width: 100%;
  display: flex;
  padding-right: 18px;
  p {
    width: auto;
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: auto;
    position: relative;
  }
`;

const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  position: absolute;
  left: 20px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

const CartCount = styled.div`
  background-color: ${(props) =>
    props.isDoctor ? '#4F90BA' : '#00AC90'};
  padding: 9px;
  position: absolute;
  top: -4px;
  right: -8px;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 12px;
  }
  @media only screen and (max-width: 1200px) {
    right: -25%;
  }
  @media only screen and (max-width: 992px) {
    right: -14%;
    padding: 6px;
  }
`;

export const Dashboard = ({setShowCart}) => {
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [ visible, setVisible ] = useState(false);
  const [ showSearcher, setShowSearcher ] = useState(false);
  const [packages, setPackages] = useState([]);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var forPatient = urlParams?.get("forPatient") === "1";
  const [loading, setLoading] = useState(false);
  const uid = useSelector((state) => state?.auth?.uid);
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const mainColor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  let isMobile = width <= 768;


  const dd = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaquetesMedico?uid=${uid}`
      );
      // console.log(requestPackages);
      if (requestPackages.data.ok) {
        setPackages(requestPackages.data.paquetes_medico);
        setLoading(false);
      } else {
        console.log('Error al obtener paquetes');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(()=>{
    dd();
    if (forPatient){
      setShowCart(true);
    }
  },[])

  const handleDelete = async (id) => {
    console.log(id);
    try {
      console.log('deleting package...?');
      setLoading(true);
      let response = await axios.get(
        `https://lpqsystem.com/eliminarPaquete?id=${id}`
      );
      console.log(response);
      dd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return(
    <Container style={{minHeight: '80vh', paddingTop: '25px'}} className={isMobile ? 'mt-5 pt-4' : ''}>
      <HelmetTitle
        title='Dashboard'
        name='description'
        content='content'
      />
      <Nav>
        <p onClick={()=>setVisible(true)}>Estudios <UilClipboardAlt color="#4F90BA"/>
          <CartCount isDoctor={isDoctor}>
            <p>
              {items.length}
            </p>
          </CartCount>
        </p>
      </Nav>

      <hr style={{color: "#C1D9D8"}}/>

      <Title>Bienvenid@</Title>
      <p style={{marginLeft: "18px"}}>Elige alguna opción para continuar</p>

      <Row>
        {Sections.map((section)=>
            (isDoctor || (!isDoctor && !section.olnyDoc))?
              <Col xs={12} lg={6}>
                <Card onClick={()=> section.path === "search" ? setShowSearcher(true) : window.location.href = section.path} mainColor={mainColor}>
                  <h1>{section.icon}&nbsp;{section.title}</h1>
                  <p>{section.desc}</p>
                </Card>
              </Col>
            :null

        )}
      </Row>

      <Modal show={showSearcher} fullscreen={true} onHide={() => setShowSearcher(false)}>
        <Modal.Body style={{padding: "0px"}}>
          <Container>
            <div style={{width: "100%", paddingTop: "30px", minHeight: "100vh", position: "relative"}}>
              <Link to='/' replace>
                <DesktopLogo
                  alt='Logo Labopat'
                  src={logoB}
                  style={{ width: '100%', maxWidth: '164px', height: 'auto' }}
                  />{' '}
              </Link>
              <Searcher hideSearch={()=>setShowSearcher(false)} spaceTop="100px" mainColor={mainColor} packages={packages} handleDelete={handleDelete}/>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Cart
        onClose={() => {setVisible(false)}}
        visible={visible}
        openSearcher={()=>setShowSearcher(true)}
      />

    </Container>
  );
}