import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from '@firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db, app } from '../firebase/firebase-config';
import { types } from '../types/types';
import { getAuth } from "firebase/auth";
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import handleAlert from '../helpers/handleAlert';

export const createUser = (
  uid,
  name,
  email,
  phone,
  photoURL = null,
  fathersLastname = '',
  mothersLastname = '',
  from=0,
) => {
  return (dispatch) => {
    const newUser = {
      name: name,
      fathersLastname: fathersLastname,
      mothersLastname: mothersLastname,
      email: email,
      phone: phone,
      newUser: true,
      photoURL: photoURL,
      profile: {
        type: 0,
      },
      created: Timestamp.fromDate(new Date()),
    };
    var idLpqPac = 0;

    setDoc(doc(db, 'users', `${uid}`), newUser)
      .then((res) => {
        if(from==0){
          window.location.href = '/checkoutp';
        }else{
          handleAlert('success', 'Los datos de tu perfil se ha actualizado correctamente', 'Perfil actualizado')
        }
      })
      .catch((e) => {
        alert('Error al guardar usuario');
        console.log('[User] ', e);
        // toast('error', 'Error al guardar usuario');
      });
  };
};

export const updateInfoUser = (data) => {
  return (dispatch, getState) => {
    const { uid } = getState().auth;
    console.log(uid);
    const dbRef = doc(db, 'users', `${uid}`);

    getDoc(dbRef).then((res)=>{
      console.log("existe ?",res.exists())
      if(res.exists()){
        updateDoc(dbRef, data)
        .then((res) => {
          handleAlert('success', 'Los datos de tu perfil se ha actualizado correctamente', 'Perfil actualizado')
          console.log('[User-Update-Data]: OK');
          getUserInfo(uid).then((user) => {
            dispatch(userInfo(user));
          });
        })
        .catch((error) => {
          console.log('[Error] ', error);
          handleAlert('error', 'Hubo un error al guardar los datos', 'Error al actualizar');
        });
      }{
        console.log("creamos el perfil*: ",uid)
        console.log(res.exists())
        //console.log(data)
        if(!res.exists()){
          dispatch(createUser(uid,data.name,data.email, data.phone,'',data.fathersLastname, data.mothersLastname,1))
        }
        //
      }
    })

    
  };
};

export const updateUserPhotoURL = (url) => {
  return (dispatch, getState) => {
    const { uid } = getState().auth;
    const dbRef = doc(db, 'users', `${uid}`);

    updateDoc(dbRef, { photoURL: url })
      .then((res) => {
        // toast('success', 'Datos guardados exitosamente');
        console.log('[User] ', res);
        getUserInfo(uid).then((user) => {
          dispatch(userInfo(user));
        });
      })
      .catch((error) => {
        console.log('[Error] ', error);
        // toast('error', 'Error al guardar datos');
      });
  };
};

export const uploadFile = (file, path) => {
  return (dispatch) => {
    const storageRef = getStorage(app, 'gs://covid19-cc1fe.appspot.com/');
    const fileRef = ref(storageRef, path);

    uploadBytes(fileRef, file)
      .then((res) => {
        console.log(res);
        getDownloadURL(fileRef)
          .then((url) => {
            console.log(url);
            dispatch(updateUserPhotoURL(url));
          })
          .catch((error) => {
            console.log('Error en URL');
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const doesUserExists = async (userUid) => {
  const docRef = doc(db, 'users', userUid);
  const docSnap = await getDoc(docRef);
  return { data: docSnap.data(), exists: docSnap.exists() };
};

export const doesUserExistsByPhone = async (phone) => {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('phone', '==', phone));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    users.push({ uid: doc.id, data: doc.data() });
  });
  return users.length > 0 ? users[0] : false;
};

export const doesUserExistsByEmail = async (email) => {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    console.log({ uid: doc.id, data: doc.data() });
    users.push({ uid: doc.id, data: doc.data() });
  });
  return users.length > 0 ? true : false;
};

export const getUserInfo = async (uid) => {
  try {
    const docRef = doc(db, 'users', uid);
    const user = await getDoc(docRef);
    if(!user.exists()){
      throw new Error('No existe el usuario');
    }
    return { uid: uid, data: user.data() };
  } catch (error) {
    console.error('Error al obtener la informacion del usuario');
    return null;
  }
  // const docRef = doc(db, 'users', uid);
  // const user = await getDoc(docRef);
  
  // const auth = getAuth();
  /*const userr = auth.currentUser;
  userr.providerData.forEach((profile) => {
    console.log("Sign-in provider: " + profile.providerId);
    console.log("  Provider-specific UID: " + profile.uid);
    console.log("  Name: " + profile.displayName);
    console.log("  Email: " + profile.email);
    console.log("  Photo URL: " + profile.photoURL);
  });*/
};

export const cleanSession = () => ({
  type: types.cleanSession,
});

export const userInfo = (data) => ({
  type: types.userInfo,
  payload: data,
});
