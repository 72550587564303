import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Highlights } from "../hightlights/highlights";
import { UilAngleRight } from "@iconscout/react-unicons";

const bgImageDesk =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FPanelBgPediatricoCompleto.png?alt=media&token=fdfcc103-ec16-47a1-980b-b4fe64b959b7";
const bgImage =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FPanelBgPediatricoCompletoMobile.png?alt=media&token=5a9402a0-3316-4db8-944a-70687bb56196";

const DiscountsContainer = styled.div`
  padding: 0px 0 120px;
  #text-col {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    p {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 44px;
      line-height: 125%;
      color: #7b8288;
      max-width: 518px;
      margin-bottom: 68px;
    }
    span {
      font-weight: 600;
      color: #00ad90;
      font-style: italic;
      text-decoration: 6px solid #e5dcf9 underline;
    }
    ul {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 30px;
      line-height: 39.01px;
      letter-spacing: 0%;
      color: #7b8288;
      @media only screen and (max-width: 576px) {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 992px) {
      align-items: center;
      p {
        font-size: 32px;
        text-align: center;
        margin-bottom: 38px;
      }
    }
  }
  #img-col {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      margin-bottom: 32px;
    }
    img {
      max-width: 100%;
    }
  }
`;

const TransparentButton = styled.button`
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 29px;
  //line-height: 55px;
  text-align: center;
  color: #fff;
  background: #f9ad62;
  width: 260px;
  height: 55px;
  border: 4px solid #f9ad62;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    color: #fff;
    background: #f9ad62;
  }
`;

const BoxTitles = styled.div`
  background-image: #000;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  padding: 10px 40px 10px 15px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  h1 {
    color: #7b8288;
    font-size: 23px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    @media only screen and (max-width: 576px) {
      font-size: 20px;
    }
    @media (min-width: 992px) {
      font-size: 30px;
    }
    @media (min-width: 1200px) {
      font-size: 24px;
    }

    @media (min-width: 1400px) {
      font-size: 40px;
    }
  }

  @media (min-width: 992px) {
    background-image: url(${(props) =>
      props.bgImageDesk || "default-desktop-image.jpg"});
    width: 90%;
    height: 7rem;
    padding: 10px 10px 10px 15px;
    justify-content: flex-start;
    background-size: contain;
    position: static;
    top: auto;
    left: auto;
  }

  @media (min-width: 1200px) {
    background-image: url(${(props) =>
      props.bgImageDesk || "default-desktop-image.jpg"});
    width: 105%;
    height: 6rem;

    padding: 10px 10px 10px 15px;
    justify-content: flex-start;
    background-size: contain;
  }

  @media (min-width: 1400px) {
    width: 950px;
    height: 160px;
  }
`;

export const App = (props) => {
  return (
    <>
      {/* Highlights slider */}
      <Highlights data-aos="fade-up" />
      {/* Content container */}

      <DiscountsContainer>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            xl={5}
            xxl={6}
            style={{ margin: "0", padding: "0" }}
          >
            <BoxTitles
              className=""
              bgImage={bgImage}
              bgImageDesk={bgImageDesk}
              textColor="#7B8288"
            >
              <h1>
                ¿Eres especialista de la salud y todavía <br />
                no tienes una cuenta Labopat?
              </h1>
            </BoxTitles>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} md={12} id="img-col">
            <img
              data-aos="fade-up"
              alt="vista-previa-sitio-web-labopat"
              src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Fpantallamedicos.png?alt=media&token=38de47f9-09fc-4216-8685-3282cdaecd95"
            />
          </Col>
          <Col xs={12} lg={6} id="text-col">
            <p data-aos="fade-up">
              Regístrate <span>sin costo</span>
            </p>
            <ul>
              <li>Genera solicitudes de estudios</li>
              <li>Obtén alertas de valores críticos</li>
              <li>Guarda el historial de tus pacientes</li>
              <li>
                Descarga resultados nuevos y <br />
                anteriores
              </li>
            </ul>
            <TransparentButton
              onClick={() => {
                window.location.href = "/registro";
              }}
            >
              Crea tu cuenta
              <UilAngleRight size="40" />{" "}
            </TransparentButton>
          </Col>
        </Row>
      </DiscountsContainer>
    </>
  );
};
