const Rosendo = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbranches%2FRosendo.png?alt=media&token=ee990edc-8f95-4773-a46d-bcfcbf4ac7cc';
const Torres = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbranches%2FTorres.png?alt=media&token=954e98ec-92e7-4288-a05a-9bb43c048c0c';
const Zavaleta = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbranches%2FZavaleta.png?alt=media&token=05aaafe4-014b-4000-8cf9-77b0e6bf=';
export const branches = [
    {
        id: 9,
        idLpq: 5,
        name: 'Palmas Plaza Local 20',
        sunday: false,
        address:
            'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
        mapsId: '',
        mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
        coordinates: ['19.0267687', '-98.2311346'],
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f',
        phone: 'Llama al +52 221 231 13 13',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/palmas_plaza',
        link: '/palmas_plaza',
        timetext1: 'Horario de atención:',
        timetext2: 'Laboratorio clínico:',
        time1: 'Lunes a viernes de 8:00 a 20:00 h.',
        time2: 'Sábados de 8:00 a 14:00 h.',
        time3: 'Lunes a viernes de 7:00 a 15:00 h.',
        time4: 'Sábados de 7:00 a 13:00 h.',

    },
    {
        id: 3,
        idLpq: 13,
        name: 'Torres Médicas',
        address:
            'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
        mapURL: 'https://goo.gl/maps/GKRKLQSnVXQPgt8o7',
        mapsId: '',
        coordinates: ['19.02051237624928', '-98.26456209191247'],
        image: Torres,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FTorres%20(1).png?alt=media&token=e085b667-2947-49c2-9262-95a867d8566b',
        //phone: '+52 222 970 07 32',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/torres_medicas',
        link:'/torres_medica',
        timetext1: 'Horario de atención:',
        timetext2: 'Rayos X:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',
        time3: 'Lunes a viernes de 10:00 a 18:00 h.',
        time4: 'Sábados de 7:00 a 13:00 h.',

    },
    {
        id: 7,
        idLpq: 21,
        name: 'La Paz',
        address:
            'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/nfcNL45qi89A5Lhw7',
        mapsId: '',
        coordinates: ['19.0522198', '-98.2362645'],
        image: Rosendo,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FLaPaz.png?alt=media&token=6b1091dc-a248-4751-b02b-022566293d75',
        //phone: '+52 222 972 26 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/lapaz',
        link: '/pabellon_r_m',
        timetext1: 'Horario de atención:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',

    },
    {
        id: 6,
        idLpq: 20,
        name: 'Bosques de Zavaleta',
        address:
            'Plaza Bosques De Zavaleta, Calz Zavaleta 313-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/S7DapoFhy9F8zgNM9',
        mapsId: '',
        coordinates: ['19.066824', '-98.2503983'],
        image: Zavaleta,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FZavaleta.png?alt=media&token=ce00a37a-55e7-4d12-a104-e2ad00496875',
        //phone: '+52 222 976 76 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/bosques_zavaleta',
        link: '/bosques_zavaleta',
        timetext1: 'Horario de atención:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',
    },
    {
        id: 12,
        idLpq: 28,
        name: 'Guadalajara',
        address:
            'Avenida Adolfo López Mateos 959 norte, Italia providencia 44658, Guadalajara Jalisco',
        mapURL: 'https://maps.app.goo.gl/jnN7KoVWGLS6gJwD8',
        mapsId: '',
        coordinates: ['20.6915087', '-103.3777739'],
        image: Zavaleta,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FGuadalajara.png?alt=media&token=07de26a3-f5c5-4e86-89be-5ce54209de7a',
        //phone: '+52 222 976 76 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/guadalajara',
        link: '/guadalajara',
        timetext1: 'Horario de atención:',
        time1: 'Lunes a viernes de 9:00 a 13:00 h.',
        time2: 'Sábados de 9:00 a 13:00 h.',
    },
];


export const branchesP = [
    {
        id: 9,
        idLpq: 5,
        name: 'Palmas Plaza Local 20',
        sunday: false,
        address:
            'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
        mapsId: '',
        mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
        coordinates: ['19.0267687', '-98.2311346'],
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f',
        phone: 'Llama al +52 221 231 13 13',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/palmas_plaza',
        link: '/palmas_plaza',
        timetext1: 'Horario de atención:',
        timetext2: 'Laboratorio clínico:',
        time1: 'Lunes a viernes de 8:00 a 20:00 h.',
        time2: 'Sábados de 8:00 a 14:00 h.',
        time3: 'Lunes a viernes de 7:00 a 15:00 h.',
        time4: 'Sábados de 7:00 a 13:00 h.',

    },
    {
        id: 3,
        idLpq: 13,
        name: 'Torres Médicas',
        address:
            'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
        mapURL: 'https://goo.gl/maps/GKRKLQSnVXQPgt8o7',
        mapsId: '',
        coordinates: ['19.02051237624928', '-98.26456209191247'],
        image: Torres,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FTorres%20(1).png?alt=media&token=e085b667-2947-49c2-9262-95a867d8566b',
        //phone: '+52 222 970 07 32',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/torres_medicas',
        link:'/torres_medica',
        timetext1: 'Horario de atención:',
        timetext2: 'Rayos X:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',
        time3: 'Lunes a viernes de 10:00 a 18:00 h.',
        time4: 'Sábados de 7:00 a 13:00 h.',

    },
    {
        id: 7,
        idLpq: 21,
        name: 'La Paz',
        address:
            'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/nfcNL45qi89A5Lhw7',
        mapsId: '',
        coordinates: ['19.0522198', '-98.2362645'],
        image: Rosendo,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FLaPaz.png?alt=media&token=6b1091dc-a248-4751-b02b-022566293d75',
        //phone: '+52 222 972 26 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/lapaz',
        link: '/pabellon_r_m',
        timetext1: 'Horario de atención:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',

    },
      
    {
        id: 6,
        idLpq: 20,
        name: 'Bosques de Zavaleta',
        address:
            'Plaza Bosques De Zavaleta, Calz Zavaleta 313-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
        mapURL: 'https://goo.gl/maps/S7DapoFhy9F8zgNM9',
        mapsId: '',
        coordinates: ['19.066824', '-98.2503983'],
        image: Zavaleta,
        photo: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FZavaleta.png?alt=media&token=ce00a37a-55e7-4d12-a104-e2ad00496875',
        //phone: '+52 222 976 76 62',
        email: 'pagos.sucursales.labopat@gmail.com',
        path: '/bosques_zavaleta',
        link: '/bosques_zavaleta',
        timetext1: 'Horario de atención:',
        time1: 'Lunes a viernes de 7:00 a 15:00 h.',
        time2: 'Sábados de 7:00 a 13:00 h.',
    }
];