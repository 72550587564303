import styled from "styled-components";
const bgBottom =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FBgBottomBanner.png?alt=media&token=94db3939-9cd0-4b23-8d64-cbbdef0c37b0";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  height: 640px;

  @media (min-width: 500px) {
    max-width: 36rem;
  height: 740px;

  }

  @media (min-width: 768px) {
    max-width: 36rem;
  height: 740px;

  }

  @media (min-width: 992px) {
    height: 460px;
    max-width: 64rem;
    background: #fff;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: 100%;
  }
`;

export const BoxTitle = styled.div`
  background-image: url(${(props) => props.bgImage || 'default-image.jpg'});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  padding: 10px 40px 10px 15px;
  width: 450px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  top: -70px;
  left: 0;
  
  h1 {
    color: ${(props) => props.textColor || '#fff'};
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    padding: 0;

    @media (min-width: 992px) {
      font-size: 20px;
    }
    @media (min-width: 1200px) {
      font-size: 37px;
    }

    @media (min-width: 1400px) {
      font-size: 40px;
    }
  }

  @media (min-width: 992px) {
    background-image: url(${(props) => props.bgImageDesk || 'default-desktop-image.jpg'});
    width: 90%;
    height: 7rem;
    padding: 10px 10px 10px 15px;
    justify-content: flex-start;
    background-size: contain;
    position: static;
    top: auto;
    left: auto;
  }

  @media (min-width: 1200px) {
background-image: url(${(props) => props.bgImageDesk || 'default-desktop-image.jpg'});
    width: 95%;
    height: 8rem;

    padding: 10px 10px 10px 15px;
    justify-content: flex-start;
    background-size: contain;
  }

  @media (min-width: 1400px) {
    width: 845px;
    height: 147px;
  }
`;
export const PanelContent = styled.div`
  position: relative;
  height: 100%;

  @media (min-width: 992px) {
    padding: 15px 0px 0px 0px;
  }
  @media (min-width: 1200px) {
    padding: 15px 0px 0px 0px;
  }
  @media (min-width: 1400px) {
    padding: 20px 0px 0px 0px;
    height: 100%;
  }
`;

export const Paragraph = styled.p`
  color: #7b8288;
  font-size: 14px;
  font-weight: 400;
  line-height: 124%;
  margin: 0;
  text-align: center;
  padding: 0;

  @media (min-width: 992px) {
    font-size: 19px;
  }

  @media (min-width: 1200px) {
  text-align: left;
    font-size: 20px;
  }

  @media (min-width: 1400px) {
    font-size: 23px;
  text-align: left;

  }
`;

export const ContainerPricing = styled.div`
  padding: 15px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 396px) {
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    justify-content: space-between;
  padding: 20px 0px 0px 0px;

  }

  @media (min-width: 1200px) {
    gap: 30px;
    justify-content: flex-start;
  }

  @media (min-width: 1400px) {
    gap: 60px;
  }
`;

export const BtnPagarAhora = styled.button`
display: inline-block;
    text-align: center;
    padding: 1px 10px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 700;
    align-self: flex-start;
    font-size: 14px;
    transition: all 0.2s ease;
    background-color: #f9ad62;
    border: 1px solid transparent;
    color: #fff;
    outline: none;
    &:hover {
      color: #f9ad62;
      border: 1px solid #f9ad62;
      background: #fff;
    }

    @media (min-width: 992px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
      padding: 3px 40px;
    }

    @media (min-width: 1400px) {
    }

    span{
      display: block;
      line-height: 20px;
    }
`

export const BoxPagoLinea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-bottom: auto; */
  gap: 5px;
  button {
    border: 2px solid #1aa687;
    background-color: #fff;
    color: #1aa687;
    padding: 1px 10px;
    border-radius: 10px;
    outline: none;
    text-align: center;
    font-weight: 700;
    align-self: flex-start;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border: 2px solid transparent;
     background: #1aa687;
    color: #fff;


    }
    @media (min-width: 992px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
      padding: 3px 40px;
    }

    @media (min-width: 1400px) {
    }
  }
`;

export const BoxPagoSucursal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;

  a {
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 700;
    align-self: flex-start;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #f9ad62;
    border: 1px solid transparent;
    color: #fff;
    
    &:hover {
      color: #f9ad62;
      border: 1px solid #f9ad62;
      background: #fff;
    }

    @media (min-width: 992px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
    }

    @media (min-width: 1400px) {
    }
  }
`;

export const PriceLinea = styled.span`
  color: #7B8288;
  font-weight: 600;
  font-size: 20px;
  
  margin: 0;
  @media (min-width: 992px) {
    font-size: 26px;
  }

  @media (min-width: 1200px) {
    font-size: 30px;
  }

  @media (min-width: 1400px) {
  }
`;

export const PriceSucursal = styled.p`
  color: #7b8288;
  line-height: 4px;
  font-weight: 600;
  font-size: 20px;
  @media (min-width: 992px) {
    font-size: 26px;
  }

  @media (min-width: 1200px) {
    font-size: 30px;
  }

  @media (min-width: 1400px) {
  }
`;

export const TxtPriceLinea = styled.p`
  color: #1aa687;
  line-height: 4px;
  font-weight: 600;
  font-size: 12px;
  @media (min-width: 992px) {
    font-size: 15px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }

  @media (min-width: 1400px) {
  }
`;

export const TxtPriceSucursal = styled.p`
  color: #7b8288;
  line-height: 4px;
  font-weight: 600;
  font-size: 12px;
  @media (min-width: 992px) {
    font-size: 15px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }

  @media (min-width: 1400px) {
  }
`;

export const BoxBgBottom = styled.div`
  position: absolute;
  width: 80%;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-image: url(${(props) => props.bgImageBottom || bgBottom });
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: 999;

  @media (min-width: 992px) {
    height: auto;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }

  span {
    color: #fff;
    font-size: 10px;
    color: ${(props) => props.txtColor || '#fff'};
    @media (min-width: 992px) {
      font-size: 15px;
    }
  }
`;

export const CustomBox = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 800px;
    height: 100%;
  }

  @media (min-width: 1400px) {
    width: 100%;
    max-width: 800px;
    height: 100%;
  }
`;

export const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageDesktop || 'default-desktop-image.jpg'});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const PriceDetails = styled.span`
  color: #7B8288;
  font-weight: 500;
  font-size: 10px;
  @media (min-width: 992px) {
    font-size: 13px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }

  @media (min-width: 1400px) {
    font-size: 16px;
  }
`

export const BoxPadding = styled.div`
    padding: 40px 45px 40px 45px;
    
  @media (min-width: 576px) {
    
  }

  @media (min-width: 768px) {
 
  }

  @media (min-width: 992px) {

  }

  @media (min-width: 1200px) {
    padding: 20px 40px 0px 40px;
   
  }

  @media (min-width: 1400px) {
    
  }

`