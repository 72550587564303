import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { UilAngleLeftB, UilAngleRightB } from "@iconscout/react-unicons";
import axios from "axios";
import ValoracionIntegral from "./ValoracionIntegral";
import PanelGastricoPediatrico from "./PanelGastricoPediatrico";
import PanelGastricoAdulto from "./PanelGastricoAdulto";


const MySlider = styled(Slider)`
  position: relative;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  .slick-arrow {
    width: 60px;
    height: 60px;
    @media only screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    @media only screen and (max-width: 576px) {
      width: 60px;
      height: 60px;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 0;
    transform: translateY(200%);
    @media only screen and (max-width: 768px) {
      /* bottom: -30px !important; */
      transform: translateY(100%);
    }
    @media only screen and (max-width: 600px) {
      /* bottom: 88px !important; */
      transform: translateY(100%);
    }
    @media only screen and (max-width: 384px) {
      /* bottom: 88px !important; */
      transform: translateY(100%);
    }
    button {
      background: #ccc;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px 1px rgba(100, 100, 100, 0.2);
      ::before {
        display: none;
      }
    }

    .slick-active {
      width: 24px;
      margin-right: 10px;
    }
    .slick-active button {
      border-radius: 81px;
      background: #00ad90;
      width: 24px;
    }
  }
`;
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const Banner = (props) => {
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [shuffledBanners, setShuffledBanners] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  let isMobile = width <= 768;

  useEffect(() => {
    axios
      .get(
        `https://lpqsystem.com/get-studies-detail?estudios=581-1039-1035-1036-1037-624`
      )
      .then((res) => {
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setShuffledBanners(shuffleArray([...banners]));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992); 
    };

  
    window.addEventListener('resize', handleResize);


    handleResize();


    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const banners = [
    /*<PanelInvernalRespiratorio key="banner1" />,
    <PanelPediatricoCompleto key="banner2" />,
    <PanelPediatricoRespiratorio key="banner3" />,*/
    <ValoracionIntegral key="banner1" />,
    <PanelGastricoPediatrico key="banner2" />,
    <PanelGastricoAdulto key="banner2" />
  ];

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilAngleLeftB
      color={isLargeScreen ? "#7B8288" : "#FFFFFF"}
        className={className}
        style={{
          ...style,
          display: "block",
          left: "-5px",
          zIndex: "1",
          marginTop: isMobile ? "-35%" : "0",
        }}
        onClick={onClick}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilAngleRightB
      color={isLargeScreen ? "#7B8288" : "#FFFFFF"}
        className={className}
        style={{
          ...style,
          display: "block",
          right: "-5px",
          marginTop: isMobile ? "-35%" : "0",
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <MySlider
      width="100%"
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={false}
      autoplaySpeed={5000}
      cssEase={"linear"}
      speed={600}
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
    >
      {shuffledBanners}
      {/* <BannerPediatrico />
      <BannerAdulto2 />
      <CreateAccountBanner /> */}
    </MySlider>
  );
};
