import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { UilTimes } from "@iconscout/react-unicons";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Search } from "../navbar/search";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StudySearch } from "../packages/studySearch";
import { handleAddItemToCart } from "../../actions/shoppingCart";
import {
  UisArrowCircleLeft,
  UisArrowCircleRight,
} from "@iconscout/react-unicons-solid";
import { PackageCard } from "../packages/packageCard";
import Slider from "react-slick";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { studyInCart } from "../../helpers/cart";
import handleAlert from "../../helpers/handleAlert";

const loadingGIF =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2Floading.gif?alt=media&token=629f78d3-10a2-44f9-9143-668f5bc19ace";

const SearcherDiv = styled.div`
  width: 100%;
  background: #fff;
  //padding-bottom: 20px;
  //max-height: 92vh;
  overflow: scroll;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const Sub = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #45494a;
`;

const SearchResults = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
`;

const ListDiv = styled(Row)`
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 3px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  max-width: 396px;
  height: 48px;
  background: none;
  color: ${(props) => props.mainColor};
  border: 2px solid ${(props) => props.mainColor};
  border-radius: 24px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 20px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const StyledSlider = styled(Slider)`
  margin: 0 auto 50px;
  width: 90%;
  top: 30px;
  left: 45%;
  transform: translate(-50%, 0);
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #0a7a71;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #fff;
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -45px;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const OrangeButton = styled.button`
  width: 30%;
  height: 30px;
  background: linear-gradient(
    79.06deg,
    #5097e3 -41.6%,
    #0cac8c 26.14%,
    #30df99 84.04%
  );
  border-radius: 10px !important;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 14px;
  margin-left: 35%;
  color: #fff;
  transition: all 0.4s ease;
  :disabled {
    background: #c4c4c4;
    color: #fff;
    border: 2px solid #c4c4c4;
  }
`;

export const Sercher = (props) => {
  const [searching, setSearching] = useState("");
  const [searchItems, setSearchItems] = useState();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const mainColor = props.mainColor ? props.mainColor : "#00AC90";
  const [packages, setPackages] = useState(props?.packages ?? []);

  const wrapperRef = useRef(null);

  let isMobile = width <= 768;

  const handleSearch = () => {
    if (searching !== "") {
      const filteredPackages = props?.packages?.filter((e) => {
        if (e?.paquete?.name?.includes(searching)) {
          return e;
        }
        return null;
      });
      setPackages(filteredPackages);
    } else {
      setPackages(props?.packages);
    }
    setLoading(true);
    try {
      if (searching === "") {
        setSearchItems([]);
        setLoading(false);
      } else {
        axios
          .get(
            `https://lpqsystem.com/searching-products-test?search=${searching}${
              idClienteLabopat ? `&idCliente=${idClienteLabopat}` : ""
            }`
          )
          .then((res) => {
            const updatedProducts = res?.data?.productos?.map((item) => ({
              ...item,
              title: item?.name,
              price: item?.precio_promo,
              oldPrice: item?.precio_normal,
            }));
            setSearchItems(updatedProducts);
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddItem = (item) => {
    const exists = studyInCart(item, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(item));
    }
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UisArrowCircleLeft
        className={className}
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "40px",
        }}
        color={mainColor ? "#4F90BA" : "#4D99E5"}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UisArrowCircleRight
        className={className}
        style={{
          ...style,
          display: "block",
          width: "40px",
          height: "40px",
        }}
        color={mainColor ? "#4F90BA" : "#4D99E5"}
        onClick={onClick}
      />
    );
  }

  return (
    <SearcherDiv
      ref={wrapperRef}
      id="searcher-div"
      spaceTop={props.spaceTop ? "65px" : "0px"}
    >
      <Container style={{ position: "relative" }}>
        {searching === "" && (
          <>
            {isMobile ? (
              <></>
            ) : (
              <>
                <Title>¿Qué estudio buscas?</Title>
                <Sub>Escribe el estudio que necesitas</Sub>
              </>
            )}
          </>
        )}
        <div
          style={{ maxWidth: "548px", marginLeft: "auto", marginRight: "auto" }}
        >
          <Search
            setSearching={setSearching}
            hideSearch={() => null}
            showSearch={() => null}
            mainColor={mainColor}
          />
          {isMobile && location.pathname !== "/checkoutp" && (
            <OrangeButton onClick={handleSearch}>Buscar</OrangeButton>
          )}

          {!isMobile && (
            <OrangeButton onClick={handleSearch}>Buscar</OrangeButton>
          )}
        </div>
        {searching !== "" ? (
          loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "120px",
                width: "100%",
              }}
            >
              <img src={loadingGIF} alt="loading" width={75} />
            </div>
          ) : (
            <div style={{ position: "relative" }} id="search-results">
              <SearchResults>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "5px",
                    marginTop: "10px",
                  }}
                >
                  Sugerencias de la busqueda:
                </p>
                {searchItems?.length !== 0 ? (
                  <ListDiv>
                    {searchItems?.map((item, index) => (
                      <StudySearch
                        key={index}
                        data={item}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={4}
                        addItem={handleAddItem}
                        // studies={items}
                        idClienteLabopat={idClienteLabopat}
                        mainColor={mainColor}
                      />
                    ))}
                  </ListDiv>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "25px",
                      marginTop: "25px",
                      fontSize: "20px",
                    }}
                  >
                    No hay resultados
                  </p>
                )}
              </SearchResults>
            </div>
          )
        ) : (
          <br />
        )}

        {/* <Row>
          <Col xs={{span: 8, offset: 2}}>
            <Row>
              <Col xs={5}><hr/></Col>
              <Col xs={2}><p style={{textAlign: 'center'}}>ó</p></Col>
              <Col xs={5}><hr/></Col>
            </Row>
          </Col>
        </Row>
        <Sub>Elige de los más frecuentes</Sub> */}

        {searching !== "" && searchItems?.length !== 0 && !loading && (
          <div className="d-flex justify-content-center">
            <WhiteButton
              mainColor={mainColor}
              onClick={() => {
                history.push(`/buscador?search=${searching}`);
                props.hideSearch();
              }}
            >
              Mostrar los {searchItems?.length} resultados
            </WhiteButton>
          </div>
        )}
        {packages?.length > 0 &&
          // (searching === "" || !searching) &&
          isDoctor && (
            <>
              <Row>
                <Col className="text-center">
                  <Title>Mis paquetes</Title>
                </Col>
              </Row>
              <StyledSlider
                style={{ justifyContent: "center" }}
                xs={12}
                dots={true}
                infinite={true}
                slidesToShow={width > 767 && packages?.length >= 1 ? 1 : 1}
                slidesToScroll={width > 767 && packages?.length >= 1 ? 1 : 1}
                autoplay={false}
                autoplaySpeed={4000}
                cssEase={"linear"}
                speed={600}
                prevArrow={<PrevArrow />}
                nextArrow={<NextArrow />}
              >
                {packages?.map((pack, index) => (
                  <PackageCard data={pack} onDelete={props.handleDelete} />
                ))}
              </StyledSlider>
            </>
          )}
        {/*props.packages?.length > 0 && (
          <>
              <ListDiv>
                {props.packages?.map((pack, index) => (
                  <Col xs={12} key={index} className="mb-4">
                    <PackageCard data={pack} onDelete={props.handleDelete} />
                  </Col>
                ))}
              </ListDiv>
          </>
                )*/}
      </Container>
    </SearcherDiv>
  );
};
