import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StudiesSlidernew } from "./studiesSlidernew";
import { Search } from "../navbar/search";
import { useSelector } from "react-redux";
import { DesktopMenu } from "../../components/menu/desktop";
import { secciones } from "../../helpers/navbar";
import { UilArrowCircleLeft } from "@iconscout/react-unicons";
import { UilArrowCircleRight } from "@iconscout/react-unicons";
import Slider from "react-slick";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const MainContainer = styled(Container)`
  padding-top: 76px;
  @media only screen and (max-width: 768px) {
    padding-top: 45px;
  }
`;

const Title = styled.h3`
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 38px;
  line-height: 125%;
  text-align: center;
  color: #474a4b;
  //margin-top: 28px;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    margin-top: 15px;
  }
`;

const Subtitle = styled.h3`
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  color: #474a4b;
  //margin-top: 28px;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    margin-top: 15px;
  }
`;

const ItemCol = styled(Col)`
  padding: 5px 10px 5px 10px;
  :hover {
    background: #fff;
    border-radius: 10px;
  }
  .row {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e0ecec;
    padding: 4px 0px;
  }
  a {
    width: 100%;
    display: flex;
    height: 95px;
    transition: all 0.3s ease;
    :hover {
      background: #efefef40;
    }
  }
  img {
    width: 120px;
  }
`;

const StyledSlider = styled(Slider)`
  margin: 0 auto 50px;
  width: 90%;
  top: 30px;
  left: 45%;
  transform: translate(-50%, 0);
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #0a7a71;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #0cac8c;
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -45px;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

export const Highlights = (props) => {
  // Studies state
  const [studies, setStudies] = useState([]);
  const [filteredStudies, setFilteredStudies] = useState([]);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  // Searching state
  const [searching, setSearching] = useState("");

  // Get highlight studies
  const getStudies = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-featured-studiesALL/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let data = await request;
      setStudies(data.data);
      setFilteredStudies(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Get studies first time
  useEffect(() => {
    getStudies();
  }, []);

  // Filter studies every time searching changes
  useEffect(() => {
    const finalStudies = studies?.filter((study) =>
      study.title?.toLowerCase()?.includes(searching.toLowerCase())
    );
    setFilteredStudies(
      finalStudies?.length <= 0 || searching === "" ? studies : finalStudies
    );
  }, [searching]);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleLeft
        className={className}
        style={{ ...style, display: "block", color: "#00AD90" }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleRight
        className={className}
        style={{ ...style, display: "block", color: "#00AD90" }}
        onClick={onClick}
      />
    );
  }

  const getGroupedSections = (secciones, isMobile) => {
    if (isMobile) {
      // En mobile, dividir en bloques de 2
      const grouped = [];
      for (let i = 0; i < secciones.length; i += 2) {
        grouped.push(secciones.slice(i, i + 2));
      }
      return grouped;
    } else {
      // En desktop, dividir en 6 y 3
      return [secciones.slice(0, 6), secciones.slice(6, 9)];
    }
  };

  const [groupedSections, setGroupedSections] = useState(
    getGroupedSections(secciones, isMobile)
  );

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 767;
      setIsMobile(mobile);
      setGroupedSections(getGroupedSections(secciones, mobile));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [secciones]);

  return (
    <MainContainer>
      <Row>
        <Col md={12} className="text-center">
          {/* Searcher input div*/}
          <div
            style={{
              maxWidth: "548px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Search
              setSearching={setSearching}
              hideSearch={() => null}
              showSearch={() => null}
              mainColor={"#00AD90"}
              notFocus={"nope"}
            />
          </div>
        </Col>
        {/* Slider column*/}
      </Row>
      <br />

      <StyledSlider
        style={{ justifyContent: "center" }}
        xs={12}
        dots={true}
        infinite={true}
        slidesToShow={1} // Un slide a la vez
        slidesToScroll={1} // Se mueve de 6 en 6 (primer slide) y luego 3 (segundo slide)
        autoplay={false}
        autoplaySpeed={4000}
        cssEase={"linear"}
        speed={600}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {groupedSections.map((group, groupIndex) => (
          <div key={groupIndex}>
            <Row>
              {group.map((item, index) => {
                // Calcular índice absoluto en el array original
                const absoluteIndex = groupIndex * (isMobile ? 2 : 6) + index;

                // Lógica corregida para asignar el background
                let backgroundColor = "";
                if ([0, 5, 6].includes(absoluteIndex)) {
                  backgroundColor = "#E5DCF980";
                } else if ([1, 3, 7].includes(absoluteIndex)) {
                  backgroundColor = "rgba(77, 153, 229, 0.35)";
                } else if ([2, 4, 8].includes(absoluteIndex)) {
                  backgroundColor = "#00C0A333";
                }

                return (
                  <ItemCol md={isMobile ? 6 : 4} key={index}>
                    <a
                      href={item.link}
                      style={{
                        borderRadius: "10px",
                        background: backgroundColor,
                        height: "200px",
                      }}
                    >
                      <Row
                        onClick={() => (window.location.href = item.link)}
                        className="position-relative rounded p-3 align-items-center"
                      >
                        <Col md={12} className="position-relative text-center">
                          {/* Imagen de fondo */}
                          <img
                            src={item.icon}
                            className="img-fluid position-absolute top-50 translate-middle opacity-25"
                            alt="category-icon"
                            style={{
                              left: "70%",
                              transform: "translateY(-50%)",
                            }}
                          />
                          {/* Texto sobre la imagen */}
                          <Subtitle className="position-relative text-dark fw-bold">
                            {item.name}
                          </Subtitle>
                        </Col>
                      </Row>
                    </a>
                  </ItemCol>
                );
              })}
            </Row>
          </div>
        ))}
      </StyledSlider>

      <br />
      <br />
      <Col md={12}>
        <br />
        <hr style={{ border: "1px solid", color: "#E4DDF8", opacity: "4" }} />
        <br />
        <Title>Destacados</Title>
        <StudiesSlidernew studies={filteredStudies} />
      </Col>
    </MainContainer>
  );
};
