import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StepFour } from "./stepFour";
import { StepThree } from "./stepThree";
import { StepTwo } from "./stepTwo";
import { ThanksScreen } from "./thanksScreen";
import { Loader } from "../general/spinner";
import {
  UilArrowLeft,
  UilClipboardNotes,
  UilClipboardAlt,
  UilCalendarAlt,
  UilUser,
  UilInvoice,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FirstTimeDoctor } from "../checkout/firstTimeDoctor";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { updateInfoUser } from "../../actions/user";

const Background = styled.div`
  background: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  background-size: 100vw 100vh;
  background-attachment: fixed;
  @media only screen and (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  > .container {
    background: #fff;
    max-width: 1120px !important;
    @media only screen and (min-width: 768px) {
      border-radius: 16px;
      padding-top: 24px;
      width: 60%;
    }
  }
`;

const BackBtn = styled(Button)`
  margin-top: 0px;
  margin-bottom: 10px;
  background-color: white;
  border: none;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 0px;
  }
  h2 {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
    border: 4px solid #fff;
    margin-left: auto;
    margin-right: auto;
  }
  .unavailable {
    .circle {
      background: #fff;
    }
    h2 {
      color: #afafaf;
    }
  }
  .current .circle {
    background: #00ac90;
  }
`;

const ProgressBar = styled.div`
  margin-bottom: -11px;
  width: 66.66%;
  height: 3px;
`;

const ContinueButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) =>
    props.isDoctor
      ? "#4D99E5"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border: 2px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
  border-radius: 10px !important;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.4s ease;
  :focus {
    background: ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
  }
  :hover {
    border: 2px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
    transition: all 0.4s ease;
    background: #fff;
    color: ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
  }
  :disabled {
    border: none;
    background: #e2e2e2;
    color: #a6a6a6;
  }
`;

const EmptyCart = styled.div`
  height: 50vh;
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #45494a;
    margin-bottom: 40px;
  }
`;

const WhiteButton = styled.button`
  width: 214px;
  margin: 6px auto;
  height: 48px;
  background: none;
  color: #00ad90;
  border: 2px solid #00ad90;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #00ad90;
  }
`;

export const Checkout = (props) => {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);
  const logged = useSelector((state) => state.auth.uid);
  const history = useHistory();
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const shoppingCart = useSelector((state) => state.shoppingCart);
  const [cartItems, setCartItems] = useState([]);
  const [newNet, setNewNet] = useState(false);
  const user = useSelector((state) => state.user?.data?.data);
  const [loading, setLoading] = useState(false);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const firstTimeDoctor = useSelector(
    (state) => state.user?.data?.data?.profile?.first_time
  );
  //console.log(useSelector((state) => state.shoppingCart));
  const [otherPersonCheck, setOtherPersonCheck] = useState(true);
  const [otherPerson, setOtherPerson] = useState({});
  const [stepsTitle, setStepsTitle] = useState([
    // 'Verifica tu lista de estudios',
    "Agenda la cita para el estudio",
    "Confirma tu expediente digital",
    "Paga tu estudio",
  ]);

  // Check if is order to patient
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var notForMe = urlParams.get("notForMe") === "1";

  useEffect(() => {
    console.log(
      "🚀 ~ file: Checkout.jsx:231 ~ useEffect ~ cartItems?.length !== shoppingCart?.items?.length:",
      cartItems?.length !== shoppingCart?.items?.length
    );
    if (
      cartItems?.length !== shoppingCart?.items?.length &&
      !cartItems?.length >= 1
    ) {
      try {
        getData();
      } catch (err) {
        console.log(err);
      }
    }
  }, [cartItems, shoppingCart]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  async function getData() {
    setLoading(true);
    const cartId = localStorage.getItem("cartId");
    const endpoint = "https://labopat-5792e.appspot.com/get-cart/?id=" + cartId;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    let request = await axios.get(endpoint, options);
    console.log(request);
    setCartItems(request.data.cart.items);
    const pricesArray = request.data.cart.items.map((item) => item.price);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const oldPricesArray = request.data.cart.items.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setSubTotalWeb(final);
    setSubTotalSuc(oldFinal);
    setLoading(false);
  }

  // step one
  const [pay, setPay] = useState("web");
  const [subTotalWeb, setSubTotalWeb] = useState(0);
  const [subTotalSuc, setSubTotalSuc] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [couponID, setCouponID] = useState(0);
  const [couponClient, setCouponClient] = useState(83);

  // step two
  const [date, setDate] = useState();
  const [place, setPlace] = useState();
  const [time, setTime] = useState();
  const [free, setFree] = useState(false);

  // step three
  const [personalData, setPersonalData] = useState({
    name: "",
    secondName: "",
    lastName: "",
    phone: "",
    email: "",
    birthday: "",
  });

  const [confirm, setConfirm] = useState({
    folio: "",
    id: 0,
    ok: true,
    type: 0,
  });

  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Checkout");

  var isOrder = urlParams.get("doctor");
  const doctorIdlpq = urlParams.get("docId");
  var patientOrderId = urlParams.get("patient");
  var isSucursal = urlParams.get("sucursal");
  var orderID = urlParams.get("order");
  useEffect(() => {
    // setStep(2);
    const pricesArray = cartItems.map((item) => item.price);
    const final = pricesArray.reduce((a, b) => a + b, 0);
    const oldPricesArray = cartItems.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce((a, b) => a + b, 0);
    setSubTotalWeb(final);
    setSubTotalSuc(oldFinal);
    if (isSucursal === "1") {
      setPay("sucursal");
    }
    if (isOrder === "1") {
      setPay("orderToPatient");
      if (isDoctor && firstTimeDoctor) {
        setTotalSteps(3);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          "",
          "Carpeta médica",
          "Expediente",
        ]);
      } else if (isDoctor && !firstTimeDoctor) {
        setTotalSteps(1);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          // '',
          "Expediente",
        ]);
      }
    }
    // Setting total steps and icons
    if (!isOrder && !isSucursal) {
      setTotalSteps(3);
      setStepsTitle([
        // 'Verifica tu lista de estudios',
        "Cita",
        "Expediente",
        "Pago",
      ]);
    } else if (isSucursal) {
      setTotalSteps(2);
      setStepsTitle([
        // 'Verifica tu lista de estudios',
        "Cita",
        "Expediente",
      ]);
    } else if (isOrder) {
      if (isDoctor && firstTimeDoctor) {
        setTotalSteps(3);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          "",
          "Carpeta médica",
          "Expediente",
        ]);
      } else if (isDoctor && !firstTimeDoctor) {
        setTotalSteps(1);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          // '',
          "Expediente",
        ]);
      }
    }
  }, []);

  const handleStepTwo = (values) => {
    setDate(values.date);
    setPlace(values.place);
    setTime(values.time);
  };

  const altaClientes = async (
    persona,
    check,
    fromRed,
    newNet,
    tipo,
    idPago,
    idPago2,
    cardInfo
  ) => {
    if (cartItems.length > 0) {
      await setLoading(true);

      console.log("************* ALTA CLIENTES ****************");
      console.log("PERSONA:", persona);
      console.log("PERSONAL DATA:", personalData);
      console.log("********************************************");

      var formData = new FormData();
      //si tiene id cliente laopat
      formData.append(
        "idClienteLabopat",
        idClienteLabopat ? idClienteLabopat : 0
      );
      //personal data
      formData.append("uid", logged);
      const eldx = window.document?.getElementById("elDx")?.value;
      formData.append("dx", persona.dx ? persona.dx : eldx);

      // Info queda vacia cuando solo se el ID
      formData.append(
        "name",
        patientOrderId && personalData.name !== null
          ? personalData.name
          : persona.name
      );
      // console.log("🚀 ~ file: Checkout.jsx:495 ~ Checkout ~ personalData", personalData)
      // console.log("🚀 ~ file: Checkout.jsx:495 ~ Checkout ~ personalData", personalData)
      formData.append(
        "app",
        patientOrderId && personalData.fathersLastname !== null
          ? personalData.fathersLastname
          : persona.fathersLastname
      );
      formData.append(
        "apm",
        patientOrderId && personalData.mothersLastname !== null
          ? personalData.mothersLastname
          : persona.mothersLastname
      );
      let theMail = patientOrderId
        ? personalData.email
          ? personalData.email
          : user.email
        : persona.email
        ? persona.email
        : user.email;
      console.log(user.email);
      console.log(theMail);
      //await setLoading(false);
      //return
      formData.append("email", theMail);
      formData.append(
        "sexo",
        patientOrderId && personalData.gender !== "undefined"
          ? personalData.gender
          : window.document?.querySelector(
              "input[type='radio'][name=gender]:checked"
            )?.value
          ? window.document?.querySelector(
              "input[type='radio'][name=gender]:checked"
            )?.value
          : persona.gender
      );
      formData.append(
        "phone",
        patientOrderId === 0 && personalData.phone !== null
          ? personalData.phone
          : persona.phone
      );

      formData.append("tipo", tipo);
      formData.append("idPago", idPago ? idPago : "");
      formData.append("idPago2", idPago2 ? idPago2 : "");
      formData.append("cardType", cardInfo?.cardType ? cardInfo.cardType : "");
      formData.append("cardName", cardInfo?.cardName ? cardInfo.cardName : "");
      formData.append(
        "cardAddress",
        cardInfo?.cardAddress ? cardInfo.cardAddress : ""
      );
      formData.append(
        "cardPostalCode",
        cardInfo?.cardPostalCode ? cardInfo.cardPostalCode : ""
      );
      formData.append(
        "fnac",
        patientOrderId && personalData.birthday !== "1900-12-12"
          ? personalData.birthday
          : persona.year
          ? persona.year + "-" + persona.month + "-" + persona.day
          : pay === "orderToPatient"
          ? "1900-12-12"
          : personalData.birthday
      );
      formData.append("termsAndConditions", persona.termsAndConditions);
      //red
      formData.append("newNetwork", newNet ? 1 : 0);
      formData.append("idNetwork", newNet ? 0 : fromRed?.id);
      formData.append("otherPerson", persona.otherPerson);
      //studies
      console.log("Payment type: ", pay);
      console.log(subTotalWeb);
      console.log(subTotalSuc);
      cartItems.forEach((element) => {
        console.log(element);
        var ids = [];
        console.log(element.estudios);
        element.estudios.forEach((elem) => {
          formData.append("products[]", element.id);
          formData.append("categories[]", element.categoria);
          formData.append("productTitle[]", element.title);
          if (pay === "web" || pay === "orderToPatient") {
            formData.append("studiesPrice[]", element.price);
            formData.append(
              "studiesPricePromedio[]",
              element.price / element.estudios.length
            );
            if (!ids.includes(element.id)) {
              ids.push(element.id);
            }
          } else {
            formData.append("studiesPrice[]", element.oldPrice);
            formData.append(
              "studiesPricePromedio[]",
              element.oldPrice / element.estudios.length
            );
            if (!ids.includes(element.id)) {
              ids.push(element.id);
            }
          }
          console.log(elem.id);
          formData.append("studies[]", elem.id);
        });
        //
      });
      //place
      formData.append("place", place?.id ? place?.id : 1);
      //date and hour
      if (pay === "orderToPatient") {
        formData.append("date", "2022-11-07"); // TODO: Change for today??
        formData.append("time", "08:10");
      } else {
        //var tmpTime = time.split(' - ')
        formData.append("time", time);
        formData.append(
          "date",
          date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
        );
      }

      //payment
      var pagado =
        pay === "web" || pay === "orderToPatient" ? subTotalWeb : subTotalSuc;
      if (isNaN(pagado)) {
        let tmp = checkPrices();
        pagado = pay === "web" || pay === "orderToPatient" ? tmp[0] : tmp[0];
      }
      console.log("a guardar : ", pagado);

      formData.append("subTotal", pagado);

      formData.append("discount", JSON.stringify(discount));
      formData.append("cuponCode", coupon);
      formData.append("couponClient", couponClient);
      formData.append("cuponID", couponID);
      formData.append("cupon", couponApplied ? 1 : 0);

      // Save idPacienteLabopat
      const idPacienteLabopat = patientOrderId
        ? patientOrderId
        : otherPersonCheck
        ? persona.idPacienteLabopat
        : 0;

      if (pay === "web") {
        formData.append("paymentType", 1);
        formData.append("refOrdenMedico", doctorIdlpq ? doctorIdlpq : 12);
        formData.append("idPacienteLabopat", idPacienteLabopat);
      } else if (pay === "sucursal") {
        formData.append("paymentType", 2);
        formData.append("refOrdenM edico", doctorIdlpq ? doctorIdlpq : 12);
        formData.append("idPacienteLabopat", idPacienteLabopat);
      } else {
        //orden de paciente
        formData.append(
          "refOrdenMedico",
          window.document.getElementById("refMedico").value
        );
        formData.append("paymentType", 3);
        formData.append("idPacienteLabopat", persona.idPacienteLabopat);
      }

      // Save address info for home consultations
      const address = window.document?.getElementById("theAddress")?.value;
      formData.append(
        "address",
        isOrder ? "" : persona.address ? persona.address : address
      );
      // Only if the user is doing the request for himself it will update the address info
      if (idPacienteLabopat === user?.idPacienteLabopat) {
        dispatch(
          updateInfoUser({
            address: persona.address ? persona.address : address,
          })
        );
      }
      // Save fields for order
      // Additional studies field
      const additionalStudies = window.document?.getElementById(
        "theAdditionalStudies"
      )?.value;
      formData.append("additionalStudies", additionalStudies ?? "");
      // Observations field
      const observations =
        window.document?.getElementById("theObservations")?.value;
      formData.append("observations", observations ?? "");

      // Other person with previous sol
      if (persona.id) {
        formData.append("idSol", persona.id);
      }

      formData.append("orderType", pay);
      if (pay === "web") {
        formData.append("fase", 0);
      } else {
        formData.append("fase", 2);
      }

      // Medico tratante
      formData.append(
        "medicoTratante",
        window.document?.querySelector(
          "input[type='radio'][name=medicoTratante]:checked"
        )?.value
          ? window.document?.querySelector(
              "input[type='radio'][name=medicoTratante]:checked"
            )?.value
          : persona.medicoTratante
      );
      if (persona.medicoTratante === "1") {
        if (persona.doctorId) {
          formData.append("doctorId", persona.doctorId);
        } else {
          formData.append("doctorId", 0);
          formData.append("nombreDoctor", persona.doctorName);
          formData.append(
            "emailDoctor",
            persona.doctorEmail ? persona.doctorEmail : ""
          );
          formData.append(
            "compartirResultados",
            persona.shareResults ? "1" : "0"
          );
        }
      }

      //Billing info
      let factura = parseInt(
        window.document?.querySelector(
          "input[type='radio'][name=necesitoFactura]:checked"
        )?.value
      )
        ? parseInt(
            window.document?.querySelector(
              "input[type='radio'][name=necesitoFactura]:checked"
            )?.value
          )
        : parseInt(persona.necesitoFactura);

      formData.append("necesitoFactura", factura);
      console.log("factura ? ", factura);
      if (parseInt(factura) === 1) {
        console.log("factura: ", persona);
        formData.append("rfc", persona.rfc);
        formData.append("razon", persona.razon);
        formData.append("emailRFC", persona.emailRFC);
        formData.append("regimen", persona.regimen);
        //formData.append("calle1", persona.calle1);
        //formData.append("next1", persona.next1);
        //formData.append("nint1", persona.nint1);
        formData.append("cp1", persona.cp1);
        //formData.append("colonia1", persona.colonia1);
        //formData.append("municipio1", persona.municipio1);
        //formData.append("estado1", persona.estado1);
        //formData.append("pais1", persona.pais1);
        formData.append("usoFactura", persona.usoFactura);
        formData.append("comentarioFactura", persona.comentarioFactura);
      }

      formData.append("order", orderID ?? "");

      // Uncomment next 3 lines to see final formData info
      // for (var pair of formData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }

      // Handle URL base on  type of order
      let response = await axios.post(
        orderID
          ? "https://lpqsystem.com/updateOrden"
          : persona.id
          ? "https://lpqsystem.com/updateEcomm"
          : "https://lpqsystem.com/alta-ecom-test",
        formData
      );

      console.log(response.data);
      if (response.data.ok) {
        await setConfirm(response);
        await setStep(step + 1);
        if (couponID !== 0) {
          //update  cupon contador de uso en lpqsystem
          await axios.get(
            "https://lpqsystem.com/update-total-use?id=" + couponID
          );
          await setLoading(false);
        } else {
          await setLoading(false);
        }
      } else {
        window.alert("*" + response.data.error);
      }
    } else {
      window.alert("No hay items en el carrito");
    }
  };

  const handleStepThree = async (values) => {
    setPersonalData(values.personalData);
    setOtherPersonCheck(values.otherPersonCheck);
    setNewNet(values.newNetwork);
    setOtherPerson(values.otherPerson);
    console.log("TRYYYYY", values);
    if (pay === "web" && free) {
      console.log("-----");
      console.log(personalData);
      console.log("-----");
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        3,
        ""
      );
      gaEventTracker("finalizar-pago-online-free");
    } else if (pay === "sucursal") {
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        1,
        ""
      );
      gaEventTracker("finalizar-pago-sucursal");
    } else if (pay === "orderToPatient") {
      console.log("es orden");
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        2,
        ""
      );
      gaEventTracker("finalizar-orden-medica");
    } else {
      setStep(step + 1);
    }
  };

  const handleStepFour = (sol, intent, cardInfo) => {
    // To send the charge id, use sol.data.id
    // If user paid with MSI, send the intent id, use intent.id

    // The charge id generated starts with 'ch_' and the intent id starts with 'pi_'
    if (sol?.data?.id || intent?.id) {
      // console.log(sol?.data?.id, intent?.id);
      // console.log(cardInfo);
      altaClientes(
        personalData,
        otherPersonCheck,
        otherPerson,
        newNet,
        3,
        sol?.data?.id,
        intent?.id,
        cardInfo
      );
      // gaEventTracker('finalizar-pago-online');
      // setStep(step + 1);
    } else {
      window.alert("No se pudo procesar el pago intenta nuevamente");
    }

    //setConfirm(sol);
  };

  const handleFirstTimeDoctor = (values) => {
    setPersonalData(values.personalData);
    //console.log(personalData);
    setStep(step + 1);
  };

  const continueClick = () => {
    setStep(step + 1);
    window.scroll({
      top: -10,
    });
  };

  useEffect(() => {
    if (logged && !notForMe && !isOrder) {
      setPersonalData({
        name: user?.name,
        fathersLastname: user?.fathersLastname,
        mothersLastname: user?.mothersLastname,
        phone: user?.phone,
        email: user?.email,
        birthday: user?.birthday,
        gender: user?.gender,
      });
    }
  }, []);

  // If its order gets the order info
  const getInfo = async (id) => {
    console.log("get info");
    setLoading(true);
    try {
      const appointment = await axios.get(
        `https://labopat-5792e.appspot.com/get-order-detail?id=${id}`
      );
      if (appointment?.data.solicitud) {
        console.log("aqui 1");
        if ((logged && notForMe) || !logged) {
          console.log("aqui 2");
          console.log(appointment.data.solicitud.nombre);

          await setPersonalData({
            name: appointment.data.solicitud.nombre,
            fathersLastname: appointment.data.solicitud.app,
            mothersLastname: appointment.data.solicitud.apm,
            phone: appointment.data.solicitud.telContacto,
            email: appointment.data.solicitud.emailContacto,
            birthday: appointment.data.solicitud.fnacimiento,
            gender: appointment.data.solicitud.genero,
            doctorId: appointment.data.doctorId,
            patientId: appointment.data.drive.idCatPaciente,
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderID) {
      getInfo(orderID);
    }
  }, []);

  const applyCupon = (cupon, items, cFree, cID, cliente) => {
    setCartItems(items);
    setCouponClient(cliente);
    setCouponApplied(true);
    setCoupon(cupon);
    setCouponID(cID ? cID : -1);
    const pricesArray = items.map((item) => item.precio_normal);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    console.log(final);
    setDiscount(subTotalSuc - final);
    setSubTotalWeb(final);
    setSubTotalSuc(final);
    if (pay === "web" && (cFree === 1 || final === 0)) {
      setFree(true);
      setTotalSteps(2);
      setStepsTitle(["Cita", "Expediente"]);
    }
  };

  useEffect(() => {
    if (isNaN(subTotalWeb) || isNaN(subTotalSuc)) {
      let prices = checkPrices();
      setSubTotalWeb(prices[0]);
      setSubTotalSuc(prices[1]);
    }
  }, [subTotalWeb, subTotalSuc]);

  const checkPrices = () => {
    const pricesArray = cartItems.map((item) => item.price);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const oldPricesArray = cartItems.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    return [final, oldFinal];
  };
  return (
    <>
      {loading ? <Loader show={loading} /> : null}
      {cartItems.length !== 0 ? (
        <>
          <Background isDoctor={isDoctor}>
            <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
              {(step <= totalSteps || (isOrder && step <= 2)) && (
                <BackBtn
                  variant="light"
                  style={{
                    display:
                      (isDoctor &&
                        firstTimeDoctor &&
                        step === 5 &&
                        pay === "orderToPatient") ||
                      (isDoctor &&
                        !firstTimeDoctor &&
                        step === 4 &&
                        pay === "orderToPatient") ||
                      (pay === "web" && step === 5) ||
                      (pay === "sucursal" && step === 4)
                        ? "none"
                        : null,
                  }}
                  onClick={() => {
                    step > 1
                      ? step === 2 && pay === "orderToPatient"
                        ? window.history.back()
                        : setStep(step - 1)
                      : window.history.back();
                  }}
                >
                  {" "}
                  <UilArrowLeft color="#00AD90" size="26" />
                  Regresar
                </BackBtn>
              )}
              {step <= totalSteps && (
                <Progress>
                  <ProgressBar
                    style={{
                      background: `linear-gradient(to right, #00AC90 ${
                        (step - 1) * 50
                      }%, #E9E9E9 0%)`,
                    }}
                  />
                  <Row style={{ width: "100%" }}>
                    {stepsTitle.map((pstep, index) => (
                      <Col
                        xs={12 / totalSteps}
                        className={`${step < index + 1 && "unavailable"} ${
                          step === index + 1 && "current"
                        }`}
                      >
                        <div className="circle"></div>
                        <h2>
                          {index + 1}. {pstep}
                        </h2>
                      </Col>
                    ))}
                  </Row>
                </Progress>
              )}
              {step === 1 ? (
                pay !== "orderToPatient" ? (
                  <StepTwo
                    onStepTwo={handleStepTwo}
                    subTotalWeb={subTotalWeb}
                    subTotalSuc={subTotalSuc}
                    applyCupon={applyCupon}
                    discount={discount}
                    items={cartItems}
                    date={date}
                    place={place}
                    time={time}
                  />
                ) : (
                  setStep(2)
                )
              ) : step === 2 ? (
                firstTimeDoctor && pay === "orderToPatient" ? (
                  <FirstTimeDoctor
                    personalData={personalData}
                    onFirstTimeDoctor={handleFirstTimeDoctor}
                  />
                ) : (
                  <StepThree
                    onStepThree={handleStepThree}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    user={user}
                    subTotalSuc={subTotalSuc}
                  />
                )
              ) : step === 3 ? (
                firstTimeDoctor && pay === "orderToPatient" ? (
                  <StepThree
                    onStepThree={handleStepThree}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    user={user}
                    subTotalSuc={subTotalSuc}
                  />
                ) : pay === "web" && !free ? (
                  <StepFour
                    items={cartItems}
                    coupon={coupon}
                    discount={discount}
                    subTotal={subTotalWeb}
                    onStepFour={handleStepFour}
                    personalData={personalData}
                    date={date}
                    user={user}
                    place={place}
                    time={time}
                  />
                ) : (
                  <ThanksScreen
                    items={cartItems}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    coupon={coupon}
                    discount={discount}
                    total={
                      pay === "web" || pay === "orderToPatient"
                        ? subTotalWeb
                        : subTotalSuc
                    }
                    otherPerson={otherPersonCheck}
                    firstTimeDoctor={firstTimeDoctor}
                    date={date}
                    place={place}
                    time={time}
                    confirm={confirm}
                  />
                )
              ) : (
                <ThanksScreen
                  items={cartItems}
                  personalData={personalData}
                  isDoctor={isDoctor}
                  pay={pay}
                  coupon={coupon}
                  discount={discount}
                  total={
                    pay === "web" || pay === "orderToPatient"
                      ? subTotalWeb
                      : subTotalSuc
                  }
                  otherPerson={otherPersonCheck}
                  firstTimeDoctor={firstTimeDoctor}
                  date={date}
                  place={place}
                  time={time}
                  confirm={confirm}
                />
              )}
              {step <= 1 ? (
                <Row className="justify-content-center">
                  <ContinueButton
                    variant="primary"
                    onClick={continueClick}
                    disabled={date && time && place ? false : true}
                    isDoctor={isDoctor}
                  >
                    Continuar
                  </ContinueButton>
                </Row>
              ) : null}
            </Container>
          </Background>
        </>
      ) : (
        <EmptyCart>
          <div id="empty">
            <UilClipboardAlt color="#A6A6A6" size={100} />
          </div>
          <p>No hay estudios agregados</p>
          <div className="d-flex align-items-center">
            <WhiteButton onClick={() => history.push("/laboratorio-clinico")}>
              Buscar más estudios
            </WhiteButton>
          </div>
        </EmptyCart>
      )}
    </>
  );
};
