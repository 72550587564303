import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  UilInvoice,
  UilCreditCard,
  UilCreditCardSearch,
  UilPadlock,
} from "@iconscout/react-unicons";
import { Loader } from "../general/spinner";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import handleAlert from "../../helpers/handleAlert";
import { TrackGoogleAnalitycsEventWhitValue } from "../../analytics/google-analytics";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// const API = 'http://localhost:3003';
const API = "http://localhost:3010";

const STRIPE_ERROR = "Error al pagar.Inténtalo otra vez.";
const SERVER_ERROR = "Error de servidor. Inténtalo otra vez.";
const STRIPE_PUBLISHABLE_KEY =
  "pk_live_51HB5FdFXCgaXtV7evVVzv2KY0UgTsyRLMyL2uBjRmtqVKr55AVcGG3W8U3Qk18nWHVwBDAF7uSkAIh8pqavoXRGK00EhRo6ccH";
  //"pk_test_51HB5FdFXCgaXtV7e0RJkxbKJLqZhdyemy1bW5iB94IJlG5FfRt1OzAN4nn888cBMSUgODxwyJytJjj2ZvA88tyKk00G4UUAcLv";
const StyledForDesktop = styled.div`
  //max-width: 620px;
  margin-left: auto;
  margin-right: auto;
`;
const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-top: -45px;
    margin-bottom: 24px;
  }
`;
const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const PlaceP = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #474a4b;
  margin-bottom: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const AddressP = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #717171;
  max-width: 470px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const DateP = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const DataForm = styled.form`
  > .form-floating {
    margin-top: 23px;
  }
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    height: 50px;
  }
  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
  }
  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
  }
  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;
const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 328px;
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  padding: 16px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(164, 164, 164, 0.5);
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
`;

const InvertedCard = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 500px;
  @media only screen and (max-width: 769px) {
    width: 90%;
  }
  padding: 16px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(164, 164, 164, 0.5);
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
`;

const CardDate = styled(Row)`
  position: relative;
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    // background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
  }
  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;
const PayButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  border-radius: 10px;
  opacity: 0.800000011920929;
  background: #f9ad62;
  transition: all ease 0.4s;
  border: none;
  margin: 8px auto;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.15);
  :focus {
    background: #f9ad62;
  }
  :hover {
    background: #f9ad62;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const CotizacionButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  border-radius: 10px;
  opacity: 0.800000011920929;
  background: #474a4b;
  transition: all ease 0.4s;
  border: none;
  margin: 0px auto;
  :focus {
    background: #474a4b;
  }
  :hover {
    background: #474a4b;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const StripeGuaranty = styled(Row)`
  width: 100%;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
  #pay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #stripe-g {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #e0ecec;
    padding: 5px 20px;
    img {
      width: 67px;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 15px;
    }
  }
  #card-logos {
    width: 100%;
    max-width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px auto 0;
    img {
      width: 45.5px;
    }
  }
`;

const ContainerTotalPayBranch = styled.div`
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-direction: column;
  align-items: center;
  span {
    color: #474a4b9e;
    font-size: 16px;
    font-weight: 600;
  }
`;

const StyledFormGroup = styled(Form.Group)`
  max-width: 140px;
  width: 100% !important;
`;

const StyledCheck = styled(Form.Check)`
  padding-left: 1.5em;
  max-width: 140px;
  width: 100% !important;
  .form-check-label {
    width: auto;
  }
  .form-check-input[type="checkbox"] {
    margin-left: -1.5em;
    float: left;
  }
  .form-check-input[type="checkbox"],
  .form-check-input[type="radio"] {
    margin-left: -1.5em;
  }
  label {
    padding-left: 1%;
  }
  > input {
    width: 20px !important;
    height: 20px !important;
    border: 1px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
    :checked {
      border: 1px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
      background-color: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
    }
  }
`;

const VerifyPlansBtn = styled.button`
  position: relative;
  margin-top: 24px;
  border-radius: 102px;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  max-width: 360px;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid #00ad90;
  color: #00ad90;
  :disabled {
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
  }
`;

const PlanSelect = styled(Form.Select)`
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
`;

const StyledModal = styled(Modal)`
  .btn-close {
    right: 24px;
  }
  .confirm-btn {
    background-color: #00ad90;
    outline: none;
    border: none;
    color: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 12px;
    max-width: 140px;
    width: 100%;
  }
  .study-list {
    max-width: 400px;
    width: 100%;
    max-height: 150px;
    overflow: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 12px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: #c4c4c4;
    }
  }
  #payError {
    font-size: 18px;
    font-weight: 400;
  }
`;

const Prices = styled(Row)`
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row;
  p {
    width: auto;
    font-family: "IBM Plex Sans";
    padding-left: 0px;
    padding-right: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  #descuento {
    font-weight: 500;
    font-size: 18px;
    font-style: italic;
    line-height: 27px;
    letter-spacing: -0.03em;
    color: #474a4bb8;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
    }
  }
`;

const PricingBlock = styled.div`
  border-top: 1px solid #e0ecec;
  &:last-child {
    border-bottom: 3px dashed #ccc;
  }
  padding: 10px 0px;
  h3 {
    color: #474a4b;
    font-size: 16px;
    font-weight: 500;
  }
  span {
    color: #474a4bb8;
    font-size: 16px;
    font-weight: 500;
  }
`;

const MyFloating = styled(FloatingLabel)`
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  .form-control {
    border: none;
    background: #e4ddf857;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    padding-left: 16px;
    padding-top: 0.5em !important;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #e4ddf857;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans";
  }
`;

const GreenButton = styled.button`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  width: 100px;
  height: 40px;
  background: #e4ddf8;
  border: none;
  border-radius: 10px;
  margin: 10px auto 10px;
  :focus {
    background: #e4ddf8;
  }
  :hover {
    background: #e4ddf8;
  }
`;

export const StepFourP = (props) => {
  const [loading, setLoading] = useState(false);
  const [msiItems, setMsiItems] = useState([]);
  const [normalItems, setNormalItems] = useState([]);
  const [showMsiMessage, setShowMsiMessage] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState(
    <p>Hubo un error al realizar el cargo a la tarjeta</p>
  );
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [method, setMethod] = useState("");
  const [inputValue, setInputValue] = useState({});
  const [isInvalid, setIsInvalid] = useState(true);
  const [plan, setPlan] = useState();
  const [intent, setIntent] = useState();
  const [charge, setCharge] = useState();
  const [cupon, setCupon] = useState("");
  const [cuponApplied, setCuponApplied] = useState(false);
  const [cuponMessage, setCuponMessage] = useState("");
  const uid = useSelector((state) => state.auth.uid);

  const [payInExhibitions, setPayInExhibitions] = useState(false);
  const total = props.subTotal;
  const items = props.items;
  const idCart = useState(1); //It was useParams();
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // const savePayment = async (payment) => {
  //   try {
  //     let request = axios.post(API + '/save-payment', {
  //       data: payment,
  //       id: idCart,
  //     });
  //     let { data } = await request;
  //     console.log(data);
  //     window.location.href = '/pago-confirmado/' + idCart; //change this for setStep(5)???
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const createPaymentMethod = async (data) => {
    const card = {
      number: data.number.replace(/ /g, ""),
      exp_month: data.month,
      exp_year: data.year,
      cvc: data.cvc,
    };
    try {
      // const response = await fetch(`${API}/payment-method-lp`, {
      const response = await fetch("https://labopat-5792e.appspot.com/payment-method-lp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(card),
      });
      return response.json();
    } catch (error) {
      handleAlert(
        "error",
        "Ha ocurrido un error, por favor intenta de nuevo",
        "Ocurrió un error"
      );
      setLoading(false);
    }
  };
  const checkAvailablePlans = async () => {
    if (isInvalid) {
      handleAlert(
        "warning",
        "Debes escribir los datos de tu tarjeta",
        "Verifica la información"
      );
    } else {
      setLoading(true);
      let price = 0;
      msiItems.forEach((item) => (price += item.price));
      const data = {
        number: inputValue["cardNumber"].replace(/ /g, ""),
        month: inputValue["month"],
        year: inputValue["year"],
        cvc: inputValue["cvv"],
        amount: price,
      };
      const name = props.personalData.name;
      const fathersLastname = props.personalData.fathersLastname
        ? ` ${props.personalData.fathersLastname}`
        : "";
      const mothersLastname = props.personalData.mothersLastname
        ? ` ${props.personalData.mothersLastname}`
        : "";
      const customerName = name + fathersLastname + mothersLastname;
      const paymentMethod = await createPaymentMethod(data);
      let amount = data.amount * 100;
      try {
        // const response = await fetch(`${API}/card-plans-lp`, {
        const response = await fetch("https://labopat-5792e.appspot.com/card-plans-lp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amount: amount,
            payment_method_id: paymentMethod.paymentMethod_id,
            description: customerName,
            email: props?.personalData?.email
              ? props?.personalData?.email
              : props.user?.email,
          }),
        });
        let json = await response.json();
        const msi = [3, 6];
        json = {
          ...json,
          available_plans: json.available_plans.filter((plan) =>
            msi.includes(plan.count)
          ),
        };
        setPlan(json);
        setLoading(false);
      } catch (error) {
        handleAlert(
          "error",
          "Ha ocurrido un error, por favor intenta de nuevo",
          "Ocurrió un error"
        );
        setLoading(false);
      }
    }
  };
  const confirmPayment = async (data) => {
    // let cardInfo = {
    //   cardType: data.cardType,
    //   cardName: data.cardName,
    //   cardAddress: data.cardAddress,
    //   cardPostalCode: data.cardPostalCode,
    // };
    try {
      // const response = await fetch(`${API}/confirm-payment-lp`, {
      const response = await fetch("https://labopat-5792e.appspot.com/confirm-payment-lp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_intent_id: data.payment_intent_id,
          selected_plan: data.selected_plan,
        }),
      });
      let json = await response.json();
      setLoading(false);
      setIntent(json);
      // props.onStepFour({ intent: json }, cardInfo);
    } catch (error) {
      handleAlert(
        "error",
        "Hubo un error al confirmar el pago",
        "Ocurrió un error"
      );
      setLoading(false);
    }
  };
  const getCreditcardToken = (creditCardData) => {
    const card = {
      "card[number]": creditCardData.number.replace(/ /g, ""),
      "card[exp_month]": creditCardData.month,
      "card[exp_year]": creditCardData.year,
      "card[cvc]": creditCardData.cvc,
      "card[name]": creditCardData.name,
      "card[address_zip]": creditCardData.zip,
    };
    return fetch("https://api.stripe.com/v1/tokens", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_PUBLISHABLE_KEY}`,
      },
      method: "post",
      body: Object.keys(card)
        .map((key) => key + "=" + card[key])
        .join("&"),
    }).then((response) => response.json());
  };
  const handleSubmitPay = async (e) => {
    setLoading(true);
    //console.log('personal data: ', props.personalData);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    let creditCardToken;
    try {
      // Create a credit card token
      creditCardToken = await getCreditcardToken({
        number: e.cardNumber,
        month: e.month,
        year: e.year,
        cvc: e.cvv,
        name: e.cardName,
        // zip: e.cardPostalCode,
      });
      if (creditCardToken.error) {
        // Reset the state if Stripe responds with an error
        // Set submitted to false to let the user subscribe again
        // console.log('error ---<|> ', creditCardToken.error);
        await setLoading(false);
        if (
          creditCardToken.error.message === "Your card number is incorrect."
        ) {
          setErrorModalText(
            <>
              <p>El número de tu tarjeta es incorrecto</p>
              <p>Verifica tus datos e intenta de nuevo</p>
            </>
          );
          setShowErrorModal(true);
        } else {
          setErrorModalText(<p>{creditCardToken.error.message}</p>);
          setShowErrorModal(true);
        }
        // window.alert(creditCardToken.error.message);
        //this.setState({ submitted: false, error: STRIPE_ERROR });
        return;
      }
    } catch (e) {
      // Reset the state if the request was sent with an error
      // Set submitted to false to let the user subscribe again
      if (e?.error?.message === "Your card number is incorrect.") {
        setErrorModalText(
          <>
            <p>El número de tu tarjeta es incorrecto</p>
            <p>Verifica tus datos e intenta de nuevo</p>
          </>
        );
        setShowErrorModal(true);
      } else {
        setErrorModalText(<p>{e?.error?.message}</p>);
        setShowErrorModal(true);
      }
      // console.log('error --> ', e);
      await setLoading(false);
      //this.setState({ submitted: false, error: STRIPE_ERROR });
      return;
    }
    // console.log('Correcto bro .. vamos a pagar ..____.');
    //console.log(creditCardToken);
    if (inputValue["selectedPlan"] !== undefined) {
      if (inputValue["selectedPlan"] === "Un solo pago") {
        // If user select Un solo pago option then do one single payment
        // The amount is the sum of the prices of all the products in the cart so we send the props.subTotal
        doPayment({
          amount: props.subTotal.toFixed() * 100,
          token: creditCardToken.id,
          cardType: e.cardType,
          cardName: e.cardName,
          cardAddress: e.cardAddress,
          // cardPostalCode: e.cardPostalCode,
        });
        setPayInExhibitions(false);
        TrackGoogleAnalitycsEventWhitValue(
          "purchase",
          "purchase",
          "pago_unico_exitoso",
          parseFloat(props.subTotal.toFixed())
        );
        // console.log('one single payment option')
        // setLoading(false);
      } else {
        // If user select 3 MSI option then confirm the payment intent
        // Don't need the amount here because the amount was already set previously in the checkAvailablePlans function
        // Then we recieved the payment intent id which contains the amount to be paid
        // And finally we confirm the payment intent with the selected plan
        confirmPayment({
          payment_intent_id: plan.intent_id,
          selected_plan: plan.available_plans.filter(
            (plan) => plan.count === Number(inputValue["selectedPlan"])
          )[0],
          cardType: e.cardType,
          cardName: e.cardName,
          cardAddress: e.cardAddress,
          // cardPostalCode: e.cardPostalCode,
        });
        TrackGoogleAnalitycsEventWhitValue(
          "purchase",
          "purchase",
          "pago_msi_exitoso",
          parseFloat(props.subTotal.toFixed())
        );
        // console.log("msi payment");
        // setLoading(false);
        // This will be the case when user select a MSI plan and have normal studies in the cart too
        // First confirm the payment intent for MSI and then do the second payment for normal studies
        if (normalItems.length > 0) {
          // The amount for the second payment is the sum of the prices of all the normal studies
          let amount = 0;
          normalItems.forEach((item) => (amount += item.price));
          doPayment({
            amount: amount * 100,
            token: creditCardToken.id,
            cardType: e.cardType,
            cardName: e.cardName,
            cardAddress: e.cardAddress,
            // cardPostalCode: e.cardPostalCode,
          });
          setPayInExhibitions(true);
          // console.log("and normal payment too");
          // console.log(creditCardToken.id);
          // setLoading(false);
          TrackGoogleAnalitycsEventWhitValue(
            "purchase",
            "purchase",
            "pago_items_normal_exitoso",
            parseFloat(amount)
          );
        }
      }
    } else if (
      (inputValue["selectedPlan"] === undefined && normalItems.length > 0) ||
      msiItems.length > 0
    ) {
      // If user doesn't select MSI option or user select debit card option then do one single payment for all the studies in the cart
      // Here we use props.subTotal because it is the sum of the prices of all the studies in the cart, including MSI studies and normal studies
      // console.log("normal charge");
      // setLoading(false);
      doPayment({
        amount: props.subTotal.toFixed() * 100,
        token: creditCardToken.id,
        cardType: e.cardType,
        cardName: e.cardName,
        cardAddress: e.cardAddress,
        // cardPostalCode: e.cardPostalCode,
      });
      setPayInExhibitions(false);
      TrackGoogleAnalitycsEventWhitValue(
        "purchase",
        "purchase",
        "pago_normal_exitoso",
        parseFloat(props.subTotal.toFixed())
      );
    }
  };
  const { width } = useWindowDimensions();
  let isMobile = width <= 576;

  const doPayment = async (data) => {
    try {
      let urlPayy = `https://labopat-5792e.appspot.com/payment-stripe?psw=Gwi&amount=${
        // let urlPayy = `${API}/payment-stripe?psw=Gwi&amount=${
        data.amount
      }&source=${
        data.token
      }&description=labopat.mx&uid=uid&email_cust=email@email.com&mail=${
        props?.personalData?.email
          ? props?.personalData?.email
          : props.user.email
      }`;
      // console.log(urlPayy);
      let request = axios.get(urlPayy);
      let response = await request;
      console.log("Payment status: ", response.status);
      setLoading(false);
      setCharge(response);
      // props.onStepFour(response, {
      //   cardType: data.cardType,
      //   cardName: data.cardName,
      //   cardAddress: data.cardAddress,
      //   cardPostalCode: data.cardPostalCode,
      // });
    } catch (error) {
      setLoading(false);
      if (error.response.data.error === "Your card was declined.") {
        setErrorModalText(
          <>
            <p>Tarjeta Declinada</p>
            <p>
              La tarjeta fue declinada, intenta de nuevo o ingresa otra tarjeta.
            </p>
          </>
        );
        setShowErrorModal(true);
      } else if (
        error.response.data.error === "Your card has insufficient funds."
      ) {
        setErrorModalText(
          <>
            <p>Fondos Insuficientes</p>
            <p>
              La tarjeta no cuenta con fondos suficientes para realizar el
              cargo.
            </p>
            <br />
            <p>Intenta de nuevo o ingresa otra tarjeta.</p>
          </>
        );
        setShowErrorModal(true);
      } else {
        setErrorModalText(<p>{error.response.data.error}</p>);
        setShowErrorModal(true);
      }
      console.log(error.response.data.error);
      // window.alert("**" + error.response.data.error);
    }
  };
  // const altaClientes = async (data) => {
  //   var params = data;
  //   console.log('params: ', params);
  //   let request = axios({
  //     method: 'get',
  //     url: 'https://clienteslabopat.com/alta-ecom',
  //   });
  //   let response = await request;
  //   props.onStepFour(response);
  // };
  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setInputValue({ ...inputValue, [name]: value });
  };
  const getInputValue = (e) => {
    const name = e;
    return inputValue[name] || "";
  };
  useEffect(() => {
    if (
      inputValue["cardNumber"] &&
      inputValue["month"] &&
      inputValue["year"] &&
      inputValue["cvv"]
    ) {
      inputValue["cardNumber"].length >= 15 &&
      inputValue["cardNumber"].length <= 16 &&
      inputValue["month"].length >= 1 &&
      inputValue["month"].length <= 2 &&
      inputValue["year"].length === 4 &&
      inputValue["cvv"].length >= 3 &&
      inputValue["cvv"].length <= 4
        ? setIsInvalid(false)
        : setIsInvalid(true);
    } else {
      setIsInvalid(true);
    }
  }, [inputValue]);
  useEffect(() => {
    setPlan(undefined);
    setInputValue({});
    reset({
      cardType: method,
    });
  }, [method]);
  useEffect(() => {
    let withMsi = [];
    let withoutMsi = [];
    items.forEach((element) => {
      if (element.msi === 1) {
        withMsi.push(element);
      } else {
        withoutMsi.push(element);
      }
    });
    setMsiItems(withMsi);
    setNormalItems(withoutMsi);
  }, [items]);
  useEffect(() => {
    // console.log(msiItems);
    // console.log(normalItems);
    if (msiItems.length > 0) {
      setShowMsiMessage(true);
    }
  }, [msiItems, normalItems]);
  useEffect(() => {
    if (payInExhibitions && charge && intent) {
      let cardInfo = {
        cardType: method,
        cardName: inputValue["cardName"],
        cardAddress: inputValue["cardAddress"],
        // cardPostalCode: inputValue["cardPostalCode"],
      };
      props.onStepFourP(charge, intent, cardInfo);
      // console.log('Charge and intent')
      setPayInExhibitions(false);
      setCharge(undefined);
      setIntent(undefined);
      reset({ cardType: method });
      setInputValue({});
    } else if (charge && !payInExhibitions) {
      let cardInfo = {
        cardType: method,
        cardName: inputValue["cardName"],
        cardAddress: inputValue["cardAddress"],
        // cardPostalCode: inputValue["cardPostalCode"],
      };
      props.onStepFourP(charge, undefined, cardInfo);
      // console.log('Only charge');
      setPayInExhibitions(false);
      setCharge(undefined);
      setIntent(undefined);
      reset({ cardType: method });
      setInputValue({});
    } else if (intent && !payInExhibitions) {
      let cardInfo = {
        cardType: method,
        cardName: inputValue["cardName"],
        cardAddress: inputValue["cardAddress"],
        // cardPostalCode: inputValue["cardPostalCode"],
      };
      props.onStepFourP(undefined, intent, cardInfo);
      // console.log('Only intent')
      setPayInExhibitions(false);
      setCharge(undefined);
      setIntent(undefined);
      reset({ cardType: method });
      setInputValue({});
    }
  }, [charge, intent, payInExhibitions]);

  const applyCupon = async () => {
    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("cupon", cupon);
    console.log("*****");

    items.forEach((element) => {
      console.log(element);
      console.log("---------");
      formData.append("products[]", element.id);
      formData.append("studiesPrice[]", element.oldPrice);
      formData.append("studies[]", element.id);
      //return;
      /*element.forEach((elem) => {
        
      });*/
    });
    let response = await axios.post(
      "https://lpqsystem.com/apply-cupon-test",
      formData
    );
    console.log("cupon :", response.data);
    if (response.data.ok && response.data.tipo !== -1) {
      setCuponApplied(true);
      if (response.data.tipo === 0) {
        setCuponMessage("Descuento de martes aplicado correctamente");
      } else if (response.data.tipo === 1) {
        setCuponMessage(
          "Descuento aplicado correctamente, canjeaste un cupón gratuito"
        );
      } else if (response.data.tipo === 2) {
        setCuponMessage(
          `Descuento aplicado correctamente, tienes disponible un saldo de ${response.data.saldoDisponible} en tu tarjeta de regalo`
        );
      } else if (response.data.tipo === 3) {
        setCuponMessage("Descuento aplicado correctamente");
      } else if (response.data.tipo === 4) {
        setCuponMessage(
          "El cupón ya no es valido, comunícate a la línea Labopat"
        );
      }

      props.applyCupon(
        cupon,
        response.data.paquetes,
        response.data.free,
        response.data.cuponID,
        response.data.cliente
      );
    } else {
      setCuponApplied(false);
      if (response.data?.tipo === 4) {
        setCuponMessage(
          "El cupón ya no es valido, comunícate a la línea Labopat"
        );
      } else {
        setCuponMessage(
          "Hubo un error al canjear tu cupón. (Si persiste el error comunícate a la línea Labopat)"
        );
      }
    }
  };

  // Función para formatear el número con espacios
  const formatCardNumber = (value) => {
    return value
      .replace(/\D/g, "") // Eliminar caracteres no numéricos
      .replace(/(.{4})(?=.)/g, "$1 ") // Insertar espacios cada 4 dígitos
      .slice(0, 19); // Limitar a 19 caracteres (16 dígitos + 3 espacios)
  };

  // Función para formatear el número
  const formatCVV = (value) => {
    return value.replace(/\D/g, "").slice(0, 4); // Limita a 3 dígitos
  };

  return (
    <StyledForDesktop>
      <Title style={{ textAlign: isMobile ? "center" : "" }}>
        Para finalizar confirma tu orden y realiza tu pago
      </Title>
      <InvertedCard style={{ textAlign: "center" }}>
        <PlaceP>
          {props.personalData.name || props.user?.name} tu cita es en{" "}
          {props.place.name}
        </PlaceP>
        <AddressP>{props.place.address}</AddressP>
        <DateP isDoctor={isDoctor}>
          {days[props.date.getDay()]}{" "}
          {String(props.date.getDate()).padStart(2, "0")} de{" "}
          {months[props.date.getMonth()]} del {props.date.getFullYear()} de{" "}
          {props.time}
        </DateP>
      </InvertedCard>

      {isMobile ? (
        <>
          <DataForm onSubmit={handleSubmit(handleSubmitPay)} autoComplete="off">
            <Row>
            <Col md={6}>
                <InvertedRadius>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#3B3B3B",
                    }}
                  >
                    Resumen de solicitud
                  </p>
                  {items.map((item, index) => (
                    <PricingBlock className="mt-3">
                      <h3>{item.title}</h3>
                      <div className="d-flex gap-3">
                        {!props.order ? (
                          <span>{currency(item.oldPrice)} MXN</span>
                        ) : (
                          <span>{currency(item.price)} MXN</span>
                        )}
                      </div>
                    </PricingBlock>
                  ))}
                  {props.discount !== 0 && (
                    <div>
                      <hr />
                      <p>
                        Usaste el cupón "
                        <span style={{ fontWeight: "800" }}>
                          {props.coupon}
                        </span>
                        "
                      </p>
                      <p>
                        Obtuviste un descuento de{" "}
                        <span style={{ fontWeight: "800" }}>
                          {currency(props.discount)} MXN
                        </span>
                      </p>
                    </div>
                  )}

                  <br />

                  <hr className="pointed" />

                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: isMobile ? "" : "0%",
                      marginTop: isMobile ? "" : "",
                    }}
                  >
                    {!props.order && (
                      <>
                        <ContainerTotalPayBranch>
                          <p>
                            <UilInvoice />
                            <span>Total pago en Sucursal:</span>
                          </p>
                          <span>{currency(props.subTotalSuc)} MXN</span>
                        </ContainerTotalPayBranch>

                        <CotizacionButton onClick={props.cotizaciones}>
                          Generar cita
                        </CotizacionButton>
                        <br />
  
                      </>
                    )}
                    <Prices>
                      {" "}
                      <p id="descuento">
                        {props.subTotalSuc - total > 0 && (
                          <>
                            Ahorras: {currency(total - props.subTotalSuc)} MXN
                          </>
                        )}
                      </p>
                    </Prices>
                    <Row style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Col md={8}>
                        <MyFloating
                          controlId="floatingName"
                          label="INGRESA CÓDIGO"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Ingresa tu cupón aquí"
                            value={cupon}
                            style={{ background: "#E4DDF857" }}
                            onChange={(e) => {
                              setCupon(e?.target?.value?.toUpperCase());
                            }}
                          />
                          <p
                            style={{
                              textAlign: "center",
                              color: "#3B3B3B",
                              marginTop: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            *Códigos no acumulables
                          </p>
                        </MyFloating>
                      </Col>
                      <Col md={4}>
                        <div className="d-flex align-items-center">
                          <GreenButton
                            disabled={cuponApplied || cupon.length === 0}
                            onClick={() => applyCupon()}
                          >
                            Aplicar
                          </GreenButton>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  
                </InvertedRadius>
              </Col>
              <Col md={6}>
                <InvertedRadius>
                  <RegularP style={{ marginBottom: "24px", marginTop: "24px" }}>
                    Tipo de tarjeta
                  </RegularP>
                  <div className="mb-3">
                    <Row>
                      <StyledFormGroup controlId="creditCard" className="p-0">
                        <StyledCheck
                          {...register("cardType", {
                            required: true,
                          })}
                          value="credito"
                          id="credito"
                          type="radio"
                          aria-label="radio 1"
                          label="Crédito"
                          isDoctor={isDoctor}
                          onChange={(e) => {
                            setMethod(e.target.value);
                          }}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup controlId="debitCard" className="p-0">
                        <StyledCheck
                          {...register("cardType", {
                            required: true,
                          })}
                          value="debito"
                          id="debito"
                          type="radio"
                          aria-label="radio 3"
                          label="Débito"
                          isDoctor={isDoctor}
                          onChange={(e) => {
                            setMethod(e.target.value);
                          }}
                        />
                      </StyledFormGroup>
                    </Row>
                  </div>
                  <RegularP style={{ padding: "0 12px 0 12px" }}>
                    Escribe los 16 dígitos de la tarjeta
                  </RegularP>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      {...register("cardNumber", {
                        required: true,
                        maxLength: 19,
                        pattern: {
                          value: /^\d{4} \d{4} \d{4} \d{4}$/, // Validar formato XXXX XXXX XXXX XXXX
                          message: "Debe estar en formato XXXX XXXX XXXX XXXX",
                        },
                      })}
                      placeholder="XXXX XXXX XXXX XXXX"
                      value={formatCardNumber(
                        getInputValue("cardNumber") || ""
                      )} // Formatear el valor con guiones
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
                        value = value.slice(0, 16); // Limitar a 16 dígitos
                        handleInput({ target: { name: "cardNumber", value } }); // Guardar sin guiones
                      }}
                      style={{ paddingLeft: "10px", paddingRight: "40px" }} // Espacio para el icono
                      maxLength={19} // Máximo de 19 caracteres (16 dígitos + 3 guiones)
                    />
                    <UilCreditCard
                      size="20"
                      color="#888"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Evita que el icono sea clickeable
                      }}
                    />
                  </div>

                  <CardDate>
                    <RegularP
                      style={{ marginBottom: "0px", marginTop: "24px" }}
                    >
                      Fecha de expiración
                    </RegularP>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "8px",
                        zIndex: "1",
                      }}
                    >
                      <Form.Label>Mes</Form.Label>
                      <Form.Select
                        {...register("month", {
                          required: true,
                          pattern: {
                            value: /[0-9]/i,
                            message: "Selecciona una fecha",
                          },
                        })}
                        value={getInputValue("month")}
                        onChange={(e) => handleInput(e)}
                      >
                        <option hidden>MM</option>
                        <option value={1}>01</option>
                        <option value={2}>02</option>
                        <option value={3}>03</option>
                        <option value={4}>04</option>
                        <option value={5}>05</option>
                        <option value={6}>06</option>
                        <option value={7}>07</option>
                        <option value={8}>08</option>
                        <option value={9}>09</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                      </Form.Select>
                    </Col>
                    <p
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      /
                    </p>
                    <Col xs={6} style={{ paddingLeft: "8px", zIndex: "1" }}>
                      <Form.Label>Año</Form.Label>
                      <Form.Select
                        {...register("year", {
                          required: true,
                          pattern: {
                            value: /[0-9]/i,
                            message: "Selecciona una fecha",
                          },
                        })}
                        value={getInputValue("year")}
                        onChange={(e) => handleInput(e)}
                      >
                        <option hidden>YYYY</option>
                        <option value={2025}>2025</option>
                        <option value={2026}>2026</option>
                        <option value={2027}>2027</option>
                        <option value={2028}>2028</option>
                        <option value={2029}>2029</option>
                        <option value={2030}>2030</option>
                        <option value={2031}>2031</option>
                        <option value={2032}>2032</option>
                        <option value={2033}>2033</option>
                        <option value={2034}>2034</option>
                        <option value={2035}>2035</option>
                      </Form.Select>
                    </Col>
                  </CardDate>
                  <RegularP
                    style={{
                      marginBottom: "0px",
                      marginTop: "24px",
                      marginLeft: "11px",
                    }}
                  >
                    Número CVV
                  </RegularP>
                  <br />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="password"
                      {...register("cvv", {
                        required: true,
                        minLength: 3,
                        maxLength: 4, // Limita a 3 dígitos
                        
                      })}
                      placeholder="CVV"
                       // Formatear el valor con 3 dígitos
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
                        value = value.slice(0, 4); // Limitar a 3 dígitos
                        handleInput({ target: { name: "cvv", value } }); // Guardar el valor
                      }}
                      autoComplete="off"
                      style={{ paddingLeft: "10px", paddingRight: "30px" }} // Ajustar el padding para el icono
                      maxLength={4} // Limitar a 3 caracteres
                    />
                    <UilCreditCardSearch
                      style={{
                        position: "absolute",
                        right: "10px", // Ajuste del espacio
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#A6A6A6",
                        pointerEvents: "none", // Evita que el icono sea clickeable
                      }}
                    />
                  </div>
                  <br />
                  <br />

                  <Form.Control
                    type="text"
                    {...register("cardName", {
                      required: true,
                      pattern: {
                        message: "Campo requerido",
                      },
                    })}
                    placeholder="Nombre del titular de la tarjeta"
                    value={getInputValue("cardName")}
                    onChange={(e) => handleInput(e)}
                  />

                  {method === "credito" && msiItems.length > 0 && (
                    <>
                      <Row className="justify-content-center">
                        <VerifyPlansBtn
                          disabled={isInvalid}
                          type="button"
                          onClick={() => checkAvailablePlans()}
                        >
                          Revisar promociones disponibles
                        </VerifyPlansBtn>
                      </Row>
                      {plan && (
                        <>
                          {plan?.available_plans?.length > 0 ? (
                            <>
                              <RegularP
                                className="mt-4"
                                style={{ padding: "0 12px 0 12px" }}
                              >
                                Elige tu plan de pagos
                              </RegularP>
                              <PlanSelect
                                name="selectedPlan"
                                value={getInputValue("selectedPlan")}
                                onChange={(e) => handleInput(e)}
                              >
                                <option hidden>Selecciona un plan</option>
                                <option value={"Un solo pago"}>
                                  Un solo pago
                                </option>
                                {plan?.available_plans?.map((plan, index) => (
                                  <option key={index} value={plan?.count}>
                                    {plan?.count} meses sin intereses
                                  </option>
                                ))}
                              </PlanSelect>
                            </>
                          ) : (
                            <RegularP className="pt-4 text-center">
                              No hay planes disponibles
                            </RegularP>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: isMobile ? "" : "0%",
                      marginTop: isMobile ? "" : "",
                    }}
                  >
                    <br />
                    {!props.order && (
                      <>
                        <ContainerTotalPayBranch>
                          <p>
                            <UilInvoice />
                            <span>Descuento pago en línea:</span>
                          </p>
                        </ContainerTotalPayBranch>
                      </>
                    )}
                    <PayButton type="submit">
                      Pagar ahora {currency(total)}
                    </PayButton>
                    <Prices>
                      {" "}
                      <p id="descuento">
                        {props.subTotalSuc - total > 0 && (
                          <>
                            Ahorras: {currency(total - props.subTotalSuc)} MXN
                          </>
                        )}
                      </p>
                    </Prices>
                  </div>
                  <br />
                  <StripeGuaranty>
                    <Col
                      xs={12}
                      lg={12}
                      id="pay"
                      style={{ flexDirection: "column" }}
                    >
                      <p>
                        <UilPadlock size={25} />
                        &nbsp;&nbsp;Pago garantizado y seguro.
                      </p>

                      <div id="stripe-g">
                        <p>Procesado por&nbsp;&nbsp;</p>
                        <img
                          alt="Stripe"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div id="card-logos">
                        <img
                          alt="mastercard"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                        />
                        <img
                          alt="visa"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                        />
                        <img
                          alt="amex"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                        />
                      </div>
                    </Col>
                  </StripeGuaranty>

                  <br />
                  {loading ? <Loader show={loading} /> : null}
                </InvertedRadius>
              </Col>
            </Row>
          </DataForm>
        </>
      ) : (
        <>
          <DataForm onSubmit={handleSubmit(handleSubmitPay)} autoComplete="off">
            <Row>
              <Col md={6}>
                <InvertedRadius>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#3B3B3B",
                    }}
                  >
                    Resumen de solicitud
                  </p>
                  {items.map((item, index) => (
                    <PricingBlock className="mt-3">
                      <h3>{item.title}</h3>
                      <div className="d-flex gap-3">
                        {!props.order ? (
                          <span>{currency(item.oldPrice)} MXN</span>
                        ) : (
                          <span>{currency(item.price)} MXN</span>
                        )}
                      </div>
                    </PricingBlock>
                  ))}
                  {props.discount !== 0 && (
                    <div>
                      <hr />
                      <p>
                        Usaste el cupón "
                        <span style={{ fontWeight: "800" }}>
                          {props.coupon}
                        </span>
                        "
                      </p>
                      <p>
                        Obtuviste un descuento de{" "}
                        <span style={{ fontWeight: "800" }}>
                          {currency(props.discount)} MXN
                        </span>
                      </p>
                    </div>
                  )}

                  <br />

                  <hr className="pointed" />

                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: isMobile ? "" : "0%",
                      marginTop: isMobile ? "" : "",
                    }}
                  >
                    {!props.order && (
                      <>
                        <ContainerTotalPayBranch>
                          <p>
                            <UilInvoice />
                            <span>Total pago en Sucursal:</span>
                          </p>
                          <span>{currency(props.subTotalSuc)} MXN</span>
                        </ContainerTotalPayBranch>

                        <CotizacionButton onClick={props.cotizaciones}>
                          Generar cita
                        </CotizacionButton>
                        <br />
                        <ContainerTotalPayBranch>
                          <p>
                            <UilInvoice />
                            <span>Descuento pago en línea:</span>
                          </p>
                        </ContainerTotalPayBranch>
                      </>
                    )}
                    <PayButton type="submit">
                      Pagar ahora {currency(total)}
                    </PayButton>
                    <Prices>
                      {" "}
                      <p id="descuento">
                        {props.subTotalSuc - total > 0 && (
                          <>
                            Ahorras: {currency(total - props.subTotalSuc)} MXN
                          </>
                        )}
                      </p>
                    </Prices>
                    <Row style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Col md={8}>
                        <MyFloating
                          controlId="floatingName"
                          label="INGRESA CÓDIGO"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Ingresa tu cupón aquí"
                            value={cupon}
                            style={{ background: "#E4DDF857" }}
                            onChange={(e) => {
                              setCupon(e?.target?.value?.toUpperCase());
                            }}
                          />
                          <p
                            style={{
                              textAlign: "center",
                              color: "#3B3B3B",
                              marginTop: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            *Códigos no acumulables
                          </p>
                        </MyFloating>
                      </Col>
                      <Col md={4}>
                        <div className="d-flex align-items-center">
                          <GreenButton
                            disabled={cuponApplied || cupon.length === 0}
                            onClick={() => applyCupon()}
                          >
                            Aplicar
                          </GreenButton>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </InvertedRadius>
              </Col>
              <Col md={6}>
                <InvertedRadius>
                  <RegularP style={{ marginBottom: "24px", marginTop: "24px" }}>
                    Tipo de tarjeta
                  </RegularP>
                  <div className="mb-3">
                    <Row>
                      <StyledFormGroup controlId="creditCard" className="p-0">
                        <StyledCheck
                          {...register("cardType", {
                            required: true,
                          })}
                          value="credito"
                          id="credito"
                          type="radio"
                          aria-label="radio 1"
                          label="Crédito"
                          isDoctor={isDoctor}
                          onChange={(e) => {
                            setMethod(e.target.value);
                          }}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup controlId="debitCard" className="p-0">
                        <StyledCheck
                          {...register("cardType", {
                            required: true,
                          })}
                          value="debito"
                          id="debito"
                          type="radio"
                          aria-label="radio 3"
                          label="Débito"
                          isDoctor={isDoctor}
                          onChange={(e) => {
                            setMethod(e.target.value);
                          }}
                        />
                      </StyledFormGroup>
                    </Row>
                  </div>
                  <RegularP style={{ padding: "0 12px 0 12px" }}>
                    Escribe los 16 dígitos de la tarjeta
                  </RegularP>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      {...register("cardNumber", {
                        required: true,
                        maxLength: 19,
                        pattern: {
                          value: /^\d{4} \d{4} \d{4} \d{4}$/, // Validar formato XXXX XXXX XXXX XXXX
                          message: "Debe estar en formato XXXX XXXX XXXX XXXX",
                        },
                      })}
                      placeholder="XXXX XXXX XXXX XXXX"
                      value={formatCardNumber(
                        getInputValue("cardNumber") || ""
                      )} // Formatear el valor con guiones
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
                        value = value.slice(0, 16); // Limitar a 16 dígitos
                        handleInput({ target: { name: "cardNumber", value } }); // Guardar sin guiones
                      }}
                      style={{ paddingLeft: "10px", paddingRight: "40px" }} // Espacio para el icono
                      maxLength={19} // Máximo de 19 caracteres (16 dígitos + 3 guiones)
                    />
                    <UilCreditCard
                      size="20"
                      color="#888"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Evita que el icono sea clickeable
                      }}
                    />
                  </div>

                  <CardDate>
                    <RegularP
                      style={{ marginBottom: "0px", marginTop: "24px" }}
                    >
                      Fecha de expiración
                    </RegularP>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: "0px",
                        paddingRight: "8px",
                        zIndex: "1",
                      }}
                    >
                      <Form.Label>Mes</Form.Label>
                      <Form.Select
                        {...register("month", {
                          required: true,
                          pattern: {
                            value: /[0-9]/i,
                            message: "Selecciona una fecha",
                          },
                        })}
                        value={getInputValue("month")}
                        onChange={(e) => handleInput(e)}
                      >
                        <option hidden>MM</option>
                        <option value={1}>01</option>
                        <option value={2}>02</option>
                        <option value={3}>03</option>
                        <option value={4}>04</option>
                        <option value={5}>05</option>
                        <option value={6}>06</option>
                        <option value={7}>07</option>
                        <option value={8}>08</option>
                        <option value={9}>09</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                      </Form.Select>
                    </Col>
                    <p
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      /
                    </p>
                    <Col xs={6} style={{ paddingLeft: "8px", zIndex: "1" }}>
                      <Form.Label>Año</Form.Label>
                      <Form.Select
                        {...register("year", {
                          required: true,
                          pattern: {
                            value: /[0-9]/i,
                            message: "Selecciona una fecha",
                          },
                        })}
                        value={getInputValue("year")}
                        onChange={(e) => handleInput(e)}
                      >
                        <option hidden>YYYY</option>
                        <option value={2025}>2025</option>
                        <option value={2026}>2026</option>
                        <option value={2027}>2027</option>
                        <option value={2028}>2028</option>
                        <option value={2029}>2029</option>
                        <option value={2030}>2030</option>
                        <option value={2031}>2031</option>
                        <option value={2032}>2032</option>
                        <option value={2033}>2033</option>
                        <option value={2034}>2034</option>
                        <option value={2035}>2035</option>
                      </Form.Select>
                    </Col>
                  </CardDate>
                  <RegularP
                    style={{
                      marginBottom: "0px",
                      marginTop: "24px",
                      marginLeft: "11px",
                    }}
                  >
                    Número CVV
                  </RegularP>
                  <br />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="password"
                      {...register("cvv", {
                        required: true,
                        minLength: 3,
                        maxLength: 4, // Limita a 3 dígitos
                        
                      })}
                      placeholder="CVV"
                       // Formatear el valor con 3 dígitos
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
                        value = value.slice(0, 4); // Limitar a 3 dígitos
                        handleInput({ target: { name: "cvv", value } }); // Guardar el valor
                      }}
                      autoComplete="off"
                      style={{ paddingLeft: "10px", paddingRight: "30px" }} // Ajustar el padding para el icono
                      maxLength={4} // Limitar a 3 caracteres
                    />
                    <UilCreditCardSearch
                      style={{
                        position: "absolute",
                        right: "10px", // Ajuste del espacio
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#A6A6A6",
                        pointerEvents: "none", // Evita que el icono sea clickeable
                      }}
                    />
                  </div>
                  <br />
                  <br />

                  <Form.Control
                    type="text"
                    {...register("cardName", {
                      required: true,
                      pattern: {
                        message: "Campo requerido",
                      },
                    })}
                    placeholder="Nombre del titular de la tarjeta"
                    value={getInputValue("cardName")}
                    onChange={(e) => handleInput(e)}
                  />

                  {method === "credito" && msiItems.length > 0 && (
                    <>
                      <Row className="justify-content-center">
                        <VerifyPlansBtn
                          disabled={isInvalid}
                          type="button"
                          onClick={() => checkAvailablePlans()}
                        >
                          Revisar promociones disponibles
                        </VerifyPlansBtn>
                      </Row>
                      {plan && (
                        <>
                          {plan?.available_plans?.length > 0 ? (
                            <>
                              <RegularP
                                className="mt-4"
                                style={{ padding: "0 12px 0 12px" }}
                              >
                                Elige tu plan de pagos
                              </RegularP>
                              <PlanSelect
                                name="selectedPlan"
                                value={getInputValue("selectedPlan")}
                                onChange={(e) => handleInput(e)}
                              >
                                <option hidden>Selecciona un plan</option>
                                <option value={"Un solo pago"}>
                                  Un solo pago
                                </option>
                                {plan?.available_plans?.map((plan, index) => (
                                  <option key={index} value={plan?.count}>
                                    {plan?.count} meses sin intereses
                                  </option>
                                ))}
                              </PlanSelect>
                            </>
                          ) : (
                            <RegularP className="pt-4 text-center">
                              No hay planes disponibles
                            </RegularP>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <StripeGuaranty>
                    <Col xs={12} lg={12} id="pay">
                      <p>
                        <UilPadlock size={25} />
                        &nbsp;&nbsp;Pago garantizado y seguro.
                      </p>

                      <div id="stripe-g">
                        <p>Procesado por&nbsp;&nbsp;</p>
                        <img
                          alt="Stripe"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div id="card-logos">
                        <img
                          alt="mastercard"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                        />
                        <img
                          alt="visa"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                        />
                        <img
                          alt="amex"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                        />
                      </div>
                    </Col>
                  </StripeGuaranty>

                  <br />
                  {loading ? <Loader show={loading} /> : null}
                </InvertedRadius>
              </Col>
            </Row>
          </DataForm>
        </>
      )}

      {/* MSI MODAL */}
      <StyledModal
        show={showMsiMessage}
        onHide={() => setShowMsiMessage(!showMsiMessage)}
      >
        <StyledModal.Header closeButton>
          <StyledModal.Title>
            ¡Meses sin intereses disponibles!
          </StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body className="text-start align-items-start">
          <p className="text-start">
            Los siguientes estudios cuentan con meses sin intereses:
          </p>
          <div className="study-list">
            <ul>
              {msiItems.map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
          {normalItems.length > 0 && (
            <>
              <p>Se realizarán dos cargos a tu tarjeta:</p>
              <ul className="mb-3">
                <li>El primero para pagar los estudios a MSI</li>
                <li>
                  El segudo para pagar los estudios que NO tienen MSI
                  disponibles
                </li>
              </ul>
            </>
          )}
          <button
            className="confirm-btn d-flex align-self-center justify-content-center"
            onClick={() => setShowMsiMessage(!showMsiMessage)}
          >
            <b>Continuar</b>
          </button>
        </StyledModal.Body>
      </StyledModal>
      {/* ERROR MODAL */}
      <StyledModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
      >
        <StyledModal.Header>
          <StyledModal.Title>Ups!... Ocurrió un problema</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body className="text-start align-items-start">
          <div id="payError">{errorModalText}</div>
          <button
            className="confirm-btn d-flex align-self-center justify-content-center"
            onClick={() => setShowErrorModal(false)}
          >
            <b>Entendido</b>
          </button>
        </StyledModal.Body>
      </StyledModal>
      <br />
    </StyledForDesktop>
  );
};
