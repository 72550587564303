import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  NavLink,
} from "react-router-dom";
import { login } from "../actions/auth";
import { NavBar } from "../components/navbar/navbar";
// Components
import { Buscador } from "../containers/Buscador";
import { Main } from "../containers/categories/Main";
import { Checkout } from "../components/checkout/Checkout";
import { CheckoutP } from "../components/checkout/CheckoutP";
import { Contact } from "../containers/Contact";
import { Home } from "../containers/home";
import { Login } from "../containers/Login";
import { Register } from "../containers/Register";
import { Password } from "../containers/Password";
import { Profile } from "../containers/Profile";
import { getUserInfo, userInfo } from "../actions/user";
import { MedicalRecord } from "../components/profile-menu/MedicalRecord";
import { EditPersonalData } from "../components/profile-menu/EditPersonalData";
import { BranchesM } from "../components/doctors/seccionmed/BranchesM";
import { Indications } from "../components/profile-menu/Indications";
import { RecordDetail } from "../components/profile-menu/RecordDetail";
import { Footer } from "../components/footer/Footer";
import { StudyDetail } from "../components/studies/studyDetail";
import { checkCart } from "../actions/shoppingCart";
import { Branches } from "../components/branches/Branches";
import { Branch } from "../components/branches/Branch";
import { Doctors } from "../components/doctors/Doctors";
import { MainCheckin } from "../components/check-in/Main";
import { Packs } from "../components/packages/Packs";
import { MyPackages } from "../components/profile-menu/MyPackages";
import { CompleteAppointment } from "../components/complete-appointment/CompleteAppointment";
import { CotizacionConfirmation } from "../components/checkout/CotizacionConfirmation";
import { Patients } from "../components/patients/Patients";
import { PatientDetail } from "../components/patients/patientDetail";
import { QualityForm } from "../components/qualityForm/QualityForm";
import { QualityAx } from "../components/qualityForm/QualityAx";
import { QualityFormIkea } from "../components/qualityForm/QualityFormIkea";
import { RootContainer } from "../components/root/rootContainer";
import { BlogRoutes, PrivRoutes } from "./routes";
import { SideNav } from "../components/sidenav/SideNav";
import { Results } from "../components/results/Results";
import { NotFound } from "../components/not-found/NotFound";
import { Dashboard } from "../components/dashboard/Dashboard";
import { SideNavContainer } from "../components/sidenav/sideNavContainer";
import { PromoEnded } from "../components/promoEnded/promoEnded";
import { ProgramaTransforma } from "../components/programa-transforma/ProgramaTransforma";
import { CursoAltaEspecialidad } from "../components/curso-alta-especialidad/CursoAltaEspecialidad";
import useWindowDimensions from "../hooks/useWindowDimensions";
import AboutUs from "../components/aboutUs/aboutUs";
import October from "../components/october/October";
import Aguascalientes from "../components/aguascalientes/Aguascalientes";
import { Blog } from "../components/blog/blog/Blog";
import { Categories } from "../components/blog/categories/categories";
import { CategoryDetail } from "../components/blog/categoryDetail/categoryDetail";
import { CreatePost } from "../components/blog/createPost/createPost";
import { Post } from "../components/blog/post/post";
import { NavbarBlog } from "../components/blog/navbar/navbar";
import { EditPost } from "../components/blog/createPost/editPost";
import { AdminBlog } from "../components/blog/adminBlog/AdminBlog";
import { PublicRoute } from "./PublicRoute";
import { SuggestionsForm } from "../components/qualityForm/SuggestionsForm";
//secciones doctores
const loading = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2Floading.gif?alt=media&token=629f78d3-10a2-44f9-9143-668f5bc19ace";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const [checking, setsChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuMb, setShowMenuMb] = useState(false);
  const [opened, setOpened] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [showSearcher, setShowSearcher] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showCartSideBar, setShowCartSideBar] = useState(false);
  const location = useLocation();
  const user = useSelector((state) => state?.user?.data.data);
  const { width } = useWindowDimensions();
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName));
        setIsLoggedIn(true);
        let data = await getUserInfo(user?.uid);
        dispatch(
          checkCart(
            data?.data?.profile?.idClienteLabopat
              ? data?.data?.profile?.idClienteLabopat
              : 0
          )
        );
        if (data?.data?.profile?.idPaciente) {
          dispatch(userInfo(data));
        } else {
          dispatch(userInfo(data));
        }
        //
      } else {
        dispatch(checkCart(0));
        setIsLoggedIn(false);
      }
      setsChecking(false);
    });
  }, [dispatch, setsChecking]);
  useEffect(() => {
    PrivRoutes.includes(location.pathname) ||
    location.pathname?.includes("editar-post")
      ? setShowNav(false)
      : location.pathname.includes("/paciente/") ||
        location.pathname.includes("/editar-paquete/")
      ? setShowNav(false)
      : setShowNav(true);
  }, [location]);
  const checkIfBlogPath = () => {
    let blogPath = BlogRoutes.some((bRoute) =>
      location.pathname.includes(bRoute)
    );
    return blogPath;
  };
  if (checking) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <img src={loading} alt="loading" width={75} />
      </div>
    );
  }
  if (window.location.pathname === "/estudio/11") {
    window.location.replace(
      "/estudio/panel-respiratorio-viral-completo-por-pcr"
    );
  }
  return (
    <>
      <RootContainer showNav={showNav ? 0 : 1}>
        {checkIfBlogPath() ? (
          <NavbarBlog />
        ) : showNav ? (
          <NavBar
            showSearcher={showSearcher}
            setShowSearcher={(e) => setShowSearcher(e)}
            showCart={showCart}
            setShowCart={(e) => setShowCart(e)}
            showMenu={showMenu}
            setShowMenu={(e) => setShowMenu(e)}
            showMenuMb={showMenuMb}
            setShowMenuMb={(e) => setShowMenuMb(e)}
            opened={opened}
            setOpened={(e) => setOpened(e)}
          />
        ) : (
          <>
            <SideNavContainer>
              <SideNav
                showSearcher={showSearcher}
                setShowSearcher={(e) => setShowSearcher(e)}
                showCartSideBar={showCartSideBar}
                setShowCartSideBar={(e) => setShowCartSideBar(e)}
              />
            </SideNavContainer>
          </>
        )}
        <div style={showNav ? {} : { flex: 1, flexDirection: "column" }}>
          <Switch>
            <Route exact path="/">
              <Home
                setShowMenu={(e) => {
                  if (width > 900) {
                    setShowMenu(e);
                  } else {
                    setShowMenuMb(e);
                    setOpened(true);
                  }
                }}
                setShowSearcher={setShowSearcher}
                setShowCart={setShowCart}
              />
            </Route>
            {/* <Route path='/facturacigiton' />
            <Route path='/promociones' />
            <Route path='/membresias' /> 
            <Route path='*' component={NotFound} /> */}
            {/* ----- PUBLIC ROUTES ----- */}
            <Route path="/checkout" component={Checkout} />
            <Route path="/checkoutp" component={CheckoutP} />
            <Route path="/sucursales/:name_branch" component={Branch} />
            <Route path="/sucursales" component={Branches} />
            <Route path="/sucursalesM" component={BranchesM} />
            {/* <Route path='/plaza_palmas' component={Branch}/> */}
            <Route path="/medicos" component={Doctors} />
            <Route path="/nosotros" component={AboutUs} />
            <Route path="/formulario-calidad" component={QualityForm} />
            <Route path="/quejas-y-sugerencias" component={SuggestionsForm} />
            <Route path="/formulario-axiona" component={QualityAx} />
            <Route
              path="/formulario-calidad-ikea"
              component={QualityFormIkea}
            />
            <Route path="/estudio/:studyId" component={StudyDetail} />
            <Route path="/buscador" component={Buscador} />
            <Route path="/completar-cita" component={CompleteAppointment} />
            <Route path="/contacto" component={Contact} />
            <Route path="/programa-transforma">
              <ProgramaTransforma setShowCart={(e) => setShowCart(e)} />
            </Route>
            <Route path="/mujer" component={PromoEnded} />
            <Route path="/password" component={Password} />
            <Route
              path="/curso-alta-especialidad"
              component={CursoAltaEspecialidad}
            />
            <Route path="/checkin" component={MainCheckin} />
            <Route path="/estudiosrosas" render={() => <Redirect to="/" />} />
            <Route path="/aguascalientes" component={Aguascalientes} />

            {/* BLOG ROUTES */}
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/categorias" component={Categories} />
            <Route path="/blog/:category" component={CategoryDetail} />
            <Route path="/post/:slug" component={Post} />
            <Route exact path="/crear-post" component={CreatePost} />
            <Route exact path="/editar-post/:postId" component={EditPost} />
            <Route exact path="/admin-blog" component={AdminBlog} />
            <Route exact path="/cotizacion" component={CotizacionConfirmation} />
            {/* ----- AUTH ----- */}
            <Route path="/iniciar-sesion" component={Login} />
            <Route path="/registro" component={Register} />
            {/* ----- PRIVATE ROUTES ----- */}
            <Route
              exact
              path="/perfil"
              component={
                isLoggedIn
                  ? Profile
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            >
              {user?.profile?.type === 1 ? (
                <Redirect to="/pacientes" />
              ) : (
                <Redirect to="/resultados" />
              )}
            </Route>
            <Route
              exact
              path="/indicaciones"
              component={
                isLoggedIn
                  ? Indications
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              exact
              path="/detalle-historial"
              component={
                isLoggedIn
                  ? RecordDetail
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              exact
              path="/datos-personales"
              component={
                isLoggedIn
                  ? EditPersonalData
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/historial-medico"
              component={
                isLoggedIn
                  ? MedicalRecord
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/pacientes"
              render={() =>
                isLoggedIn ? (
                  <Patients setShowCart={() => setShowCartSideBar(true)} />
                ) : (
                  <Redirect to="/iniciar-sesion" />
                )
              }
            />
            <Route
              path="/paciente/:id"
              component={
                isLoggedIn
                  ? PatientDetail
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/paquetes"
              component={
                isLoggedIn
                  ? Packs
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/mis-paquetes"
              component={
                isLoggedIn
                  ? MyPackages
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/editar-paquete/:id"
              component={
                isLoggedIn
                  ? Packs
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/resultados"
              component={
                isLoggedIn
                  ? Results
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/dashboard"
              component={
                isLoggedIn
                  ? Dashboard
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route
              path="/sucursalesM"
              component={
                isLoggedIn
                  ? BranchesM
                  : () => {
                      window.location.href = "/iniciar-sesion";
                    }
              }
            />
            <Route path="/not-found" component={NotFound} />
            <Route path="/:service" component={Main} />
            <Redirect to="/" />
          </Switch>
          {showNav !== true ? (
            <Footer onDashboard={true} />
          ) : (
            <Footer onDashboard={false} />
          )}
        </div>
      </RootContainer>
    </>
  );
};