import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilEditAlt, UilTimes, UilMinusCircle } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PersonalForm } from "./personalForm";
import { Loader } from "../general/spinner";
import axios from "axios";
import { updateInfoUser } from "../../actions/user";
import { useDispatch } from "react-redux";
import Select from "react-select";
import e from "cors";
import { setPatientInfo } from "../../actions/patientInfo";
import { TrackGoogleAnalitycsEventWhitValue } from "../../analytics/google-analytics";

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  margin-top: 0px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const CheckText = styled.h3`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin: 0;
`;

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

const UserInfo = styled.div`
  position: relative;
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > #name {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 4px;
  }
  > p {
    margin-bottom: 0px;
  }
  > p:nth-child(6) {
    margin-bottom: 0px;
  }
  > svg {
    color: #00ad90;
    position: absolute;
    top: 12px;
    right: 16px;
  }
`;

const DataForm = styled.form`
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans";
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#00AD90")};
  }

  > div input.form-check-input:checked {
    background-color: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AD90")};
  }
  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const ContinueButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) =>
    props.isDoctor
      ? "#4D99E5"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%)"};
  border: 2px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
  border-radius: 10px !important;
  margin-top: 28px;

  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :hover {
    border: 2px solid ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
    transition: all 0.4s ease;
    background: #fff;
    color: ${(props) => (props.isDoctor ? "#4D99E5" : "#30DF99")};
  }
  :disabled {
    border: none;
    background: #e2e2e2;
    color: #a6a6a6;
  }
`;

export const StepThree = (props) => {
  const logged = useSelector((state) => state?.auth?.uid);
  const user = useSelector((state) => state.user?.data?.data);
  //console.log('el user', user);
  const [otherPersonCheck, setOtherPersonCheck] = useState(false);
  const [otherPerson, setOtherPerson] = useState();
  const dispatch = useDispatch();
  const [showAddNetwork, setShowAddNetwork] = useState(false);
  const [phone, setPhone] = useState();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isOrder = urlParams.get("doctor") === "1";
  var notForMe = urlParams.get("notForMe") === "1";
  var forPatient = urlParams?.get("forPatient") === "1";
  // const [selectedFile, setSelectedFile] = useState(null);
  const isDoctor = props.isDoctor;
  const pay = props.pay;
  // logged && !notForMe && !otherPersonCheck ? user
  const [selectValue, setSelectValue] = useState({
    value: "New",
    label: "Nuevo paciente",
  });
  const [state, setState] = useState(props.personalData);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState();
  const [currentPatient, setCurrentPatient] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [formValues, setFormValues] = useState({
    name: "",
    fathersLastname: "",
    mothersLastname: "",
    phone: "",
    email: "",
    day: "",
    month: "",
    year: "",
    gender: "",
  });

  const [validFormValues, setValidFormValues] = useState({
    name: true,
    fathersLastname: true,
    mothersLastname: true,
    phone: true,
    email: true,
    day: true,
    month: true,
    year: true,
    gender: true,
  });

  const {
    register,
    handleSubmit,
    control,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setState(props.personalData);
    console.log("personal data", props.personalData);
  }, [props.personalData]);

  //console.log(errors);
  const onSubmit = (data, event) => {
    //console.log(props.pay);
    let factura = parseInt(
      window.document?.querySelector(
        "input[type='radio'][name=necesitoFactura]:checked"
      )?.value
    );
    let genero = window.document?.querySelector(
      "input[type='radio'][name=gender]:checked"
    )?.value;
    let medico = window.document?.querySelector(
      "input[type='radio'][name=medicoTratante]:checked"
    )?.value;
    // console.log('data1: ', data);
    // console.log('factura on step3: ', factura, genero, medico);
    data.gender = genero;
    data.medicoTratante = medico;
    data.necesitoFactura = factura;

    // This variable is for other person, saving the sol id to take previous data in the backend
    if (state?.id > 0) {
      data.id = state.id;
    }
    //console.log('data2: ', data);
    //return;

    let myId =
      isOrder || otherPersonCheck
        ? currentPatient
          ? currentPatient.id
          : -1
        : logged
        ? user.profile.idPacienteLabopat
        : -1;

    handleClose();
    props.onStepThree({
      personalData: { ...data, idPacienteLabopat: myId },
      otherPersonCheck: otherPersonCheck,
      otherPerson: otherPerson,
      newNetwork: showAddNetwork,
    });

    /**
     * revisar que persona es y crear un solo objeto persona indicando si es red, nueva red o para mi
     */

    //setOtherPerson(Red[e.target.value - 1]);
    //setPersonalData(Red[e.target.value - 1]);
    //console.log('other person: ', otherPerson);
    //console.log('other person check: ', otherPersonCheck);
    //console.log('personal data: ', personalData);
    //console.log(data);
    //console.log(data.target.name.value);
    TrackGoogleAnalitycsEventWhitValue(
      "AgendaSucursal",
      "AgendaSucursal ",
      "agenda_pago_sucursal_exitoso",
      props.subTotalSuc
    );
    window.scroll({
      top: -10,
      behavior: "auto",
    });
  };
  //console.log(errors);

  const setStateAfterUpdate = (data) => {
    setState(data);
    if (data.idPacienteLabopat && !otherPersonCheck) {
      dispatch(updateInfoUser(data));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!validFormValues[name]) {
      setValidFormValues((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  const getMyPatients = async () => {
    try {
      setLoading(true);
      let requestPending = await axios.get(
        `https://lpqsystem.com/red-pacientes?uid=${logged}`
      );
      let requestPendingB = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${logged}`
      );
      // console.log(requestPending.data);
      //pasamos uid
      var tempPatients = [];
      requestPending?.data?.forEach((pat) => {
        tempPatients.push(pat);
      });
      const uniqueData = requestPendingB?.data?.reduce((unique, current) => {
        const isDuplicate = unique.some(
          (item) =>
            item?.nombre?.toLowerCase() === current?.nombre?.toLowerCase() &&
            item?.app?.toLowerCase() === current?.app?.toLowerCase() &&
            item?.apm?.toLowerCase() === current?.apm?.toLowerCase() &&
            item?.fnacimiento === current?.fnacimiento &&
            item?.emailContacto?.toLowerCase() ===
              current?.emailContacto?.toLowerCase() &&
            item?.telContacto?.toLowerCase() ===
              current?.telContacto?.toLowerCase()
        );

        if (!isDuplicate) {
          unique.push(current);
          //tempPatients.push(current);
        }

        return unique;
      }, []);
      uniqueData.forEach((u) => {
        tempPatients.push(u);
      });
      setPatients(tempPatients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (logged && isDoctor) {
      getMyPatients();
    }
    // if (logged) {
    //   getMyPatients(); // TODO: Change to get my network
    // }
  }, []);

  // Doctor creando una orden para sus pacientes
  useEffect(() => {
    if (isOrder) {
      if (currentPatient !== undefined) {
        setState({
          id: currentPatient?.id,
          name: currentPatient?.nombre,
          fathersLastname: currentPatient?.app,
          mothersLastname: currentPatient?.apm,
          phone: currentPatient?.telContacto,
          email: currentPatient?.emailContacto,
          birthday: currentPatient?.fnacimiento,
          gender: currentPatient?.genero,
        });
      } else {
        setState({
          id: "",
          name: "",
          fathersLastname: "",
          mothersLastname: "",
          phone: "",
          email: "",
          birthday: "",
          gender: "",
        });
        setFormValues({
          name: "",
          fathersLastname: "",
          mothersLastname: "",
          phone: "",
          email: "",
          day: "",
          month: "",
          year: "",
          gender: "",
        });
      }
    }
  }, [currentPatient]);

  // Usuario loggeado creando solicitud para alguien su red
  useEffect(() => {
    if (!notForMe && !isOrder) {
      // Que no se esté completando orden para alguien mas
      if (otherPersonCheck) {
        if (currentPatient !== undefined) {
          setState({
            id: currentPatient?.id,
            name: currentPatient?.nombre,
            fathersLastname: currentPatient?.app,
            mothersLastname: currentPatient?.apm,
            phone: currentPatient?.telContacto,
            email: currentPatient?.emailContacto,
            birthday: currentPatient?.fnacimiento,
            gender: currentPatient?.genero,
          });
        } else {
          setState({
            id: "",
            name: "",
            fathersLastname: "",
            mothersLastname: "",
            phone: "",
            email: "",
            birthday: "",
            gender: "",
          });
          setFormValues({
            name: "",
            fathersLastname: "",
            mothersLastname: "",
            phone: "",
            email: "",
            day: "",
            month: "",
            year: "",
            gender: "",
          });
        }
      } else {
        if (logged) {
          setState(user); // Cuando quita el check de otra persona, se usa la info del usuario
        }
      }
    }
  }, [currentPatient, otherPersonCheck]);

  let checkFields = async () => {
    let claves =
      pay === "orderToPatient" ? ["name", "email"] : Object.keys(formValues);
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const functionWithPromise = (clave) => {
      //a function that returns a promise
      if (clave !== "phone") {
        // El celular es opcional
        if (clave === "email") {
          if (!emailRegex.test(formValues[clave])) {
            // Formato del email
            setValidFormValues((prevState) => ({
              ...prevState,
              email: false,
            }));
            return Promise.resolve(false);
          } else {
            setValidFormValues((prevState) => ({
              ...prevState,
              [clave]: true,
            }));
            return Promise.resolve(true);
          }
        } else {
          if (clave === "day" || clave === "month" || clave === "year") {
            // Para verificar que sean números
            if (parseInt(formValues[clave]) > 0) {
              setValidFormValues((prevState) => ({
                ...prevState,
                [clave]: true,
              }));
              return Promise.resolve(true);
            } else {
              setValidFormValues((prevState) => ({
                ...prevState,
                [clave]: false,
              }));
              return Promise.resolve(false);
            }
          } else {
            if (!formValues[clave].length > 0) {
              // Campos obligatorios
              setValidFormValues((prevState) => ({
                ...prevState,
                [clave]: false,
              }));
              return Promise.resolve(false);
            } else {
              setValidFormValues((prevState) => ({
                ...prevState,
                [clave]: true,
              }));
              return Promise.resolve(true);
            }
          }
        }
      } else {
        return Promise.resolve(true);
      }
    };

    const anAsyncFunction = async (clave) => {
      return functionWithPromise(clave);
    };

    const getData = async () => {
      return Promise.all(claves.map((clave) => anAsyncFunction(clave)));
    };

    return getData().then((e) => e.some((e) => e === false));
  };

  let focusOnError = () => {
    let claves = Object.keys(formValues);
    for (let index = 0; index < claves.length; index++) {
      if (!validFormValues[claves[index]]) {
        // Si hay un error en el campo
        setFocus([claves[index]][0]);
        break;
      }
    }
  };

  useEffect(() => {
    if (otherPersonCheck) {
      setCurrentPatient(undefined);
      setValue("address", "");
    } else {
      setValue(
        "address",
        user?.address ? user?.address : state?.address ? state?.address : ""
      );
    }
  }, [otherPersonCheck]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Use when order for patient
    if (forPatient) {
      var patInfo = JSON.parse(localStorage?.getItem("patientInfo"));
      // If there is patient info in the local storage
      if (patInfo) {
        console.log(
          "🚀 ~ file: stepThree.jsx:711 ~ useEffect ~ patInfo:",
          patInfo
        );
        // Need to save in all of them to make it work :c
        dispatch(setPatientInfo(patInfo)); // Save to dispatch
        setValue("name", patInfo?.name); // Save to react hook form
        setValue("fathersLastname", patInfo?.fathersLastname ?? "");
        setValue("mothersLastname", patInfo?.mothersLastname ?? "");
        setValue("phone", patInfo?.phone ?? "+52");
        setValue("email", patInfo?.email);
        setValue("year", getFromDate("year", patInfo?.fnacimiento) ?? "");
        setValue("month", getFromDate("month", patInfo?.fnacimiento) ?? "");
        setValue("day", getFromDate("day", patInfo?.fnacimiento) ?? "");
        setValue("gender", patInfo?.gender ?? "");
        setState({
          name: patInfo?.name ?? "",
          fathersLastname: patInfo?.fathersLastname ?? "",
          mothersLastname: patInfo?.mothersLastname ?? "",
          email: patInfo?.email ?? "",
          phone: patInfo?.phone ?? "+52",
          day: getFromDate("day", patInfo?.fnacimiento) ?? "",
          month: getFromDate("month", patInfo?.fnacimiento) ?? "",
          year: getFromDate("year", patInfo?.fnacimiento) ?? "",
          gender: patInfo?.gender ?? "",
        }); // Save to state
        setFormValues({
          ...formValues,
          name: patInfo?.name ?? "",
          fathersLastname: patInfo?.fathersLastname ?? "",
          mothersLastname: patInfo?.mothersLastname ?? "",
          email: patInfo?.email ?? "",
          phone: patInfo?.phone ?? "+52",
          day: getFromDate("day", patInfo?.fnacimiento) ?? "",
          month: getFromDate("month", patInfo?.fnacimiento) ?? "",
          year: getFromDate("year", patInfo?.fnacimiento) ?? "",
          gender: patInfo?.gender ?? "",
        }); // Save to formValues (for validation)
      }
    }
    setValue(
      "address",
      user?.address ? user?.address : state?.address ? state?.address : ""
    );
  }, []);

  const getFromDate = (fragment, dateString) => {
    // To get format YYYY-MM-DD or DD-MM-YYYY
    const yearFirst = isNaN(dateString?.substring(4, 5));
    // Returns in fuction of the part of the date needed
    if (fragment === "year") {
      return yearFirst ? dateString?.substring(0, 4) : dateString?.substring(6);
    } else if (fragment === "month") {
      return yearFirst
        ? dateString?.substring(5, 7)
        : dateString?.substring(3, 5);
    } else {
      return yearFirst ? dateString?.substring(8) : dateString?.substring(0, 2);
    }
  };

  useEffect(() => {
    if (forPatient) {
      var patInfo = JSON.parse(localStorage?.getItem("patientInfo"));
      if (patInfo && patients?.length >= 1) {
        patients?.map((e, index) => {
          if (e.id === patInfo?.id) {
            setSelectValue({
              value: index,
              label: `${e.id} ${e.nombre} ${e.app} ${e.apm}  ${e.fnacimiento}`,
            });
          }
        });
      }
    }
  }, [patients]);

  return (
    <StyledForDesktop>
      <DataForm
        onSubmit={handleSubmit(() => {
          console.log("Continue button click");
          if (
            ((isOrder || otherPersonCheck) && currentPatient === undefined) ||
            (otherPersonCheck && currentPatient === undefined)
          ) {
            checkFields().then((e) => {
              console.log("Hay errores?", e ? "Sí" : "No");
              if (!e) {
                //handleShow();
                if (pay === "orderToPatient") {
                  console.log("no  lol 1");
                  onSubmit(state);
                } else {
                  console.log("lol - 1");
                  handleShow();
                }
              } else {
                focusOnError();
              }
            });
          } else {
            //handleShow();
            if (pay === "orderToPatient") {
              console.log("no  lol 2");
              onSubmit(state);
            } else {
              console.log("lol - 2");
              handleShow();
            }
          }
        })}
        autoComplete="off"
        isDoctor={isDoctor}
      >
        {logged && pay !== "orderToPatient" ? (
          // Solicitud cuando está logeado y no es orden para paciente, ¿tendría 2 grupos (la red de personas y sus pacientes)?
          <>
            {/* ------ Logged user ----- */}
            <Title>Confirme los datos personales del paciente</Title>
            {logged && !isOrder && !notForMe ? (
              <Form.Check
                type="checkbox"
                id="otherPerson"
                className="d-flex flex-row align-items-center justify-content-end"
              >
                <Form.Check.Label className="me-5">
                  <CheckText>Los estudios no son para mí</CheckText>
                </Form.Check.Label>
                <Form.Check.Input
                  checked={otherPersonCheck}
                  onChange={() => setOtherPersonCheck(!otherPersonCheck)}
                />
              </Form.Check>
            ) : (
              <></>
            )}
            {otherPersonCheck ? (
              <>
                <>
                  <RegularP>
                    Elige y confirma los datos de la persona que se realizará
                    los estudios.
                  </RegularP>
                  {patients?.length >= 1 && (
                    <Select
                      className="basic-single"
                      classNamePrefix="Nuevo paciente"
                      // defaultValue={{value:'New', label: "Nuevo paciente"}}
                      // isLoading={isLoading}
                      isClearable
                      isSearchable
                      name="color"
                      options={[
                        { value: "New", label: "Nuevo paciente" },
                        ...patients.map((patient, index) => ({
                          value: index,
                          label: `${patient.id} ${patient.nombre} ${patient.app} ${patient.apm}  ${patient.fnacimiento}`,
                        })),
                      ]}
                      value={selectValue}
                      onChange={(e, { action }) => {
                        console.log(e);
                        console.log(action);
                        if (e !== null && e?.value !== "New") {
                          setSelectValue({
                            value: e?.value,
                            label: `${patients[e?.value]?.id} ${
                              patients[e?.value]?.nombre
                            } ${patients[e?.value]?.app} ${
                              patients[e?.value]?.apm
                            }  ${patients[e?.value]?.fnacimiento}`,
                          });
                          console.log(patients[e.value]);
                          if (
                            patients[e.value]?.idPacienteLabopat &&
                            patients[e.value]?.idPacienteLabopat !== 0
                          ) {
                            setCurrentPatient(patients[e.value]);
                          } else {
                            setCurrentPatient(undefined);
                            // Save to react hook form
                            setValue("name", patients[e.value]?.nombre);
                            setValue(
                              "fathersLastname",
                              patients[e.value]?.app ?? ""
                            );
                            setValue(
                              "mothersLastname",
                              patients[e.value]?.apm ?? ""
                            );
                            setValue(
                              "phone",
                              patients[e.value]?.telContacto &&
                                patients[e.value]?.telContacto !== "undefined"
                                ? patients[e.value]?.telContacto
                                : "+52"
                            );
                            setValue("email", patients[e.value]?.emailContacto);
                            setValue(
                              "year",
                              getFromDate(
                                "year",
                                patients[e.value]?.fnacimiento
                              ) ?? ""
                            );
                            setValue(
                              "month",
                              getFromDate(
                                "month",
                                patients[e.value]?.fnacimiento
                              ) ?? ""
                            );
                            setValue(
                              "day",
                              getFromDate(
                                "day",
                                patients[e.value]?.fnacimiento
                              ) ?? ""
                            );
                            setValue("gender", patients[e.value]?.gender ?? "");
                            setState({
                              // Save to state
                              id: patients[e.value]?.id ?? "",
                              name: patients[e.value]?.nombre ?? "",
                              fathersLastname: patients[e.value]?.app ?? "",
                              mothersLastname: patients[e.value]?.apm ?? "",
                              email: patients[e.value]?.emailContacto ?? "",
                              phone:
                                patients[e.value]?.telContacto &&
                                patients[e.value]?.telContacto !== "undefined"
                                  ? patients[e.value]?.telContacto
                                  : "+52",
                              day:
                                getFromDate(
                                  "day",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              month:
                                getFromDate(
                                  "month",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              year:
                                getFromDate(
                                  "year",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              gender: patients[e.value]?.gender ?? "",
                            });
                            setFormValues({
                              // Save to formValues (for validation)
                              ...formValues,
                              name: patients[e.value]?.nombre ?? "",
                              fathersLastname: patients[e.value]?.app ?? "",
                              mothersLastname: patients[e.value]?.apm ?? "",
                              email: patients[e.value]?.emailContacto ?? "",
                              phone:
                                patients[e.value]?.telContacto &&
                                patients[e.value]?.telContacto !== "undefined"
                                  ? patients[e.value]?.telContacto
                                  : "+52",
                              day:
                                getFromDate(
                                  "day",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              month:
                                getFromDate(
                                  "month",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              year:
                                getFromDate(
                                  "year",
                                  patients[e.value]?.fnacimiento
                                ) ?? "",
                              gender: patients[e.value]?.gender ?? "",
                            });
                          }
                        } else {
                          setCurrentPatient(undefined);
                          setState((old) => {
                            return { ...old, id: undefined };
                          });
                          setSelectValue({
                            value: "New",
                            label: "Nuevo paciente",
                          });
                          // checkFields();
                        }
                      }}
                      id="my-select"
                    />
                  )}
                  <PersonalForm
                    register={register}
                    state={state}
                    user={user}
                    handleChange={handleChange}
                    control={control}
                    currentPatient={currentPatient}
                    otherPersonCheck={otherPersonCheck}
                    setStateAfterUpdate={setStateAfterUpdate}
                    validFormValues={validFormValues}
                  />
                </>

                {otherPerson ? (
                  <>
                    <UserInfo>
                      <p id="name">{otherPerson.name}</p>
                      <p>
                        Expediente{" "}
                        {otherPerson.age
                          ? otherPerson.age
                          : "Expediente no registrado"}
                      </p>
                      <p>
                        {otherPerson.age
                          ? `${otherPerson.age} años`
                          : "Edad no registrada"}
                      </p>
                      <br />
                      <p>
                        {otherPerson.phone
                          ? otherPerson.phone
                          : "Teléfono no registrado"}
                      </p>
                      <p>
                        {otherPerson.email
                          ? otherPerson.email
                          : "Email no registrado"}
                      </p>
                      <UilMinusCircle
                        style={{ cursor: "pointer" }}
                        // onClick={() => eraseDataOtherUser()}
                      />
                    </UserInfo>
                  </>
                ) : null}
              </>
            ) : null}

            {!otherPersonCheck && user?.record ? (
              <>
                <RegularP>Confirma tus datos</RegularP>
                <UserInfo>
                  <p id="name">{user.name}</p>
                  <p>
                    {user?.record
                      ? `Expediente ${user?.record}`
                      : "Expediente no registrado"}
                  </p>
                  <p>
                    {user?.age ? `${user?.age} años` : "Edad no registrada"}
                  </p>
                  <br />
                  <p>{user?.phone ? user?.phone : "Teléfono no registrado"}</p>
                  <p>{user?.email ? user?.email : "Email no registrado"}</p>
                  <UilEditAlt />
                </UserInfo>
                <hr />
              </>
            ) : !otherPersonCheck ? (
              <PersonalForm
                register={register}
                state={state}
                user={user}
                handleChange={handleChange}
                control={control}
                setStateAfterUpdate={setStateAfterUpdate}
                validFormValues={validFormValues}
              />
            ) : null}
          </>
        ) : !logged ? (
          <>
            {/* ------ Not logged user ----- */}
            <Title>Ingresa tus datos para crear tu expediente digital.</Title>
            <br />
            <PersonalForm
              register={register}
              state={state}
              user={user}
              handleChange={handleChange}
              control={control}
              setStateAfterUpdate={setStateAfterUpdate}
              validFormValues={validFormValues}
            />
          </>
        ) : null}
        {logged && isDoctor && pay === "orderToPatient" ? (
          <>
            {/* ------ Logged Doctor ----- */}
            <Title>Solicitud médica</Title>
            {patients?.length >= 1 && (
              <Select
                className="basic-single"
                classNamePrefix="Nuevo paciente"
                // defaultValue={{value:'New', label: "Nuevo paciente"}}
                // isLoading={isLoading}
                isClearable
                isSearchable
                name="color"
                options={[
                  { value: "New", label: "Nuevo paciente" },
                  ...patients.map((patient, index) => ({
                    value: index,
                    label: `${patient.id} ${patient.nombre} ${patient.app} ${patient.apm}  ${patient.fnacimiento}`,
                  })),
                ]}
                value={selectValue}
                onChange={(e, { action }) => {
                  console.log(e);
                  console.log(action);
                  if (e !== null && e?.value !== "New") {
                    setSelectValue({
                      value: e?.value,
                      label: `${patients[e?.value]?.id} ${
                        patients[e?.value]?.nombre
                      } ${patients[e?.value]?.app} ${
                        patients[e?.value]?.apm
                      }  ${patients[e?.value]?.fnacimiento}`,
                    });
                    console.log(patients[e.value]);
                    if (
                      patients[e.value]?.idPacienteLabopat &&
                      patients[e.value]?.idPacienteLabopat !== 0
                    ) {
                      setCurrentPatient(patients[e.value]);
                    } else {
                      setCurrentPatient(undefined);
                      // Save to react hook form
                      setValue("name", patients[e.value]?.nombre);
                      setValue("fathersLastname", patients[e.value]?.app ?? "");
                      setValue("mothersLastname", patients[e.value]?.apm ?? "");
                      setValue(
                        "phone",
                        patients[e.value]?.telContacto &&
                          patients[e.value]?.telContacto !== "undefined"
                          ? patients[e.value]?.telContacto
                          : "+52"
                      );
                      setValue("email", patients[e.value]?.emailContacto);
                      setValue(
                        "year",
                        getFromDate("year", patients[e.value]?.fnacimiento) ??
                          ""
                      );
                      setValue(
                        "month",
                        getFromDate("month", patients[e.value]?.fnacimiento) ??
                          ""
                      );
                      setValue(
                        "day",
                        getFromDate("day", patients[e.value]?.fnacimiento) ?? ""
                      );
                      setValue("gender", patients[e.value]?.gender ?? "");
                      setState({
                        // Save to state
                        id: patients[e.value]?.id ?? "",
                        name: patients[e.value]?.nombre ?? "",
                        fathersLastname: patients[e.value]?.app ?? "",
                        mothersLastname: patients[e.value]?.apm ?? "",
                        email: patients[e.value]?.emailContacto ?? "",
                        phone:
                          patients[e.value]?.telContacto &&
                          patients[e.value]?.telContacto !== "undefined"
                            ? patients[e.value]?.telContacto
                            : "+52",
                        day:
                          getFromDate("day", patients[e.value]?.fnacimiento) ??
                          "",
                        month:
                          getFromDate(
                            "month",
                            patients[e.value]?.fnacimiento
                          ) ?? "",
                        year:
                          getFromDate("year", patients[e.value]?.fnacimiento) ??
                          "",
                        gender: patients[e.value]?.gender ?? "",
                      });
                      setFormValues({
                        // Save to formValues (for validation)
                        ...formValues,
                        name: patients[e.value]?.nombre ?? "",
                        fathersLastname: patients[e.value]?.app ?? "",
                        mothersLastname: patients[e.value]?.apm ?? "",
                        email: patients[e.value]?.emailContacto ?? "",
                        phone:
                          patients[e.value]?.telContacto &&
                          patients[e.value]?.telContacto !== "undefined"
                            ? patients[e.value]?.telContacto
                            : "+52",
                        day:
                          getFromDate("day", patients[e.value]?.fnacimiento) ??
                          "",
                        month:
                          getFromDate(
                            "month",
                            patients[e.value]?.fnacimiento
                          ) ?? "",
                        year:
                          getFromDate("year", patients[e.value]?.fnacimiento) ??
                          "",
                        gender: patients[e.value]?.gender ?? "",
                      });
                    }
                  } else {
                    setCurrentPatient(undefined);
                    setState((old) => {
                      return { ...old, id: undefined };
                    });
                    setSelectValue({ value: "New", label: "Nuevo paciente" });
                    // checkFields();
                  }
                }}
                id="my-select"
              />
            )}
            <PersonalForm
              register={register}
              state={state}
              user={{ doctor: true }}
              handleChange={handleChange}
              orderToPatient={true}
              control={control}
              currentPatient={currentPatient}
              setStateAfterUpdate={setStateAfterUpdate}
              validFormValues={validFormValues}
              pay={pay}
            />
          </>
        ) : null}

        <Row className="justify-content-center">
          {logged &&
            !isOrder &&
            !notForMe &&
            !otherPersonCheck &&
            !user?.profile.idPacienteLabopat && (
              <p>Necesitas completar tu perfil para continuar</p>
            )}
          <ContinueButton
            type="submit"
            disabled={
              logged &&
              !isOrder &&
              !notForMe &&
              !otherPersonCheck &&
              !user?.profile.idPacienteLabopat
            }
            isDoctor={isDoctor}
            onClick={handleSubmit(onSubmit)}
            // onClick={handleSubmit(handleShow)}
          >
            Continuar
          </ContinueButton>
          {/*<StyledModal show={show} onHide={handleClose}>
            <div onClick={handleClose}>
              <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Verifica tus datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Antes de continuar revisa que todos tus datos sean correctos
              </p>
              <ContinueButton onClick={handleSubmit(onSubmit)}>
                Confirmar
              </ContinueButton>
              <WhiteButton variant='light' onClick={handleClose}>
                Ver datos
              </WhiteButton>
            </Modal.Body>
          </StyledModal>*/}
        </Row>
      </DataForm>
      {loading ? <Loader show={loading} /> : null}
    </StyledForDesktop>
  );
};
