import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Drawer } from "antd";
import { Row, Col, Modal, Button } from "react-bootstrap";
import {
  UilArrowRight,
  UilClipboardAlt,
  UilTimes,
  UilSearch,
  UilTrashAlt,
  UilInvoice,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { ListItemCart } from "./listItemCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { updateCartDB } from "../../helpers/api";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { UIDsHome } from "../../helpers/cart";
import { PrivacyModal } from "../footer/privacyModal";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 476px;
  }
  .ant-drawer-title {
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: ${(props) => props.mainColor};
  }
  .ant-drawer-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props) => props.mainColor};
  }
`;
const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;
const WhiteButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: ${(props) => props.mainColor};
  border: 2px solid ${(props) => props.mainColor};
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;
const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 35px;
  background: none;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const OrangeButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: -25px;
  width: 87%;
  padding-top: 0px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;
const ListDiv = styled.div`
  overflow-y: auto;
  padding-right: 7px;
  margin-right: -5px;
  max-height: 38vh;
  border-bottom-style: groove;
  @media only screen and (max-width: 1600px) {
    max-height: 16vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 15vh;
  }
  @media only screen and (max-width: 576px) {
    max-height: 28vh;
  }
  @media only screen and (max-width: 376px) {
    max-height: 15vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const CardDiv = styled.div`
  padding: 7px;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #0cac8c;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-right: -5px;
  max-height: 40vh;
`;
const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 40px;
  }
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.mainColor};
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${(props) => props.mainColor};
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;
const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

const ContainerTotalPayBranch = styled.div`
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  flex-direction: column;
  align-items: center;
  span {
    color: #474a4b9e;
    font-size: 16px;
    font-weight: 600;
    @media only screen and (max-width: 1600px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 376px) {
      font-size: 14px;
    }
  }
`;

const Cotizacion = styled.div`
  text-align: center;
  p {
    font-size: 11px;
  }
  span {
    cursor: pointer;
    color: #0cac8c;
    text-decoration: underline;
    font-size: 11px;
  }
  @media only screen and (max-width: 1600px) {
    p {
      font-size: 10px;
    }
    span {
      font-size: 10px;
    }
  }
`;

const CotizacionButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 200px;
  height: 35px;
  border-radius: 10px;
  opacity: 0.800000011920929;
  background: #474a4b;
  transition: all ease 0.4s;
  border: none;
  margin: 0px auto;
  @media only screen and (max-width: 1600px) {
    width: 180px;
    height: 30px;
    line-height: 18px;
    font-size: 14px;
  }
  :focus {
    background: #474a4b;
  }
  :hover {
    background: #474a4b;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const PayButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 200px;
  height: 35px;
  border-radius: 10px;
  opacity: 0.800000011920929;
  background: #f9ad62;
  transition: all ease 0.4s;
  border: none;
  margin: 8px auto;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 1600px) {
    width: 180px;
    height: 30px;
    line-height: 18px;
    font-size: 14px;
  }
  :focus {
    background: #f9ad62;
  }
  :hover {
    background: #f9ad62;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const Prices = styled(Row)`
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start;
  justify-content: left;
  flex-direction: row;
  p {
    width: auto;
    font-family: "IBM Plex Sans";
    padding-left: 0px;
    padding-right: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }
  #descuento {
    font-weight: 500;
    font-size: 14px;
    font-style: italic;

    letter-spacing: -0.03em;
    color: #474a4bb8;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1600px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 8px;
      padding-left: 0px;
    }
  }
`;

export const Cart = (props) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const uid = useSelector((state) => state.auth.uid);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [totalSucursal, setTotalSucursal] = useState();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const [notForMe, setNotForMe] = useState(false);
  var order = urlParams.get("order");
  const [patientId, setPatientId] = useState(0);
  const [showCallModal, setShowCallModal] = useState(false);
  var isOrder = urlParams.get("doctor");
  const location = useLocation();
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  let isMobile = width <= 768;
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  useEffect(() => {
    getTotal();
  }, [items]);
  const getTotal = () => {
    if (idClienteLabopat && idClienteLabopat != 0) {
      const pricesArray = items?.map((item) => item.price);
      const final = pricesArray.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      setTotalSucursal(final);
    } else {
      const pricesArray = items?.map((item) => item.oldPrice);
      const final = pricesArray.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      setTotalSucursal(final);
    }
  };
  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);
    // Update cart
    dispatch(removeItemFromCart(data));
    // Update total price
    dispatch(setTotalPrice(price));
    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };
  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };
  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };
  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));
    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
    props.onClose();
  };
  return (
    <StyledDrawer
      headerStyle={{
        fontFamily: "IBM Plex Sans",
        background: "#f5fffb",
      }}
      title="Estudios agregados"
      placement="right"
      closeIcon={
        <UilArrowRight
          style={{
            position: "initial",
            marginLeft: width < 500 ? "-10px" : "0px",
          }}
          color={"#fff"}
          size={30}
        />
      }
      onClose={props.onClose}
      open={props.visible}
      mainColor={mainColor}
    >
      {items?.length > 0 ? (
        <div style={{ position: "relative", paddingBottom: "150px" }}>
          {isMobile ? (
            <>
              <Row>
                <Col md={6}>
                  <WhiteButton1
                    style={{
                      color: "#fff",
                      border: isDoctor
                        ? "1px solid #5097E3"
                        : "1px solid #30B196",
                      background: isDoctor ? "#5097E3" : "#30B196",
                    }}
                    onClick={() => {
                      props.onClose();
                      props.openSearcher();
                    }}
                  >
                    <UilSearch size={20} />
                    Buscar estudios
                  </WhiteButton1>
                  <br />
                  <br />
                  <WhiteButton1
                    style={{
                      color: isDoctor ? "#5097E3" : "rgb(0, 172, 144)",
                      border: isDoctor
                        ? "2px solid #5097E3"
                        : "2px solid rgb(0, 172, 144)",
                    }}
                    onClick={vaciarCarrito}
                  >
                    <UilTrashAlt size={20} />
                    Vaciar carrito
                  </WhiteButton1>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <Col lg={6} style={{ padding: "2px" }}>
                  <WhiteButton1
                    style={{
                      color: "#fff",
                      border: isDoctor
                        ? "1px solid #5097E3"
                        : "1px solid #30B196",
                      background: isDoctor ? "#5097E3" : "#30B196",
                    }}
                    onClick={() => {
                      props.onClose();
                      props.openSearcher();
                    }}
                  >
                    <UilSearch size={20} />
                    Buscar estudios
                  </WhiteButton1>
                </Col>
                <Col lg={6} style={{ padding: "2px" }}>
                  <WhiteButton1
                    style={{
                      color: isDoctor ? "#5097E3" : "rgb(0, 172, 144)",
                      border: isDoctor
                        ? "2px solid #5097E3"
                        : "2px solid #30B196",
                    }}
                    onClick={vaciarCarrito}
                  >
                    <UilTrashAlt size={20} />
                    Vaciar carrito
                  </WhiteButton1>
                </Col>
              </div>
            </>
          )}
          <br />
          <ListDiv>
            {items.map((item) => (
              <>
                <CardDiv>
                  <ListItemCart
                    key={item.id}
                    item={item}
                    mainColor={mainColor}
                    onDelete={handleRemoveItemFromCart}
                    idClienteLabopat={idClienteLabopat}
                  />
                </CardDiv>
                <hr />
              </>
            ))}
          </ListDiv>
          <br />
        </div>
      ) : (
        <EmptyCart>
          <div id="empty">
            <UilClipboardAlt color="#A6A6A6" size={100} />
          </div>
          <p>No hay estudios agregados</p>
          <WhiteButton
            mainColor={mainColor}
            onClick={() => {
              props.onClose();
              props.openSearcher();
            }}
          >
            Buscar estudios
          </WhiteButton>
        </EmptyCart>
      )}
      <Bottom minColor={mainColor}>
        {items?.length > 0 ? (
          <>
            {idClienteLabopat && idClienteLabopat !== 0 ? (
              <>
                <Total
                  mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"}
                >
                  Total a pagar: <span>{currency(totalSucursal)}</span>
                </Total>
                <br />
                <br />
              </>
            ) : (
              <>
                {isDoctor ? (
                  <></>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: isMobile ? "" : "0%",
                        marginTop: isMobile ? "" : "",
                      }}
                    >
                      <ContainerTotalPayBranch>
                        <p>
                          <UilInvoice />
                          <span>Total pago en Sucursal:</span>
                        </p>
                        <span>{currency(totalSucursal)} MXN</span>
                      </ContainerTotalPayBranch>
                      <CotizacionButton
                        onClick={() => {
                          if (someNotAvailableOnline()) {
                            setShowCallModal(true);
                          } else {
                            gaEventTracker("carrito-pago-sucursal");
                            window.location.href = "/checkoutp";
                            props.onClose();
                          }
                        }}
                      >
                        Generar cita
                      </CotizacionButton>
                      <br />
                      <ContainerTotalPayBranch>
                        <p>
                          <UilInvoice />
                          <span>Descuento pago en línea:</span>
                        </p>
                        <span>{currency(totalPrice)} MXN</span>
                      </ContainerTotalPayBranch>
                      <PayButton
                        onClick={() => {
                          if (someNotAvailableOnline()) {
                            setShowCallModal(true);
                          } else {
                            gaEventTracker("carrito-pago-online");
                            window.location.href = "/checkoutp";
                            props.onClose();
                          }
                        }}
                      >
                        Pagar ahora -10%
                      </PayButton>
                      <Prices>
                        {" "}
                        <p id="descuento">
                          {totalSucursal - totalPrice > 0 && (
                            <>
                              Ahorras: {currency(totalPrice - totalSucursal)}{" "}
                              MXN
                            </>
                          )}
                        </p>
                      </Prices>
                      <Modal.Body>
                        <Cotizacion
                          onClick={() => {
                            window.location.href = `/cotizacion`;
                            props.onClose();
                          }}
                          disabled={items?.length === 0}
                        >
                          <span>Generar cotización sin costo </span>

                          <p>
                            Al realizar tu pago en línea, aceptas el{" "}
                            <span onClick={() => setShowPrivacyModal(true)}>
                              aviso de privacidad
                            </span>{" "}
                            y las{" "}
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#0CAC8C",
                                textDecoration: "underline",
                              }}
                              onClick={() =>
                                (window.location.href =
                                  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FTerminos%20y%20condiciones%20.pdf?alt=media&token=f94e2aa8-9d69-45fd-ab0b-1073683e4111")
                              }
                            >
                              condiciones de uso
                            </span>{" "}
                            de Labopat. <br />
                            <br />
                            Todos los precios que se muestran en este sitio
                            corresponden a pesos mexicanos MXN.
                          </p>
                        </Cotizacion>
                      </Modal.Body>
                    </div>
                  </>
                )}
              </>
            )}
            {isDoctor && (
              <>
                {UIDsHome.includes(uid) ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: isMobile ? "" : "0%",
                      marginTop: isMobile ? "" : "",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: isMobile ? "" : "0%",
                        marginTop: isMobile ? "" : "",
                      }}
                    >
                      <ContainerTotalPayBranch>
                        <p>
                          <UilInvoice />
                          <span>Total pago en Sucursal:</span>
                        </p>
                        <span>{currency(totalSucursal)} MXN</span>
                      </ContainerTotalPayBranch>
                      <CotizacionButton
                        onClick={() => {
                          if (someNotAvailableOnline()) {
                            setShowCallModal(true);
                          } else {
                            gaEventTracker("carrito-pago-sucursal");
                            window.location.href = "/checkoutp";
                            props.onClose();
                          }
                        }}
                      >
                        Generar cita
                      </CotizacionButton>
                      <br />
                      <ContainerTotalPayBranch>
                        <p>
                          <UilInvoice />
                          <span>Descuento pago en línea:</span>
                        </p>
                        <span>{currency(totalPrice)} MXN</span>
                      </ContainerTotalPayBranch>
                      <PayButton
                        onClick={() => {
                          if (someNotAvailableOnline()) {
                            setShowCallModal(true);
                          } else {
                            gaEventTracker("carrito-pago-online");
                            window.location.href = "/checkoutp";
                            props.onClose();
                          }
                        }}
                      >
                        Pagar ahora -10%
                      </PayButton>
                      <Prices>
                        {" "}
                        <p id="descuento">
                          {totalSucursal - totalPrice > 0 && (
                            <>
                              Ahorras: {currency(totalPrice - totalSucursal)}{" "}
                              MXN
                            </>
                          )}
                        </p>
                      </Prices>
                    </div>
                    <CotizacionButton
                      onClick={() => {
                        window.location.href = "/checkout?sucursal=1";
                      }}
                    >
                      Generar cita anterior
                    </CotizacionButton>
                    <OrangeButton
                      id="genOrder"
                      onClick={() => {
                        window.location.href = "/cotizacion";
                      }}
                      mainColor={
                        mainColor === "#4F90BA" ? "#4D99E5" : "#30DF99"
                      }
                    >
                      Generar cotización
                    </OrangeButton>
                  </div>
                ) : (
                  <BlueButton
                    id="genOrder"
                    onClick={handleOrder}
                    mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"}
                  >
                    Genera una orden aquí
                  </BlueButton>
                )}
              </>
            )}
          </>
        ) : (
          isDoctor && (
            <p id="noItems">
              Para generar una orden{" "}
              <span>necesitas agregar al menos 1 estudio.</span>
            </p>
          )
        )}
      </Bottom>
      <StyledModal
        show={showCallModal}
        onHide={() => setShowCallModal(false)}
        mainColor={mainColor}
      >
        <div onClick={() => setShowCallModal(false)}>
          <UilTimes color="#fff" style={{ cursor: "pointer" }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>
            Estudios no disponibles para reserva en linea
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Los siguientes estudios solo se pueden agendar mediante llamada:
          </p>
          <ul>
            {items.map((study) => {
              if (
                study.categoria === 10 ||
                study.categoria === 8 ||
                study.ventaOnline === 0
              ) {
                return <li>{study.name}</li>;
              }
            })}
          </ul>
          <ContinueButton
            onClick={() => callToSchedule()}
            mainColor={mainColor}
          >
            Llamar para agendar
          </ContinueButton>
        </Modal.Body>
      </StyledModal>
      <PrivacyModal
        show={showPrivacyModal}
        hide={() => setShowPrivacyModal(false)}
      />
    </StyledDrawer>
  );
};
