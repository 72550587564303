import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AiFillHeart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { studyInCart } from '../../helpers/cart';
import handleAlert from '../../helpers/handleAlert';
import { PINK_OCTOBER } from '../../helpers/colors';

const CardContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  background: #ffffff;
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : 'transparent'};
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: ${({pink}) => pink ? PINK_OCTOBER : 'linear-gradient(45deg, #fff, #fff), linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)'};
  border-radius: 10px;
  width: 96%;
  max-width: 359px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 16px 19px 16px;
  .row .col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Tag = styled.div`
  width: 154px;
  height: 30px;
  background: #0a7a71;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  left: 50%;
  transform: translate(-50%);
`;

const Prices = styled(Row)`
  background: transparent;
  border-radius: 5px;
  width: 100%;
  color: #fff;
  letter-spacing: -0.03em;
  margin: 8px auto 18px auto;
  padding-top: 9px;
  p {
    margin-bottom: 7px;
  }
  #linea {
    margin-top: 2px;
    font-weight: 700;
    font-family: 'IBM Plex Sans';
    font-size: 20px;
    line-height: 22px;
    color: ${({pink}) => pink === 'yes' ? PINK_OCTOBER : '#0CAC8C'};
    text-align: right;
    padding-right: 5px;
    margin-bottom: 9px;
  }
  #sucursal {
    margin-top: 2px;
    font-weight: 600;
    font-family: 'IBM Plex Sans';
    font-size: 16px;
    line-height: 22px;
    color: #6A6A6A;
    text-align: right;
    padding-right: 5px;
  }
  #price-online {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: ${({pink}) => pink === 'yes' ? PINK_OCTOBER : '#0CAC8C'};
    padding-left: 5px;
  }
  #price-sucursal {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #6A6A6A;
    padding-left: 5px;
  }
  .col-6 {
    padding: 0px;
  }
`;

const Title = styled.h3`
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${({pink}) => pink ? PINK_OCTOBER : '#474A4B'};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const OrangeButton = styled.button`
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  font-size: 14px;
  padding: 0;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 10px;
  color: ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  }
`;

const WhiteButton = styled.button`
  width: 100%;
  height: 40px;
  background: ${({pink}) => pink ? PINK_OCTOBER : 'linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%  )'};
  border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  border-radius: 10px;
  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'} !important;
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${({pink}) => pink ? PINK_OCTOBER : '#30DF99'};
  }
`;

const Detail = styled.div`
  max-height: 231px;
  overflow-y: auto;
  padding: 16px 18px;
  border-radius: 8px;
  background: #F2F7F9;
  > h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 6px;
  }
  > div p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #6A6A6A !important;
  }
  #enlace {
    color: ${({pink}) => pink ? PINK_OCTOBER : '#00AC90'};
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const TestCard = (props) => {
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const { items } = useSelector((state) => state.shoppingCart);
  const [viewDetail, setViewDetail] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isOrder = urlParams.get("doctor");
  const modal = document.getElementById("myModal");
  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    const exists = studyInCart(props.data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(props.data));
    }
  };

  const payStudy = () => {
    const exists = studyInCart(props.data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
      history.push("/checkoutp");
    } else {
      dispatch(handleAddItemToCart(props.data));
      history.push('/checkoutp');
    }
  };

  const handleCloseModalCart = () => {
    modal.style.display = "none";
  };

  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));
    console.log(patientInfo?.id >= 1);

    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      dispatch(handleAddItemToCart(props.data));
    }
    handleCloseModalCart(props.data);
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para ` +
      props.data.title;
  };

  return (
    <CardContainer
      pink = {props.data.ventaEspecial===1} 
      data-aos='fade-up'
      style={{
        boxShadow: '0px 4px 4px rgba(71, 74, 75, 0.15)',
        height: viewDetail ? 'auto' : '303px',
      }}
    >
      {props.data.tag && (
        <Tag>
          <AiFillHeart />
          &nbsp;{props.data.tag}
        </Tag>
      )}

      <Title
        onClick={() => { window.location.href = `/estudio/${props.data.link}`}}
        style={{color: props.data.ventaEspecial===1 && 'black'}}
        pink={props.data.ventaEspecial===1} 
      >
        <span>{props.data.title}</span>
      </Title>
      <Prices
        pink={props.data.ventaEspecial===1 ? 'yes' : undefined} 
      >
        {props.idClienteLabopat && props.idClienteLabopat !== 0 ? (
          <>
            <Col xs={6} style={{ paddingRight: '0px' }}>
              <p id='sucursal'>Precio de convenio:</p>
            </Col>
            <Col xs={6} style={{ paddingLeft: '0px' }}>
              <p id='price-sucursal' className='mb-3'>
                {currency(props.data.price)} MXN
              </p>
            </Col>
          </>
        ) : (
          <>
            <Col xs={6} style={{ paddingRight: '0px' }}>
            <p id='sucursal'>Pago en sucursal: </p>
              {props.data.categoria !== 10 &&
              props.data.categoria !== 8 &&
              props.data.ventaOnline !== 0 ? (
                <p id='linea' style={{color: props.data.ventaEspecial===1 && '#FF79A8'}}>Pago en línea: {/*props.idClienteLabopat*/}</p>
              ) : (
                <></>
              )}
              
            </Col>
            <Col xs={6} style={{ paddingLeft: '0px' }}>
            <p id='price-sucursal'>{currency(props.data.oldPrice)} MXN</p>
              {props.data.categoria !== 10 &&
              props.data.categoria !== 8 &&
              props.data.ventaOnline !== 0 ? (
                <p id='price-online' style={{color: props.data.ventaEspecial===1 && '#FF79A8'}}>{currency(props.data.price)} MXN</p>  
              ) : (
                <></>
              )}
            </Col>
          </>
        )}
      </Prices>
      {(props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0) || isDoctor ? (
        <Row>
          <Col xs={6} style={{ padding: '0 5px 0 0' }}>
            <OrangeButton onClick={() => addStudy()} pink={props.data.ventaEspecial===1}>
              Agregar estudio
            </OrangeButton>
          </Col>
          {isDoctor ? (
            <Col xs={6} style={{ padding: '0 0 0 5px' }}>
            <WhiteButton  onClick={handleOrder}>Generar orden</WhiteButton>
          </Col>
          ) : (
            <Col xs={6} style={{ padding: '0 0 0 5px' }}>
            <WhiteButton  style={{border: props.data.ventaEspecial===1 && '2px solid #FF79A8', color: props.data.ventaEspecial===1 && '#FF79A8'}} onClick={() => payStudy()} pink={props.data.ventaEspecial===1}>Pagar ahora</WhiteButton>
          </Col>
          )}
        </Row>
      ) : (
        <Row className='justify-content-center'>
          <Col xs={10} style={{ padding: '0 0 0 5px' }}>
            <WhiteButton style={{border: props.data.ventaEspecial===1 && '2px solid #FF79A8', color: props.data.ventaEspecial===1 && '#FF79A8'}}  onClick={() => callToSchedule()} pink={props.data.ventaEspecial===1}>
              Llamar para agendar
            </WhiteButton>
          </Col>
        </Row>
      )}
      <p
        style={{
          color: '#C4C4C4',
          textAlign: 'center',
          marginBottom: '0px',
          cursor: 'pointer',
        }}
        onClick={() => setViewDetail(!viewDetail)}
      >
        <span style={{ textDecoration: 'underline' }}>{viewDetail ? "Ocultar" : "Ver"} detalles</span>{' '}
        {viewDetail ? <UilAngleUp size='28' /> : <UilAngleDown size='28' />}
      </p>
      {viewDetail && (
        <Detail pink={props.data.ventaEspecial===1}>
          {props.data.tEntrega && (
            <>
              <h4>Tiempo de entrega</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.tEntrega }}
              ></div>
            </>
          )}

          {props.data.muestra && (
            <>
              <h4>Tipo de muestra</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.muestra }}
              ></div>
            </>
          )}

          {props.data.indicaciones && (
            <>
              <h4>Indicaciones</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.indicaciones }}
              ></div>
            </>
          )}

          {props.data.info && (
            <>
              <h4>Información</h4>
              <div dangerouslySetInnerHTML={{ __html: props.data.info }}></div>
            </>
          )}
          <p id='enlace' onClick={() => { window.location.href = `/estudio/${props.data.link}`}}>Leer descripción completa</p>
        </Detail>
      )}
    </CardContainer>
  );
};
